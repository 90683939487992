import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { RegisteringUserDto } from "./RegisteringUser";
import { McUserDto } from "firefly/my-chimney/ts/user";
import { UserRegisterRequest } from "./UserRegisterRequest";

export class UserRegistrationDao {
  private readonly fetchHelper: FetchHelper;

  constructor(token: string) {
    this.fetchHelper = new FetchHelper({
      basePath: "/user-registration/",
    }).authorizationBearer(token);
  }

  async getRegisteringUser(): Promise<RegisteringUserDto> {
    return await this.fetchHelper.get("get-registering-user").fetchAsJson();
  }

  async registerUser(request: UserRegisterRequest): Promise<McUserDto> {
    return await this.fetchHelper
      .post("register-user", request)
      .fetchAsJson<McUserDto>();
  }
}
