import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { FireplaceUpdateSpec } from "./FireplaceUpdate";

export class FireplaceDao {
  private fetchHelper: FetchHelper;

  constructor() {
    this.fetchHelper = new FetchHelper({ basePath: "/api/fireplace/" });
  }

  update(update: FireplaceUpdateSpec): Promise<void> {
    return this.fetchHelper.post("update", update).fetch();
  }
}
