import { FC, useMemo } from "react";
import { ChromePicker, Color, ColorResult } from "@hello-pangea/color-picker";
import styled from "styled-components";
import { debounce } from "@mui/material/utils";

export const StyledColorPicker = styled(ChromePicker)`&& {

}`;

export interface ColorPickerProps {
  color: Color;
  onColorChange: (color: ColorResult) => void;
}

export const ColorPicker: FC<ColorPickerProps> = (props) => {

  const debounceColorChange = useMemo(
    () => debounce((color: ColorResult) => {
      props.onColorChange(color);
    }, 300),
    []
  );

  return (
    <StyledColorPicker
      styles={{
        picker: {
          boxShadow: "unset",
          fontFamily: "inherit"
        }
      }}
      color={props.color}
      onChange={debounceColorChange}
    />
  );
};
