import { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Building } from "@airmont/firefly/my-chimney/ts/building";
import { SimpleTreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ArrayUtils } from "@airmont/shared/ts/utils/core";
import { SxProps } from "@mui/material";
import { FlueTreeItem } from "../shared/buildingTreeView/FlueTreeItem";
import { FireplaceTreeItem } from "../shared/buildingTreeView/FireplaceTreeItem";
import { ChimneyTreeItem } from "../shared/buildingTreeView/ChimneyTreeItem";
import {
  Chimney,
  Fireplace,
  Flue,
} from "@airmont/firefly/my-chimney/ts/building";
import { BuildingTreeItem } from "../shared/buildingTreeView/BuildingTreeItem";

export interface BuildingTreeProps {
  building: Building;
  selectedObject?: {
    type: "Chimney" | "Flue" | "Fireplace";
    id: string;
  } | null;
  sx?: SxProps;
  onSelectedFireplace?: (fireplace: Fireplace) => void;
  onSelectedFlue?: (flue: Flue) => void;
  onSelectedChimney?: (fireplace: Chimney) => void;
}
export const BuildingTreeView: FC<BuildingTreeProps> = (props) => {
  const { building, selectedObject } = props;

  const chimneyByNodeId = useMemo(() => {
    return ArrayUtils.toObjectDictionary(
      building.chimneys,
      (it) => `Chimney:${it.id}`
    );
  }, [building]);

  const flueByNodeId = useMemo(() => {
    return ArrayUtils.toObjectDictionary(
      building.getFlues(),
      (it) => `Flue:${it.id}`
    );
  }, [building]);

  const fireplaceByNodeId = useMemo(() => {
    return ArrayUtils.toObjectDictionary(
      building.getFireplaces(),
      (it) => `Fireplace:${it.id}`
    );
  }, [building]);

  const [expanded, setExpanded] = useState<string[]>([]);
  const selected = useMemo(() => {
    if (selectedObject == null) {
      return null;
    }
    return selectedObject.type + ":" + selectedObject.id;
  }, [selectedObject]);

  useEffect(() => {
    const defaultExpanded: Array<string> = [`Building:${building.id}`];
    building.chimneys.forEach((chimney) => {
      defaultExpanded.push(`Chimney:${chimney.id}`);
      chimney.flues.forEach((flue) => {
        defaultExpanded.push(`Flue:${flue.id}`);
      });
    });
    setExpanded(defaultExpanded);
  }, [building]);

  const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
    //setExpanded(nodeIds);
  };

  const handleSelect = (event: SyntheticEvent, itemId: string | null) => {
    if (props.onSelectedFireplace != null) {
      if (itemId?.startsWith("Fireplace:")) {
        const fireplace = fireplaceByNodeId[itemId];
        if (fireplace != null) {
          props.onSelectedFireplace?.(fireplace);
        }
      }
    }
    if (props.onSelectedFlue != null) {
      if (itemId?.startsWith("Flue:")) {
        const flue = flueByNodeId[itemId];
        if (flue != null) {
          props.onSelectedFlue?.(flue);
        }
      }
    }
    if (props.onSelectedChimney != null) {
      if (itemId?.startsWith("Chimney:")) {
        const chimney = chimneyByNodeId[itemId];
        if (chimney != null) {
          props.onSelectedChimney?.(chimney);
        }
      }
    }
  };

  return (
    <SimpleTreeView
      slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
      expandedItems={expanded}
      selectedItems={selected}
      sx={props.sx}
      onExpandedItemsChange={handleToggle}
      onSelectedItemsChange={handleSelect}
    >
      <BuildingTreeItem building={building}>
        {building.chimneys.map((chimney) => {
          return (
            <ChimneyTreeItem chimney={chimney}>
              {chimney.flues.map((flue) => {
                return (
                  <FlueTreeItem flue={flue}>
                    {flue.fireplaces.map((fireplace) => {
                      return <FireplaceTreeItem fireplace={fireplace} />;
                    })}
                  </FlueTreeItem>
                );
              })}
            </ChimneyTreeItem>
          );
        })}
      </BuildingTreeItem>
    </SimpleTreeView>
  );
};
