import React, { FC, ReactNode } from "react";
import { TreeItem } from "@mui/x-tree-view";
import HouseRoundedIcon from "@mui/icons-material/HouseRounded";
import { Building } from "@airmont/firefly/my-chimney/ts/building";

export interface BuildingTreeItemProps {
  building: Building;
  children?: ReactNode;
}

export const BuildingTreeItem: FC<BuildingTreeItemProps> = (props) => {
  const { building } = props;

  return (
    <TreeItem
      itemId={`Building:${building.id}`}
      label={building.name}
      slots={{ expandIcon: HouseRoundedIcon }}
      sx={{
        ".MuiTreeItem-content": {
          pt: 0.5,
          pb: 0.5,
          pr: 1,
          pl: 1.7,
          borderRadius: "7px",
        },
      }}
    >
      {props.children}
    </TreeItem>
  );
};
