import {
  ActionGroupPreferenceProps,
  ActionGroupPreferences,
} from "./ActionGroup";
import { Layout } from "./Layout";

export const resolveActionGroupPreferences = (
  actionGroupPreferences: ActionGroupPreferences | undefined,
  layout: Layout
): ActionGroupPreferenceProps | undefined => {
  if (actionGroupPreferences === undefined) {
    return undefined;
  }
  const preferenceByLayout = actionGroupPreferences[layout];
  if (preferenceByLayout != null) {
    return preferenceByLayout;
  }
  return actionGroupPreferences;
};
