import React, { FC } from "react";
import { Link, ListItem, ListItemIcon, SxProps, useTheme } from "@mui/material";
import { LinkItem } from "./LinkItem";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { ItemVariant } from "./ItemVariant";
import { Layout } from "./Layout";
import { LayoutDirection } from "./LayoutDirection";
import { useClassName } from "./useResolveClassName";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export interface LinkListItemProps {
  item: LinkItem;
  variant?: ItemVariant;
  layout?: Layout;
  layoutDirection?: LayoutDirection;
  hideLabel?: boolean;
  sx?: SxProps;
}
export const LinkListItem: FC<LinkListItemProps> = (props) => {
  const { item } = props;
  const hideLabel = props.hideLabel ?? false;
  const displayLabel = !hideLabel;
  const classNameRoot = useClassName(props, ["Item", "LinkItem"]);
  const theme = useTheme();
  const sx = useSxMerge(props.sx, {
    justifyContent: hideLabel ? "center" : undefined,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  });
  return (
    <ListItem className={classNameRoot} sx={sx}>
      <Link
        target={item.target ?? "_blank"}
        href={item.href}
        underline={"none"}
        color={"text.primary"}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {item.icon != null && (
          <ListItemIcon sx={{ minWidth: hideLabel ? "unset" : undefined }}>
            {item.icon}
          </ListItemIcon>
        )}
        {displayLabel && item.name}
        {displayLabel && (
          <ListItemIcon sx={{ marginLeft: 1 }}>
            <OpenInNewRoundedIcon fontSize="small" />
          </ListItemIcon>
        )}
      </Link>
    </ListItem>
  );
};
