import React, { FC, useMemo } from "react";
import {
  LeftRightSideValueHighlight,
  MuiChartContainer,
  RightSideValueHighlight,
  useSxMerge,
} from "@airmont/shared/ts/ui/mui";
import {
  axisClasses,
  BarPlot,
  ChartsAxisHighlight,
  ChartsLegend,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { AllSeriesType } from "@mui/x-charts/models/seriesType";
import { SxProps } from "@mui/system";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import {
  AxisConfig,
  ChartsYAxisProps,
  ScaleName,
} from "@mui/x-charts/models/axis";
import { FlueMetrics } from "../metrics/FlueMetrics";
import { useResolveBurnHourCountSeries } from "./useResolveBurnHourCountSeries";
import { useResolveSootIndexSeries } from "./useResolveSootIndexSeries";
import { useResolveSootIndexCumulativeSeries } from "./useResolveSootIndexCumulativeSeries";
import { LineSeriesType } from "@mui/x-charts/models/seriesType/line";
import { useResolveSootIndexCumulativePredictedSeries } from "./useResolveSootIndexCumulativePredictedSeries";
import { useResolveSootIndexPredictedSeries } from "./useResolveSootIndexPredictedSeries";
import { useResolveBurnHourCountEstimatedSeries } from "./useResolveBurnHourCountEstimatedSeries";
import { ChartsTooltipAxisContent } from "./ChartsTooltipAxisContent";
import { blue, orange, red } from "@mui/material/colors";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { LinearProgress, Stack, useTheme } from "@mui/material";
import { Info } from "luxon";

const months: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export interface SootIndexByMonthViewProps {
  loading?: boolean;
  selectedYear: number;
  selectedMonth: number;
  flueMetricsByMonth: Record<string, FlueMetrics>;
  predictedFlueMetricsByMonth: Record<string, FlueMetrics>;
  sootIndexThreshold: number;
  hideBurnHours?: boolean;
  sx?: SxProps;
}
export const SootIndexByMonthView: FC<SootIndexByMonthViewProps> = (props) => {
  const {
    selectedYear,
    selectedMonth,
    flueMetricsByMonth,
    predictedFlueMetricsByMonth,
    sootIndexThreshold,
  } = props;
  const loading = props.loading ?? false;
  const theme = useTheme();
  const svgRef = React.useRef<SVGSVGElement>(null);
  const lastYear = selectedYear - 1;
  const displayBurnHours = props.hideBurnHours ?? true;
  const now = useDateTimeNow();

  const monthLabels = useMemo(() => {
    const luxonMonths = Info.months();
    return months.map((month) => {
      const luxonMonth = luxonMonths[month - 1];
      return `${luxonMonth} ${selectedYear}`;
    });
  }, [selectedYear]);

  const burnHoursCountSeries = useResolveBurnHourCountSeries(
    selectedYear,
    now.year === selectedYear ? now.month : null,
    flueMetricsByMonth
  );
  const burnHourCountEstimatedSeries = useResolveBurnHourCountEstimatedSeries(
    lastYear,
    now.year === selectedYear ? now.month : null,
    flueMetricsByMonth
  );

  const sootIndexSeries = useResolveSootIndexSeries(
    selectedYear,
    now.year === selectedYear ? now.month : null,
    flueMetricsByMonth
  );

  const sootIndexCumulativeSeries = useResolveSootIndexCumulativeSeries(
    selectedYear,
    now.year === selectedYear ? now.month : null,
    flueMetricsByMonth
  );

  const sootIndexEstimatedSeries = useResolveSootIndexPredictedSeries(
    selectedYear,
    now.year === selectedYear ? now.month : null,
    predictedFlueMetricsByMonth
  );

  const sootIndexCumulativePredictedSeries =
    useResolveSootIndexCumulativePredictedSeries({
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      nowYear: now.year,
      nowMonth: now.month,
      flueMetricsByMonth: flueMetricsByMonth,
      predictedFlueMetricsByMonth: predictedFlueMetricsByMonth,
    });
  const maxSootIndex = useMemo(() => {
    return sootIndexCumulativePredictedSeries.reduce((a, b) => {
      return Math.max(a ?? 0, b ?? 0);
    }, 0);
  }, [sootIndexCumulativePredictedSeries]);
  const maxSootIndexAxisValue = Math.max(
    sootIndexThreshold + 1000,
    maxSootIndex ?? 0
  );

  const burnHoursThisYearSeriesDef: AllSeriesType = {
    id: "burnHours-thisYear",
    type: "bar",
    label: "Fyringstimer, " + selectedYear,
    yAxisKey: "burnHours",
    data: burnHoursCountSeries,
    color: blue["500"],
  };
  const burnHoursLastYearSeriesDef: AllSeriesType = {
    id: "burnHours-lastYear",
    type: "bar",
    label: "Fyringstimer, estimert",
    yAxisKey: "burnHours",
    data: burnHourCountEstimatedSeries,
    color: blue["200"],
  };
  const sootIndexSeriesDef: AllSeriesType = {
    id: "sootIndex",
    type: "bar",
    label: `Si, ${selectedYear}`,
    yAxisKey: "sootIndex",
    data: sootIndexSeries,
    color: orange["700"],
  };

  const sootIndexEstimatedSeriesDef: AllSeriesType = {
    id: "sootIndex-estimated",
    type: "bar",
    label: `Si, estimert`,
    yAxisKey: "sootIndex",
    data: sootIndexEstimatedSeries,
    color: orange["300"],
  };
  const sootIndexCumulativeSeriesDef: AllSeriesType = {
    id: "sootIndex-cumulative",
    type: "line",
    label: "Si, kumulativ",
    yAxisKey: "sootIndex",
    data: sootIndexCumulativeSeries,
    color: red["500"],
  };
  const sootIndexCumulativePredictedSeriesDef: LineSeriesType = {
    id: "sootIndex-cumulative-estimated",
    type: "line",
    label: "Si, estimert, kumulativ",
    yAxisKey: "sootIndex",
    data: sootIndexCumulativePredictedSeries,
    color: red["200"],
  };

  const series: Array<AllSeriesType> = [];
  series.push(sootIndexCumulativeSeriesDef);
  if (displayBurnHours) {
    series.push(burnHoursThisYearSeriesDef);
    if (now.year === selectedYear) {
      series.push(burnHoursLastYearSeriesDef);
    }
  }
  series.push(sootIndexSeriesDef);
  if (now.year === selectedYear) {
    series.push(sootIndexEstimatedSeriesDef);
    series.push(sootIndexCumulativePredictedSeriesDef);
  }

  const yAxis: MakeOptional<
    AxisConfig<ScaleName, any, ChartsYAxisProps>,
    "id"
  >[] = [];
  if (displayBurnHours) {
    yAxis.push({
      id: "burnHours",
      scaleType: "linear",
      min: 0,
      max: 800,
    });
  }

  yAxis.push({
    id: "sootIndex",
    scaleType: "linear",
    min: 0,
    max: maxSootIndexAxisValue,
  });

  const sx = useSxMerge(props.sx, {
    position: "relative",
  });

  return (
    <Stack direction={"column"} sx={sx}>
      {loading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      )}
      <MuiChartContainer sx={{ flex: 1, minHeight: 0, minWidth: 0 }}>
        <ResponsiveChartContainer
          ref={svgRef}
          margin={{
            top: 20,
            left: 80,
            right: 90,
            bottom: 150,
          }}
          series={series}
          xAxis={[
            {
              id: "months",
              data: monthLabels,
              scaleType: "band",
              valueFormatter: (value) => value.toString(),
            },
          ]}
          yAxis={yAxis}
          sx={{
            ".MuiLineElement-root": {
              strokeWidth: 2,
            },
            ".MuiLineElement-series-sootIndex-cumulative-estimated": {
              strokeDasharray: "5 5",
            },
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
              transform: "translateX(-20px)",
            },
            [`.${axisClasses.right} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
              transform: "translateX(30px)",
            },
          }}
        >
          <ChartsLegend
            position={{
              vertical: "bottom",
              horizontal: "middle",
            }}
          />
          <BarPlot />
          <LinePlot />
          <MarkPlot />
          <ChartsAxisHighlight x="band" />
          <ChartsXAxis
            label="Måned"
            position="bottom"
            axisId="months"
            slotProps={{}}
          />
          {displayBurnHours && (
            <ChartsYAxis
              label="Fyringstimer"
              position="left"
              axisId="burnHours"
            />
          )}
          <ChartsYAxis label="Sot-indeks" position="right" axisId="sootIndex" />
          {now.year === selectedYear && (
            <ChartsReferenceLine
              x={now.month}
              label="Nå"
              lineStyle={{
                strokeDasharray: "10 5",
                stroke: theme.palette.grey["500"],
              }}
            />
          )}
          <ChartsReferenceLine
            y={props.sootIndexThreshold}
            label={`Feiebehov ved si ${props.sootIndexThreshold}`}
            labelAlign={"start"}
            labelStyle={{
              fontSize: "smaller",
            }}
            lineStyle={{
              strokeDasharray: "10 5",
              stroke: theme.palette.grey["500"],
            }}
            axisId={"sootIndex"}
          />
          {displayBurnHours && (
            <LeftRightSideValueHighlight
              svgRef={svgRef}
              leftAxisScaleId={"burnHours"}
              rightAxisScaleId={"sootIndex"}
            />
          )}
          {!displayBurnHours && (
            <RightSideValueHighlight
              svgRef={svgRef}
              rightAxisScaleId={"sootIndex"}
            />
          )}
          <ChartsTooltip
            trigger={"axis"}
            slotProps={{
              popper: {
                popperOptions: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, 10],
                      },
                    },
                  ],
                },
              },
            }}
            slots={{
              axisContent: ChartsTooltipAxisContent,
            }}
          />
        </ResponsiveChartContainer>
      </MuiChartContainer>
    </Stack>
  );
};
