import { ChimneysSpecification } from "@airmont/firefly/my-chimney/ts/building";
import {
  AddressDto,
  ChimneyHatStringsEnum,
  ChimneySensorPlacementStringEnum,
  FlueMetricsFactory,
  FlueTypeStringsEnum,
  YesNoUnknownStringEnum,
} from "@airmont/firefly/shared/ts/domain";

export const createKlokkergata6 = (
  address: AddressDto
): ChimneysSpecification => {
  return {
    chimneys: [
      {
        fields: {
          hat: ChimneyHatStringsEnum.Yes,
        },
        flues: [`${address.id}.f-1`].map((flueId) => {
          return {
            type: "Flue",
            id: flueId,
            mcFields: {
              name: null,
            },
            fields: {
              type: FlueTypeStringsEnum.Steel,
              height: 8,
              sensorPlacement: ChimneySensorPlacementStringEnum.Roof,
            },
            fireplaces: [`${flueId}.fp-1`].map((firePlaceId) => {
              return {
                type: "Fireplace",
                id: firePlaceId,
                flueId: flueId,
                mcFields: {
                  name: "Contura 23",
                },
                fields: {
                  catalytic: YesNoUnknownStringEnum.Yes,
                },
              };
            }),
            metrics: [
              FlueMetricsFactory.createSinceSweepDto({
                flueId: flueId,
                metrics: {
                  burnCount: 151,
                  burnHourCount: 1501,
                  sootIndex: 231,
                  temperatureMax: 335,
                  chimneyFireCount: 0,
                },
              }),
            ],
          };
        }),
      },
    ],
  };
};
