import { PropertyValue } from "./PropertyValue";
import { PropertyValueType } from "./PropertyValueType";
import { DateTime } from "luxon";

export const resolvePropertyValueTypeFromValue = (
  value: PropertyValue
): PropertyValueType => {
  if (typeof value === "boolean") {
    return "boolean";
  } else if (typeof value === "number") {
    return "number";
  } else if (value instanceof DateTime) {
    return "dateTime";
  } else {
    return "string";
  }
};
