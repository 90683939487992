import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { AddressDto } from "@airmont/firefly/shared/ts/domain";

export class MyAddressController {
  public readonly handlers: RequestHandler[];

  private addresses: Array<AddressDto>;

  constructor(args: { addresses: Array<AddressDto> }) {
    this.addresses = args.addresses;
    this.handlers = [
      rest.get("/api/my-address/get-my-addresses", this.getMyAddresses),
    ];
  }

  getMyAddresses = async (
    request: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<Array<AddressDto>>> => {
    return res(ctx.json(this.addresses));
  };
}
