import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { DotsProgress } from "@airmont/shared/ts/ui/progress";

export const BuildingLoaderPage: FC = () => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");

  return (
    <AppPage
      name={"Building loader"}
      hideName
      mainPane={
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 0,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Stack
            direction={"column"}
            useFlexGap
            gap={1}
            sx={{ alignSelf: "center" }}
          >
            <CircularProgress sx={{ margin: "auto", display: "block" }} />
            <Typography>
              {`${t("Loading Buildings")}`}
              <DotsProgress length={5} />
            </Typography>
          </Stack>
        </Box>
      }
    />
  );
};
