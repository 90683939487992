import { useEffect, useState } from "react";
import {
  FlueId,
  FlueMetricNaturalKey,
  FlueMetricNaturalKeyImpl,
  FlueMetrics,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import {
  DictionarySetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { DateTime } from "luxon";

export const useFlueMetricTimeItems = (
  flueId: FlueId,
  flueMetrics: Array<FlueMetrics>
): {
  flueMetricTimeItems: Array<FlueMetricNaturalKey>;
  selectedFlueMetricTimeItem: FlueMetricNaturalKey;
  handleFlueMetricTimeItemSelect: (item: FlueMetricNaturalKey) => void;
} => {
  const [flueMetricTimeItems, setFlueMetricTimeItems] = useState<
    Array<FlueMetricNaturalKey>
  >([]);
  const [selectedFlueMetricTimeItem, setSelectedFlueMetricTimeItem] =
    useUserSettingWithDefault<FlueMetricNaturalKey>(
      "KeyFiguresPage.selectedFlueMetricTimeItem",
      DictionarySetting,
      FlueMetricNaturalKeyImpl.sinceSweep(flueId),
      {
        storeLocally: true,
        serialize: (value: FlueMetricNaturalKey): string => {
          return `${value.unit}:${value.time?.toISO() ?? ""}`;
        },
        deserialize: (string): FlueMetricNaturalKey => {
          const indexOfColon = string.indexOf(":");
          if (indexOfColon < 0) {
            throw new IllegalStateError(
              "UserSetting KeyFiguresPage.selectedFlueMetricTimeItem is not serialized on expected format: " +
                string
            );
          }
          const unitAsString = string.substring(
            0,
            indexOfColon
          ) as keyof typeof FlueMetricsTimeUnitEnum;
          const unit: FlueMetricsTimeUnitEnum | undefined =
            FlueMetricsTimeUnitEnum[unitAsString];
          if (unit === undefined) {
            throw new IllegalStateError(
              "UserSetting KeyFiguresPage.selectedFlueMetricTimeItem is not serialized on expected format: " +
                string
            );
          }
          const timeAsString = string.substring(indexOfColon + 1);
          const time =
            timeAsString.length > 0
              ? DateTime.fromISO(timeAsString)
              : undefined;
          if (time !== undefined && !time.isValid) {
            throw new IllegalStateError(
              "UserSetting KeyFiguresPage.selectedFlueMetricTimeItem is not serialized on expected format: " +
                string
            );
          }
          return new FlueMetricNaturalKeyImpl(flueId, unit, time);
        },
      }
    );

  useEffect(() => {
    const items: Array<FlueMetricNaturalKey> = [];
    items.push(FlueMetricNaturalKeyImpl.ever(flueId));
    items.push(FlueMetricNaturalKeyImpl.sinceSweep(flueId));
    flueMetrics
      .filter(
        (metric) =>
          metric.timeUnit === FlueMetricsTimeUnitEnum.Year &&
          metric.time !== undefined
      )
      .sort((a, b) => {
        const aTime = a.time ?? _throw(new IllegalStateError());
        const bTime = b.time ?? _throw(new IllegalStateError());
        return aTime.equals(bTime) ? 0 : aTime < bTime ? 1 : -1;
      })
      .forEach((metric) => {
        items.push(metric.naturalKey);
      });

    flueMetrics
      .filter(
        (metric) =>
          metric.timeUnit === FlueMetricsTimeUnitEnum.Month &&
          metric.time !== undefined
      )
      .sort((a, b) => {
        const aTime = a.time ?? _throw(new IllegalStateError());
        const bTime = b.time ?? _throw(new IllegalStateError());
        return aTime.equals(bTime) ? 0 : aTime < bTime ? 1 : -1;
      })
      .forEach((metric) => {
        items.push(metric.naturalKey);
      });
    setFlueMetricTimeItems(items);
  }, [flueId, flueMetrics]);

  return {
    flueMetricTimeItems: flueMetricTimeItems,
    selectedFlueMetricTimeItem: selectedFlueMetricTimeItem,
    handleFlueMetricTimeItemSelect: setSelectedFlueMetricTimeItem,
  };
};
