import { FC, useState } from "react";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppTheme } from "../AppThemeProvider";
import { ColorResult } from "@hello-pangea/color-picker";
import { ColorPicker } from "./ColorPicker";
import { ObjectPath } from "@airmont/shared/ts/utils/core";
import { overridableThemeOptions } from "../overridableThemeOptions";

export interface AppThemeEditorProps {
  sx?: SxProps;
}

export const AppThemeEditor: FC<AppThemeEditorProps> = (props) => {
  const { themeName, themeOptions, setThemeOptions } = useAppTheme();
  const theme = useTheme();
  const colors = overridableThemeOptions;
  const [selectedColorIndex, setSelectedColorIndex] = useState<number>(-1);
  const selectedMuiColor =
    selectedColorIndex > -1 ? colors[selectedColorIndex] : null;
  const selectedColor =
    selectedMuiColor != null
      ? ObjectPath.of(selectedMuiColor.path).getValue(theme)
      : null;
  const [colorDialogOpen, setColorDialogOpen] = useState<boolean>(false);

  const handleThemeColorClick = (colorName: string, index: number) => {
    setSelectedColorIndex(index);
    setColorDialogOpen(true);
  };

  const handleColorChange = (colorResult: ColorResult) => {
    if (selectedColorIndex > -1) {
      const color = colors[selectedColorIndex];
      const newOptions = ObjectPath.of(color.path).updateObject(
        themeOptions,
        colorResult.hex
      );
      setThemeOptions(themeName, newOptions);
    }
  };

  return (
    <Box sx={props.sx}>
      <Typography>Theme: {themeName}</Typography>
      <List>
        {colors.map((muiColor, index) => {
          const color = ObjectPath.of(muiColor.path).getValue(theme);
          return (
            <ListItemButton
              key={index}
              selected={index === selectedColorIndex}
              onClick={() => handleThemeColorClick(muiColor.path, index)}
            >
              <ListItemAvatar>
                <Avatar
                  variant={"rounded"}
                  sx={{
                    backgroundColor: color,
                    border: "1px solid " + theme.palette.divider,
                  }}
                >
                  <span></span>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={muiColor.name}
                secondary={color}
              ></ListItemText>
            </ListItemButton>
          );
        })}
      </List>
      {colorDialogOpen != null && selectedMuiColor && (
        <Dialog
          open={colorDialogOpen}
          hideBackdrop={true}
          onClose={() => setColorDialogOpen(false)}
        >
          <DialogTitle>{selectedMuiColor.name}</DialogTitle>
          <DialogContent>
            <ColorPicker
              color={selectedColor}
              onColorChange={handleColorChange}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};
