import { Button as MuiButton } from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";
import { FC } from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";

export const Button: FC<ButtonProps> = (props) => {
  const componentSize = useComponentSizeFromDense();
  let sx: SxProps<Theme> | undefined = props.sx;

  const iconButNoText =
    (props.endIcon != null || props.startIcon != null) && !props.children;
  if (iconButNoText) {
    sx = Object.assign({}, sx, {
      minWidth: "unset",
      paddingLeft: "8px",
      paddingRight: "8px",
      ".MuiButton-startIcon": {
        marginLeft: "unset",
        marginRight: "unset",
      },
    } as SxProps<Theme>);
  }

  return <MuiButton {...props} sx={sx} size={componentSize} />;
};
