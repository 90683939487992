import React, { FC, useMemo, useRef, useState } from "react";
import { TimeframeObject } from "./TimeframeObject";
import {
  Box,
  Button,
  IconButton,
  styled,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import { Info } from "luxon";
import { useCalculateMaxWidth } from "@airmont/shared/ts/utils/core";
import { useThisTranslation } from "./i18n";
import { TimeframeOptions } from "./TimeframeOptions";
import { TimeframeUnit } from "./TimeframeUnit";
import { YearSelect } from "./YearSelect";
import { MonthSelect } from "./MonthSelect";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

const resolveDateTimeGetUnit = (
  timeframeUnit: TimeframeUnit
): "year" | "quarter" | "month" | "weekNumber" => {
  if (timeframeUnit === "year") {
    return "year";
  } /*else if (timeframeUnit === "quarter") {
    return "quarter";
  } */ else if (timeframeUnit === "month") {
    return "month";
  } /*else if (timeframeUnit === "week") {
    return "weekNumber";
  }*/ else {
    throw Error("timeframeUnit not supported: " + timeframeUnit);
  }
};

export type TimeframeLayoutType = "standard" | "row";

type StyledTimeframeProps = {
  $layout: TimeframeLayoutType;
  $periodTextWidth: number;
};
export const StyledTimeframe = styled(Box)<StyledTimeframeProps>`
  display: grid;
  transition: opacity 500ms;

  &.standard-layout {
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "uc uc uc"
      "nl pe nr"
      "nl pe nr"
      ". gn .";

    > .unitContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 6px;
    }

    > .periodContainer {
      > .monthPeriodName {
        min-width: ${(props: StyledTimeframeProps) => props.$periodTextWidth}px;
      }
    }
  }

  &.row-layout {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "nl pe nr gn uc";

    > .navigationLeft {
      display: flex;
      align-items: center;
    }

    > .navigationRight {
      display: flex;
      align-items: center;
    }

    > .periodContainer {
      display: flex;
      align-items: center;

      > .monthPeriodName {
        min-width: ${({ $periodTextWidth }: StyledTimeframeProps) =>
          $periodTextWidth}px;
        padding-right: 0.5em;
      }
    }

    .gotoNow {
      display: flex;
      align-items: center;
    }

    > .unitContainer {
      display: flex;
      align-items: center;

      padding-left: 8px;
    }
  }

  > .unitContainer {
    grid-area: uc;
  }

  > .navigationLeft {
    grid-area: nl;
  }

  > .navigationRight {
    grid-area: nr;
  }

  > .periodContainer {
    grid-area: pe;
  }

  .gotoNow {
    grid-area: gn;
  }
`;

export interface TimeframeProps {
  timeframe: TimeframeObject;
  timeframeUnit: TimeframeUnit;
  onPreviousTimeframe: () => void;
  onNextTimeframe: () => void;
  onNowTimeframe: () => void;
  onTimeframeChange: (newTimeframe: TimeframeObject) => void;
  onUnitChange: (newUnit: TimeframeUnit) => void;
  onYearChange: (newYear: number) => void;
  onMonthChange: (newMonth: number) => void;
  options: TimeframeOptions;
  layout?: TimeframeLayoutType;
  hidden?: boolean;
  sx?: SxProps;
}

export const Timeframe: FC<TimeframeProps> = (props) => {
  const { timeframe, timeframeUnit } = props;
  const allowedUnits = props.options.allowedUnits ?? [];
  const { t } = useThisTranslation();
  const [layout] = useState<TimeframeLayoutType>(props.layout ?? "standard");
  const monthPeriodNameRef = useRef<HTMLButtonElement>(null);

  const months = useMemo(() => Info.months(), []);

  const hide = props.hidden;
  const maxMonthWidth = useCalculateMaxWidth(
    months,
    monthPeriodNameRef.current
  );

  const displayYear = timeframeUnit === "year" || timeframeUnit === "month";
  const displayMonth = timeframeUnit === "month";

  const displayYearUnitToggleButton =
    allowedUnits.includes("year") && allowedUnits.length > 1;
  const displayMonthUnitToggleButton =
    allowedUnits.includes("month") && allowedUnits.length > 1;

  const handleUnitChange = (
    event: React.MouseEvent<HTMLElement>,
    newUnit: string | null
  ) => {
    if (newUnit != null) {
      props.onUnitChange(newUnit as TimeframeUnit);
    }
  };

  const handleYearChange = (newYear: number) => {
    props.onYearChange(newYear);
  };

  const handleMonthChange = (newMonth: number) => {
    props.onMonthChange(newMonth);
  };
  const sx = useSxMerge(props.sx, {
    visibility: hide ? "hidden" : "visible",
    opacity: hide ? 0 : 1,
  });
  return (
    <StyledTimeframe
      className={`Timeframe ${layout}-layout`}
      $periodTextWidth={maxMonthWidth}
      $layout={layout}
      sx={sx}
    >
      <Box className={"unitContainer"}>
        <ToggleButtonGroup
          size={
            layout === "standard"
              ? "small"
              : layout === "row"
              ? "small"
              : undefined
          }
          value={props.options.unit}
          exclusive
          onChange={handleUnitChange}
        >
          {displayYearUnitToggleButton && (
            <ToggleButton
              value={"year"}
              sx={{ lineHeight: 1, color: "text.secondary" }}
            >
              <Tooltip title={t("Change size of the period to year")}>
                <span>{t("Y")}</span>
              </Tooltip>
            </ToggleButton>
          )}
          {displayMonthUnitToggleButton && (
            <ToggleButton
              value={"month"}
              sx={{ lineHeight: 1, color: "text.secondary" }}
            >
              <Tooltip title={t("Change size of the period to month")}>
                <span>{t("M")}</span>
              </Tooltip>
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Box>
      <Box className={"navigationLeft"}>
        {layout === "standard" && (
          <Button
            color={"secondary"}
            size={"large"}
            onClick={props.onPreviousTimeframe}
            sx={{
              height: "100%",
              ".MuiButton-endIcon": {
                margin: 0,
              },
              ".MuiButton-endIcon > svg": {
                fontSize: "60px",
              },
            }}
            endIcon={<ChevronLeftRoundedIcon />}
          />
        )}
        {layout === "row" && (
          <IconButton color={"secondary"} onClick={props.onPreviousTimeframe}>
            <ChevronLeftRoundedIcon />
          </IconButton>
        )}
      </Box>
      <Box
        className={"periodContainer"}
        sx={{
          display: "flex",
          flexDirection:
            layout === "standard"
              ? "column"
              : layout === "row"
              ? "row"
              : "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: layout === "standard" ? "80px" : undefined,
        }}
      >
        {displayYear && (
          <YearSelect
            timeframe={props.timeframe}
            minYear={2021}
            disableFuture={props.options.disallowFuture}
            onChange={handleYearChange}
          />
        )}
        {displayMonth && (
          <MonthSelect
            timeframe={props.timeframe}
            disableFuture={props.options.disallowFuture}
            onChange={handleMonthChange}
            ref={monthPeriodNameRef}
          />
        )}
      </Box>
      <Box className={"navigationRight"}>
        {layout === "standard" && (
          <Button
            color={"secondary"}
            size={"large"}
            onClick={props.onNextTimeframe}
            sx={{
              height: "100%",
              ".MuiButton-endIcon": {
                margin: 0,
              },
              ".MuiButton-endIcon > svg": {
                fontSize: "60px",
              },
            }}
            endIcon={<ChevronRightRoundedIcon />}
          />
        )}
        {layout === "row" && (
          <IconButton color={"secondary"} onClick={props.onNextTimeframe}>
            <ChevronRightRoundedIcon />
          </IconButton>
        )}
      </Box>
      <Box
        className={"gotoNow"}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {layout === "standard" && (
          <Button
            color={"secondary"}
            size={"large"}
            onClick={props.onNowTimeframe}
          >
            {t("Goto today")}
          </Button>
        )}
        {layout === "row" && (
          <IconButton color={"secondary"} onClick={props.onNowTimeframe}>
            <UpdateRoundedIcon />
          </IconButton>
        )}
      </Box>
    </StyledTimeframe>
  );
};
