import { RequestHandler } from "msw";
import {
  AddressDto,
  createAddressMocks,
} from "@airmont/firefly/shared/ts/domain";
import { SystemController } from "@airmont/firefly/my-chimney/ts/system";
import { MyAddressController } from "@airmont/firefly/my-chimney/ts/address";
import {
  ChimneyController,
  ChimneyDto,
  createChimneyMocks,
} from "@airmont/firefly/my-chimney/ts/building";
import { BuildingController } from "@airmont/firefly/my-chimney/ts/building";
import { createKlokkergata6 } from "./Klokkergata6";
import { createOpalveien15 } from "./Opalveien15";
import {
  BurnController,
  createBurnTemperatureSeriesMocks,
} from "@airmont/firefly/my-chimney/ts/burn";

export class MockControllers {
  private readonly systemController: SystemController;
  private readonly addressController: MyAddressController;
  private readonly chimneyController: ChimneyController;
  //private readonly flueController: FlueController;
  //private readonly flueMetricsController: FlueMetricsController;
  //private readonly fireplaceController: FireplaceController;
  private readonly buildingController: BuildingController;
  private readonly burnController: BurnController;

  constructor() {
    const addresses: Array<AddressDto> = createAddressMocks({
      specifications: [
        {
          streetAddress: { street: "Klokkergata", houseNumber: 6 },
          postalAddress: { code: "1794", place: "Sponvika" },
        },
        {
          streetAddress: { street: "Opalveien", houseNumber: 15 },
          postalAddress: { code: "1784", place: "Halden" },
        },
      ],
      count: 2,
    });
    const chimneys: Array<ChimneyDto> = createChimneyMocks({
      addresses: addresses,
      specifications: [
        createKlokkergata6(addresses[0]),
        createOpalveien15(addresses[1]),
      ],
    });

    this.systemController = new SystemController();
    this.addressController = new MyAddressController({
      addresses: addresses,
    });
    this.chimneyController = new ChimneyController({
      chimneys: chimneys,
    });
    /*this.flueController = new FlueController({
      flues: flues,
    });*/
    /*this.flueMetricsController = new FlueMetricsController({
      flueMetrics: flueMetrics,
    });*/
    /*this.fireplaceController = new FireplaceController({
      fireplaces: fireplaces,
    });*/

    const { burns, burnSeries } = createBurnTemperatureSeriesMocks(chimneys);
    this.burnController = new BurnController({
      burns: burns,
      burnSeries: burnSeries,
    });

    this.buildingController = new BuildingController({
      addresses: addresses,
      chimneys: chimneys,
    });
  }

  getHandlers(): RequestHandler[] {
    return [
      ...this.systemController.handlers,
      ...this.addressController.handlers,
      ...this.chimneyController.handlers,
      ...this.buildingController.handlers,
      ...this.burnController.handlers,
    ];
  }
}
