import { FireplaceFieldsDto, FlueId } from "@airmont/firefly/shared/ts/domain";
import { Typed } from "@airmont/firefly/my-chimney/ts/shared";
import { Flue } from "../flue/Flue";

export type FireplaceId = string;

export interface McFireplaceFieldsDto {
  name: string | null;
}
export interface FireplaceDto extends Typed<"Fireplace"> {
  id: FireplaceId;
  flueId: FlueId;
  mcFields: McFireplaceFieldsDto | null;
  fields: FireplaceFieldsDto;
}

export interface FireplaceCopyArgs {
  mcFields?: McFireplaceFieldsDto | null;
}

export interface Fireplace extends Typed<"Fireplace"> {
  id: FireplaceId;
  mcFields: McFireplaceFieldsDto | null;
  fields: FireplaceFieldsDto;
  flue: Flue | undefined;
  setFlue: (flue: Flue) => void;
  copy: (args: FireplaceCopyArgs) => Fireplace;
}
export class FireplaceImpl implements Fireplace {
  readonly type = "Fireplace";
  readonly id: FireplaceId;
  readonly mcFields: McFireplaceFieldsDto | null;
  readonly fields: FireplaceFieldsDto;
  flue: Flue | undefined;

  constructor(args: {
    id: FireplaceId;
    mcFields: McFireplaceFieldsDto | null;
    fields: FireplaceFieldsDto;
    flue?: Flue;
  }) {
    this.id = args.id;
    this.mcFields = args.mcFields;
    this.fields = args.fields;
    this.flue = args.flue;
  }
  copy(args: FireplaceCopyArgs): Fireplace {
    return new FireplaceImpl({
      id: this.id,
      mcFields: args.mcFields ?? this.mcFields,
      fields: this.fields,
      flue: this.flue,
    });
  }
  setFlue(flue: Flue) {
    this.flue = flue;
  }
}
