import { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { ThemeDemo } from "@airmont/shared/ts/ui/app-theme-provider";
import { AppThemeEditor } from "@airmont/shared/ts/ui/app-theme-provider";

export const AppThemePage: FC = () => {
  return (
    <AppPage
      name={"App theme"}
      mainPane={<ThemeDemo />}
      mainPaneProps={{
        scrollableContentVertically: true,
      }}
      supportingPaneProps={{
        label: "Editor",
      }}
      supportingPane={<AppThemeEditor sx={{ flex: 1 }} />}
    />
  );
};
