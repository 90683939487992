import { BurnId } from "../../Burn";
import {
  BurnTemperatureSeriesNonSensitive,
  BurnTemperatureSeriesNonSensitiveImpl,
} from "../../BurnTemperatureSeriesNonSensitive";
import { FC, useMemo } from "react";
import { ApexChart } from "@airmont/firefly/shared/ts/charts";
import { ApexOptions } from "apexcharts";
import { useXAxisLabelsFormatter } from "./useXAxisLabelsFormatter";
import { useXAxisTooltipFormatter } from "./useXAxisTooltipFormatter";
import { useApexSeries } from "./useApexSeries";
import { useYAxisLabelsFormatter } from "./useYAxisLabelsFormatter";
import { SxProps } from "@mui/system";
import { BurnNonSensitiveUi } from "../../BurnNonSensitiveUi";
import { resolveYAxisMax } from "../shared/resolveYAxisMax";
import { useTheme } from "@mui/material";
import { Duration } from "luxon";
import { MathUtils } from "@airmont/shared/ts/utils/core";
import { useFireflySharedTsDomainTranslation } from "../../../i18n";

const minimumDuration = Duration.fromDurationLike({ hours: 4 });

export interface BurnNonSensitiveChartProps {
  burnTemperatureSeries: Array<BurnTemperatureSeriesNonSensitive>;
  burnById: Record<BurnId, BurnNonSensitiveUi>;
  hoveredBurn?: BurnId;
  maxTemperatures?: {
    ever?: number;
    sinceSweep?: number;
  };
  chimneyFireThreshold?: number;
  sx?: SxProps;
}
export const BurnNonSensitiveChart: FC<BurnNonSensitiveChartProps> = (
  props
) => {
  const { t } = useFireflySharedTsDomainTranslation();
  const theme = useTheme();
  const { series, maxY } = useApexSeries({
    burnTemperatureSeries: props.burnTemperatureSeries,
    burnById: props.burnById,
    hoveredBurn: props.hoveredBurn,
  });
  const longestDuration = useMemo(() => {
    const duration = Duration.fromObject({
      hours: MathUtils.round(
        BurnTemperatureSeriesNonSensitiveImpl.getLongestDuration(
          props.burnTemperatureSeries
        ).shiftTo("hours").hours
      ),
    });
    return duration;
  }, [props.burnTemperatureSeries]);
  const maxDuration: Duration = useMemo(() => {
    if (longestDuration < minimumDuration) {
      return minimumDuration;
    }
    return longestDuration;
  }, [longestDuration]);
  const maxHours = maxDuration.hours;
  const xAxisType = "numeric";
  const xAxisTooltipFormatter = useXAxisTooltipFormatter(xAxisType);
  const xAxisLabelsFormatter = useXAxisLabelsFormatter(xAxisType);
  const yAxisLabelsFormatter = useYAxisLabelsFormatter();
  const annotations: ApexAnnotations = useMemo(() => {
    const yAxisAnnotations: YAxisAnnotations[] = [];
    if (props.maxTemperatures?.ever != null) {
      yAxisAnnotations.push({
        y: props.maxTemperatures.ever,
        borderColor: theme.palette.warning.main,
        label: {
          borderColor: theme.palette.warning.main,
          style: {
            color: theme.palette.warning.contrastText,
            background: theme.palette.warning.main,
          },
          text: t("Max Temp. Since Start"),
        },
      });
    }
    if (props.chimneyFireThreshold != null) {
      yAxisAnnotations.push({
        y: props.chimneyFireThreshold,
        borderColor: theme.palette.error.main,
        label: {
          borderColor: theme.palette.error.main,
          style: {
            color: theme.palette.error.contrastText,
            background: theme.palette.error.main,
          },
          text: t("Chimney Fire Threshold"),
        },
      });
    }

    return {
      yaxis: yAxisAnnotations,
    };
  }, [props.maxTemperatures?.ever]);

  const options: ApexOptions = useMemo(() => {
    const yAxisMax = resolveYAxisMax({
      maxTemperature:
        props.maxTemperatures?.ever ?? props.maxTemperatures?.sinceSweep,
      maxY: maxY,
    });

    return {
      chart: {
        type: "line",
      },
      noData: {
        text: t("No Burns"),
      },
      annotations: annotations,
      legend: {
        show: false,
      },
      xaxis: {
        type: xAxisType,
        min: 0,
        max: maxDuration.toMillis(),
        tickAmount: maxHours,
        labels: {
          formatter: xAxisLabelsFormatter,
        },
        tooltip: {
          formatter: xAxisTooltipFormatter,
        },
      },
      yaxis: {
        max: yAxisMax,
        min: 0,
        labels: {
          formatter: yAxisLabelsFormatter,
        },
      },
    };
  }, [
    xAxisType,
    xAxisLabelsFormatter,
    xAxisTooltipFormatter,
    yAxisLabelsFormatter,
    annotations,
    maxHours,
    props.maxTemperatures?.ever,
    props.maxTemperatures?.sinceSweep,
  ]);

  return <ApexChart series={series} options={options} sx={props.sx} />;
};
