import EnergySavingsLeafRoundedIcon from "@mui/icons-material/EnergySavingsLeafRounded";
import { alpha, Tooltip } from "@mui/material";
import React, { FC } from "react";
import { blue } from "@mui/material/colors";

export const SavingEnergyIcon: FC = () => {
  return (
    <Tooltip title={"Energi-effektivt"}>
      <EnergySavingsLeafRoundedIcon sx={{ color: alpha(blue["500"], 0.9) }} />
    </Tooltip>
  );
};
