import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useBuildingDao } from "./useBuildingDao";
import { EnvironmentBuildings } from "./EnvironmentBuildings";
import { EnvironmentAwareBuilding } from "./EnvironmentAwareBuilding";

export interface BuildingLoaderProps {
  loader: ReactNode;
  noBuildings: ReactNode;
  children: (
    buildings: Array<EnvironmentAwareBuilding>,
    onBuildingsChange: (buildings: Array<EnvironmentAwareBuilding>) => void
  ) => ReactNode;
}

export const BuildingLoader: FC<BuildingLoaderProps> = (props) => {
  const buildingDao = useBuildingDao();
  const [buildings, setBuildings] = useState<
    Array<EnvironmentAwareBuilding> | undefined
  >(undefined);
  const queryClient = useQueryClient();

  useEffect(() => {
    const doFetch = async () => {
      const buildingQueryResult = await queryClient.fetchQuery({
        queryKey: ["BuildingDao.getMyBuildings"],
        queryFn: () => buildingDao.getMyBuildings(),
        staleTime: 60 * 60 * 1000,
        gcTime: 61 * 60 * 1000,
      });
      setBuildings(
        buildingQueryResult
          .map((buildingDto) => {
            return EnvironmentBuildings.fromDto(
              buildingDto
            ).toEnvironmentAwareBuildings();
          })
          .flat()
      );
    };
    doFetch();
  }, [queryClient, buildingDao]);

  const onBuildingsChange = useCallback(
    (buildings: Array<EnvironmentAwareBuilding>) => {
      setBuildings(buildings);
    },
    []
  );

  if (buildings == null) {
    return props.loader;
  }

  if (buildings.length === 0) {
    return props.noBuildings;
  }

  return <>{props.children(buildings, onBuildingsChange)}</>;
};
