import { Settings } from "luxon";
import { environment } from "../../environments/environment";
import { bootDemo } from "./demo/bootDemo";
import { SystemDao } from "@airmont/firefly/my-chimney/ts/system";
import { MyChimneyBootResult } from "./MyChimneyBootResult";
import { MockUser } from "shared-ts-utils-authentication";

export const boot = async (args: {
  onMessage: (message: string) => void;
  onError: (error: Error) => void;
}): Promise<MyChimneyBootResult> => {
  Settings.defaultLocale = "no";

  const systemDao = new SystemDao();
  let buildNumber = "?";
  //let useBackend = true;

  try {
    buildNumber = await systemDao.getBuildNumber();
    console.info("Backend detected!");
  } catch (e) {
    console.info("Backend not detected....");
    if (!environment.production) {
      console.info("...mocking backend");
      //useBackend = false;
      await bootDemo();
      buildNumber = await systemDao.getBuildNumber();
    }
  }
  buildNumber = await systemDao.getBuildNumber();

  console.info("buildNumber: " + buildNumber);

  return Promise.resolve({
    isAuthenticated: true,
    user: new MockUser({
      id: "dummy-user",
      name: "Dummy User",
      accessToken: "dummyAccessToken",
      role: "User",
      email: "dummyuser@airmont.no",
      claims: ["dummyClaim"],
    }),
  });
};
