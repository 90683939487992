import { RequestHandler } from "msw";
import { ChimneyDto } from "./Chimney";

export class ChimneyController {
  public readonly handlers: RequestHandler[];

  private chimneys: Array<ChimneyDto>;

  constructor(args: { chimneys: Array<ChimneyDto> }) {
    this.chimneys = args.chimneys;
    this.handlers = [];
  }
}
