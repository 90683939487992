import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const AboutPublicPreview = () => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  return (
    <Stack direction={"column"} useFlexGap gap={1}>
      <Typography>{t("Public Preview:Description")}</Typography>
      <ul>
        <Typography component={"li"}>
          <b>{t("Public Preview:.Early access")}</b>
          {": "}
          {t("Public Preview:Early access:Description")}
        </Typography>
        <Typography component={"li"}>
          <b>{t("Public Preview:.Wider audience")}</b>
          {": "}
          {t("Public Preview:Wider audience:Description")}
        </Typography>
        <Typography component={"li"}>
          <b>{t("Public Preview:.Testing and feedback")}</b>
          {": "}
          {t("Public Preview:Testing and feedback:Description")}
        </Typography>
        <Typography component={"li"}>
          <b>{t("Public Preview:.Potential instability")}</b>
          {": "}
          {t("Public Preview:Potential instability:Description")}
        </Typography>
        <Typography component={"li"}>
          <b>{t("Public Preview:.Subject to change")}</b>
          {": "}
          {t("Public Preview:Subject to change:Description")}
        </Typography>
      </ul>
    </Stack>
  );
};
