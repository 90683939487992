import { FC, ReactNode } from "react";
import { Toolbar } from "@mui/material";
import { useDense } from "@airmont/shared/ts/ui/responsive";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";
import { SxProps } from "@mui/system";

export interface PageToolbarProps {
  className?: string;
  children: ReactNode;
  sx?: SxProps;
}
export const PageToolbar: FC<PageToolbarProps> = (props) => {
  const dense = useDense();
  const sx = useSxMerge(props.sx, {
    display: "flex",
    alignItems: "center",
    minHeight: dense ? "56px" : undefined,
    "> .MuiDivider-root": {
      ml: 1,
      mr: 0,
    },
    "> .MuiButton-root": {
      ml: 1,
    },
  });
  return (
    <Toolbar
      disableGutters
      variant={dense ? "dense" : "regular"}
      className={
        "PageToolbar" + (props.className != null ? " " + props.className : "")
      }
      sx={sx}
    >
      {props.children}
    </Toolbar>
  );
};
