import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { ChimneyUpdateSpec } from "./ChimneyUpdate";

export class ChimneyDao {
  private fetchHelper: FetchHelper;

  constructor() {
    this.fetchHelper = new FetchHelper({ basePath: "/api/chimney/" });
  }
  update(update: ChimneyUpdateSpec): Promise<void> {
    return this.fetchHelper.post("update", update).fetch();
  }
}
