import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  enUS as muiCore_enUS,
  nbNO as muiCore_nbNO,
  Localization as MuiCoreLocalization,
} from "@mui/material/locale";
import { enUS as dataGrid_enUS } from "@mui/x-data-grid-premium/locales";
import { nbNO as dataGrid_nbNO } from "@mui/x-data-grid-premium/locales";
import { LocalizationProvider as DatePickerLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enUS as dp_enUS } from "@mui/x-date-pickers/locales";
import { nbNO as dp_nbNO } from "@mui/x-date-pickers/locales";
import { svSE as dp_svSE } from "@mui/x-date-pickers/locales";
import { nbNO as dg_nbNO } from "@mui/x-data-grid/locales";
import { svSE as dg_svSE } from "@mui/x-data-grid/locales";
import { GridLocaleText } from "@mui/x-data-grid/models/api/gridLocaleTextApi";
import { PickersLocaleText } from "@mui/x-date-pickers";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export interface MuiDataGridLocalization {
  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: Partial<GridLocaleText>;
      };
    };
  };
}

export interface MuiDataPickersLocalization {
  components: {
    MuiLocalizationProvider: {
      defaultProps: {
        localeText: Partial<PickersLocaleText<never>>;
      };
    };
  };
}

export type MuiLocalization = MuiCoreLocalization & MuiDataGridLocalization;

const mui_enUS: MuiLocalization = { ...muiCore_enUS, ...dataGrid_enUS };
const mui_nbNo: MuiLocalization = { ...muiCore_nbNO, ...dataGrid_nbNO };

const localizationByLanguage: Record<string, MuiLocalization> = {
  en: mui_enUS,
  no: mui_nbNo,
  "en-US": mui_enUS,
  "en-GB": mui_enUS,
  "nb-NO": mui_nbNo,
  "nn-NO": mui_nbNo,
};

const dataPickersLocalizationByLanguage: Record<
  string,
  MuiDataPickersLocalization
> = {
  en: dp_enUS,
  no: dp_nbNO,
  "en-US": dp_nbNO,
  "en-GB": dp_nbNO,
  "nb-NO": dp_nbNO,
  "nn-NO": dp_nbNO,
};

interface MuiLocalizationContextType {
  muiLocalization: MuiLocalization;
  setLocale: (language: string) => void;
}

const MuiLocalizationContext = createContext<MuiLocalizationContextType>({
  muiLocalization: mui_enUS,
  setLocale: () => {
    /* Nothing to do */
  },
});

export interface MuiLocalizationProviderProps {
  language: string;
  children: ReactNode;
}

export const MuiLocalizationProvider: FC<MuiLocalizationProviderProps> = (
  props
) => {
  const [value, setValue] = useState(resolveLocalization(props.language));
  const setLocale = useCallback((language: string) => {
    setValue(resolveLocalization(language));
  }, []);

  useEffect(() => {
    setValue(resolveLocalization(props.language));
  }, [props.language]);

  const contextValue: MuiLocalizationContextType = useMemo(() => {
    return {
      muiLocalization: value,
      setLocale: setLocale,
    };
  }, [value, setLocale]);
  return (
    <MuiLocalizationContext.Provider value={contextValue}>
      <DatePickerLocalizationProvider
        localeText={
          dp_nbNO.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        {props.children}
      </DatePickerLocalizationProvider>
    </MuiLocalizationContext.Provider>
  );
};

export const useMuiLocalization = (): MuiLocalizationContextType => {
  const context = useContext(MuiLocalizationContext);
  return context;
};

const resolveLocalization = (language: string): MuiLocalization => {
  const localization = localizationByLanguage[language];
  if (localization == null) {
    throw new IllegalArgumentError("Language not supported: " + language);
  }
  return localization;
};
