import React, { FC, useMemo } from "react";
import { BurnQualityCountAggregateByTime } from "./BurnQualityCountAggregateByTime";
import { Stack, useTheme } from "@mui/material";
import { MuiChartContainer, useSxMerge } from "@airmont/shared/ts/ui/mui";
import {
  axisClasses,
  BarPlot,
  ChartsAxisHighlight,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  MarkPlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { SxProps } from "@mui/system";
import { DateTime, DateTimeUnit, Interval } from "luxon";
import { BarSeriesType } from "@mui/x-charts/models/seriesType/bar";
import { BurnAssessmentColor } from "./BurnAssessmentColor";
import { TimeWheel } from "@airmont/shared/ts/utils/luxon";
import { useTranslation } from "react-i18next";

export interface BurnQualityCountAggregateByTimeChartProps {
  interval: Interval;
  timeUnit: DateTimeUnit;
  aggregates?: Array<BurnQualityCountAggregateByTime>;
  loading: boolean;
  sx?: SxProps;
}

export const BurnQualityCountAggregateByTimeChart: FC<
  BurnQualityCountAggregateByTimeChartProps
> = (props) => {
  const { interval, timeUnit, loading, aggregates } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");
  const monthLabels = useMemo(() => {
    return new TimeWheel({
      start: interval.start as DateTime<true>,
      timeUnit: timeUnit,
    }).runUntilTime(interval.end as DateTime<true>, (dateTime) => {
      return dateTime.toFormat("MMMM yyyy");
    });
  }, [interval, timeUnit]);

  const series = useMemo(() => {
    const excellentData =
      aggregates?.map((it) => it.qualityCount.excellent) ?? [];
    const goodData = aggregates?.map((it) => it.qualityCount.good) ?? [];
    const badData = aggregates?.map((it) => it.qualityCount.bad) ?? [];
    const unknownData = aggregates?.map((it) => it.qualityCount.unknown) ?? [];
    return [
      {
        id: "unknown",
        label: t("BurnAssessment.Unknown"),
        type: "bar",
        stack: "total",
        data: unknownData,
        color: BurnAssessmentColor.Unknown,
      },
      {
        id: "bad",
        label: t("BurnAssessment.Bad"),
        type: "bar",
        stack: "total",
        data: badData,
        color: BurnAssessmentColor.Bad,
      },
      {
        id: "good",
        label: t("BurnAssessment.Normal"),
        type: "bar",
        stack: "total",
        data: goodData,
        color: BurnAssessmentColor.Normal,
      },
      {
        id: "excellent",
        label: t("BurnAssessment.Good"),
        type: "bar",
        stack: "total",
        data: excellentData,
        color: BurnAssessmentColor.Good,
      },
    ] as Array<BarSeriesType>;
  }, [aggregates, t]);

  const theme = useTheme();
  const sx = useSxMerge(props.sx, {});
  return (
    <Stack direction={"column"} sx={sx}>
      <MuiChartContainer
        loading={loading}
        sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
      >
        <ResponsiveChartContainer
          margin={{
            top: 10,
            left: 75,
            right: 0,
            bottom: 100,
          }}
          series={series}
          xAxis={[
            {
              id: "months",
              data: monthLabels,
              scaleType: "band",
            },
          ]}
          yAxis={[
            {
              id: "burnCount",
              scaleType: "linear",
              label: t("Number of Burns"),
            },
          ]}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
              transform: "translateX(-20px)",
            },
          }}
        >
          <ChartsLegend
            position={{
              vertical: "bottom",
              horizontal: "middle",
            }}
          />
          <BarPlot />
          <MarkPlot />
          <ChartsAxisHighlight x="band" />
          <ChartsXAxis
            label={t("Month")}
            position="bottom"
            axisId="months"
            slotProps={{}}
          />
          <ChartsYAxis
            label={t("Number of Burns")}
            position="left"
            axisId="burnCount"
          />
          <ChartsTooltip trigger={"axis"} />
        </ResponsiveChartContainer>
      </MuiChartContainer>
    </Stack>
  );
};
