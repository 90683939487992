import React, { FC, ReactNode } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  useTheme,
} from "@mui/material";
import { Action } from "./Action";
import { Layout } from "./Layout";
import { LayoutDirection } from "./LayoutDirection";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { isSelectableAction } from "./SelectableAction";
import { useClassName } from "./useResolveClassName";
import { ItemVariant } from "./ItemVariant";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export interface ActionListItemProps {
  item: Action;
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  hideSelection?: boolean;
  hideLabel?: boolean;
  endAdornment?: ReactNode;
  sx?: SxProps;
}
export const ActionListItem: FC<ActionListItemProps> = (props) => {
  const { item, endAdornment } = props;
  const hideLabel = props.hideLabel ?? false;
  const hideSelection = props.hideSelection ?? false;
  const selected = isSelectableAction(item) ? item.selected : undefined;
  const selectable = selected !== undefined;

  const classNameRoot = useClassName(props, [
    "Item",
    "Action",
    "ActionListItem",
  ]);

  const classNameButton = useClassName(props, ["ActionListItem-button"]);
  const theme = useTheme();
  const handleClick = () => {
    item.onExecute(item);
  };

  const sx = useSxMerge(props.sx, {
    "&.variant-rounded": {
      borderRadius: "50px",
    },
    "&.direction-column": {
      borderRadius: 3,
    },
  });

  return (
    <ListItem
      onClick={handleClick}
      className={classNameRoot}
      disablePadding
      disableGutters
      sx={sx}
    >
      <ListItemButton
        className={classNameButton}
        sx={{
          borderRadius: "inherit",
          ">.MuiListItemText-root": {
            ">.MuiTypography-root": {
              fontSize: "inherit",
              textAlign: "inherit",
            },
          },
          "&.layout-medium": {
            fontSize: "0.75rem",
          },
          "&.direction-row": {
            textAlign: "left",
            ".MuiListItemText-root": {
              minHeight: "20px",
            },
          },
          "&.direction-column": {
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: "4px",
            ".MuiListItemIcon-root": {
              minWidth: "56px",
              maxWidth: "56px",
              minHeight: "32px",
              maxHeight: "32px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "15px",
            },
          },
          "&.direction-column:hover": {
            backgroundColor: "unset",
            ".MuiListItemIcon-root": {
              backgroundColor: theme.palette.action.hover,
            },
          },
        }}
      >
        {selectable && !hideSelection && selected && (
          <ListItemIcon className={"CheckIcon"}>
            <CheckRoundedIcon />
          </ListItemIcon>
        )}
        {item.icon != null && <ListItemIcon>{item.icon}</ListItemIcon>}
        {!hideLabel && (
          <ListItemText
            inset={selectable && !hideSelection && !selected}
            primary={item.name}
          />
        )}
        {endAdornment != null && endAdornment}
      </ListItemButton>
    </ListItem>
  );
};
