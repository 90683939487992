import { DateTimeISO } from "@airmont/shared/ts/types";
import { BurnQualityCount, BurnQualityCountDto } from "./BurnQualityCount";
import { DateTime } from "luxon";

export interface BurnQualityCountAggregateByTimeDto {
  time: DateTimeISO;
  qualityCount: BurnQualityCountDto;
}

export class BurnQualityCountAggregateByTime {
  readonly time: DateTime<true>;
  readonly qualityCount: BurnQualityCount;

  public constructor(dto: BurnQualityCountAggregateByTimeDto) {
    this.time = (DateTime.fromISO(dto.time) as DateTime<true>).toLocal();
    this.qualityCount = new BurnQualityCount(dto.qualityCount);
  }
}
