import { FC, useMemo } from "react";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { Fab, SxProps, useTheme } from "@mui/material";
import { Variant } from "./Variant";
import { Button } from "@airmont/shared/ts/ui/mui";
import { merge } from "lodash";
import { NavigationType } from "@airmont/shared/ts/ui/navigation";

export interface NavigationTypeButtonProps {
  navigationTypeByWidth: NavigationType;
  navigationTypeByUser: NavigationType | null;
  variant: Variant;
  onToggle: () => void;
  sx?: SxProps;
  onHover?: (hovering: boolean) => void;
}
export const NavigationTypeButton: FC<NavigationTypeButtonProps> = (props) => {
  const { navigationTypeByWidth, navigationTypeByUser, variant } = props;
  const theme = useTheme();
  const navigationType =
    navigationTypeByUser != null ? navigationTypeByUser : navigationTypeByWidth;

  const sx: SxProps = useMemo(() => {
    if (variant === "flat") {
      return merge(
        {
          zIndex: 1,
          position: "absolute",
          left: navigationType === "drawer" ? "364px" : "84px",
          bottom: "15px",
          minWidth: "unset",
          minHeight: "unset",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          border: "1px solid",
          borderColor: theme.palette.divider,
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.background.default,
          "&:hover": {
            backgroundColor: theme.palette.background.default,
            borderColor: theme.palette.primary.dark,
          },
        },
        props.sx
      );
    } else if (variant === "outlined") {
      return merge(
        {
          zIndex: 3,
          position: "absolute",
          left: navigationType === "drawer" ? "365px" : "83px",
          bottom: "15px",
          minWidth: "unset",
          minHeight: "unset",
          width: "25px",
          height: "25px",
          backgroundColor: theme.palette.background.default,
          "&:hover": {
            backgroundColor: theme.palette.background.default,
            borderColor: theme.palette.primary.dark,
          },
        },
        props.sx
      );
    } else {
      return merge(
        {
          zIndex: 3,
          position: "absolute",
          left: navigationType === "drawer" ? "366px" : "86px",
          bottom: "15px",
          minWidth: "unset",
          minHeight: "unset",
          width: "23px",
          height: "25px",
          backgroundColor: theme.palette.background.default,
          "&:hover": {
            backgroundColor: theme.palette.background.default,
            borderColor: theme.palette.primary.dark,
          },
        },
        props.sx
      );
    }
  }, [
    navigationType,
    props.sx,
    theme.palette.background.default,
    theme.palette.divider,
    theme.palette.primary.dark,
    theme.palette.secondary.main,
    variant,
  ]);

  const handleMouseEnter = () => {
    props.onHover?.(true);
  };
  const handleMouseLeave = () => {
    props.onHover?.(false);
  };

  return (
    <>
      {variant === "flat" && (
        <Fab
          onClick={props.onToggle}
          sx={sx}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {navigationType === "drawer" && (
            <KeyboardDoubleArrowLeftRoundedIcon fontSize={"small"} />
          )}
          {navigationType === "rail" && (
            <KeyboardDoubleArrowRightRoundedIcon fontSize={"small"} />
          )}
        </Fab>
      )}
      {variant === "outlined" && (
        <Fab
          onClick={props.onToggle}
          sx={sx}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {navigationType === "drawer" && (
            <KeyboardDoubleArrowLeftRoundedIcon fontSize={"small"} />
          )}
          {navigationType === "rail" && (
            <KeyboardDoubleArrowRightRoundedIcon fontSize={"small"} />
          )}
        </Fab>
      )}
      {variant === "elevation" && (
        <Button
          variant={"outlined"}
          onClick={props.onToggle}
          sx={sx}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          startIcon={
            navigationType === "drawer" ? (
              <KeyboardDoubleArrowLeftRoundedIcon fontSize={"small"} />
            ) : (
              <KeyboardDoubleArrowRightRoundedIcon fontSize={"small"} />
            )
          }
        />
      )}
    </>
  );
};
