import { useMemo } from "react";
import { BurnDao } from "./BurnDao";

export const useBurnDao = (): BurnDao => {
  return useMemo(
    () =>
      new BurnDao({
        accessToken: undefined,
      }),
    []
  );
};
