import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { FlueDto } from "./Flue";

export class FlueController {
  public readonly handlers: RequestHandler[];

  private readonly flues: Array<FlueDto>;

  constructor(args: { flues: Array<FlueDto> }) {
    this.flues = args.flues;
    this.handlers = [];
  }

  query = async (
    request: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse> => {
    return res(ctx.json(this.flues));
  };
}
