import { FC, ReactNode, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { TextField_size } from "@airmont/shared/ts/ui/mui";
import { useResizeDetector } from "react-resize-detector";
import { SxProps } from "@mui/system";
import { useMergeSx } from "./useMergeSx";

export interface PropertyWrapperProps {
  label: string;
  name?: string;
  size?: TextField_size;
  fullWidth?: boolean;
  sx?: SxProps;
  children?: ReactNode;
}

export const PropertyWrapper: FC<PropertyWrapperProps> = (props) => {
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  const { width, ref } = useResizeDetector();

  const size = props.size ?? "medium";
  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const sx = useMergeSx(
    props.sx,
    {
      position: "relative",
      paddingTop: "8px",
      paddingLeft: "14px",
      paddingRight: "14px",
      paddingBottom: "8px",
      display: "inline-flex",
      alignItems: "center",
      minHeight: size === "small" ? "40px" : "56px",
      width: props.fullWidth ? "100%" : "25ch",
    } as SxProps,
    [props.sx, props.fullWidth, size]
  );

  return (
    <Box
      title={props.label}
      className={"Property"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={sx}
      ref={ref}
    >
      {props.children}
      <Box
        component={"fieldset"}
        sx={{
          position: "absolute",
          borderRadius: "5px",
          borderWidth: hover ? "1px" : "1px",
          borderColor: hover
            ? theme.palette.text.primary
            : theme.palette.divider,
          top: "-9px",
          left: "-4px",
          right: "-3px",
          bottom: "-2px",
          paddingLeft: "8px",
          paddingRight: "8px",
          paddingTop: 0,
          paddingBottom: 0,
          pointerEvents: "none",
        }}
      >
        <Box
          title={props.label}
          component={"legend"}
          sx={{
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: `${width ?? 20}px`,
          }}
        >
          {props.label}
        </Box>
      </Box>
    </Box>
  );
};
