import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { BuildingSelect } from "@airmont/firefly/my-chimney/ts/building";
import { PeriodSelect } from "./periodSelect/PeriodSelect";
import { BurnInfo } from "./BurnInfo";
import { BurnTimeSeriesChart } from "./BurnTimeSeriesChart";
import {
  AddressId,
  Burn,
  BurnTemperatureSeries,
  FlueId,
} from "@airmont/firefly/shared/ts/domain";
import { useResizeDetector } from "react-resize-detector";
import { useWindowWidth } from "@airmont/shared/ts/ui/responsive";
import { useMyChimneyContext } from "../MyChimneyContext";
import { TimeframeProps } from "@airmont/shared/ts/ui/timeframe";
import { BurnList } from "./BurnList";
import { FlueSelect } from "../shared/FlueSelect";
import { useTranslation } from "react-i18next";

export interface BurnsPageContentProps {
  timeframeProps: TimeframeProps;
  burns: Array<Burn> | undefined;
  isAnonymizedTime: boolean | undefined;
  isBurnsLoading: boolean;
  isBurnsFetching: boolean;
  temperatureSeries: Array<BurnTemperatureSeries> | undefined;
  isTemperatureSeriesLoading: boolean;
  isTemperatureSeriesFetching: boolean;
}

export const BurnsPageContent: FC<BurnsPageContentProps> = (props) => {
  const {
    timeframeProps,
    burns,
    isAnonymizedTime,
    isBurnsFetching,
    temperatureSeries,
    isTemperatureSeriesFetching,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const windowWidth = useWindowWidth();
  const { width, ref } = useResizeDetector({ handleHeight: false });
  const {
    buildings,
    selectedBuilding,
    selectedFlue,
    setSelectedBuilding,
    setSelectedFlue,
  } = useMyChimneyContext();

  const [selectedBurn, setSelectedBurn] = useState<Burn | undefined>(undefined);
  const selectedBurnIds = useMemo(() => {
    return selectedBurn !== undefined ? [selectedBurn.id] : [];
  }, [selectedBurn]);

  const [forceAnonymizeTime, setForceAnonymizeTime] = useState<boolean>(false);

  useEffect(() => {
    if (burns != null && burns.length > 0) {
      setSelectedBurn(burns[0]);
    } else {
      setSelectedBurn(undefined);
    }
  }, [burns]);
  const handleSelectedBurn = (burn: Burn) => {
    setSelectedBurn(burn);
  };
  const handleBuildingChange = (value: AddressId) => {
    setSelectedBuilding(value);
  };
  const handleSelectedFlue = (flue: FlueId) => {
    setSelectedFlue(flue);
  };

  const handleAnonymizeBurnsClick = () => {
    setForceAnonymizeTime(!forceAnonymizeTime);
  };

  return (
    <Stack
      direction={"column"}
      gap={1}
      useFlexGap
      sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
      ref={ref}
    >
      <Toolbar disableGutters variant={"dense"}>
        <BuildingSelect
          buildings={buildings}
          selected={selectedBuilding.id}
          onChange={handleBuildingChange}
        />
        {selectedBuilding.getNumberOfFlues() > 1 && (
          <FlueSelect
            building={selectedBuilding}
            selectedFlue={selectedFlue}
            onSelectFlue={handleSelectedFlue}
            sx={{ ml: 1 }}
          />
        )}
      </Toolbar>
      <PeriodSelect
        timeframe={timeframeProps}
        flue={selectedFlue}
        color={"inherit"}
        sx={{ maxWidth: width ?? 100 - 100 }}
      />
      <Typography align={"center"}>{t("Burns")}</Typography>
      {localStorage.getItem("BurnsPage.anonymizeBurns") === "true" && (
        <Button onClick={handleAnonymizeBurnsClick}>
          Force Anonymize Time
        </Button>
      )}
      {isBurnsFetching && (
        <LinearProgress color={"secondary"} sx={{ height: "1px" }} />
      )}
      {!isBurnsFetching && <Divider />}
      <BurnList
        burns={burns}
        anonymizedTime={isAnonymizedTime || forceAnonymizeTime}
        maxWidth={width}
        selectedBurn={selectedBurn}
        onSelected={handleSelectedBurn}
      />
      <Stack
        direction={"column"}
        useFlexGap
        gap={1}
        sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
      >
        {selectedBurn !== undefined && (
          <BurnInfo
            burn={selectedBurn}
            anonymizedTime={isAnonymizedTime || forceAnonymizeTime}
            variant={"normal"}
            layout={windowWidth}
          />
        )}
        <Paper
          sx={{
            flexGrow: 1,
            minHeight: 0,
            minWidth: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            position: "relative",
            pl: 1,
          }}
        >
          {isTemperatureSeriesFetching && (
            <LinearProgress
              color={"secondary"}
              sx={{
                height: "2px",
                position: "absolute",
                top: "1px",
                left: theme.shape.borderRadius,
                right: theme.shape.borderRadius,
              }}
            />
          )}
          <BurnTimeSeriesChart
            flue={selectedFlue}
            burnTimeSeries={temperatureSeries}
            anonymizedTime={isAnonymizedTime || forceAnonymizeTime}
            selected={selectedBurnIds}
            selectedMonth={timeframeProps.timeframe.start}
            sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
          />
        </Paper>
      </Stack>
    </Stack>
  );
};
