import { Month } from "./Month";

export class PeriodSelectUtils {
  static getMonths(count = 24): Array<Month> {
    const months: Array<Month> = [];
    let currentMonth = Month.current();
    months.push(currentMonth);
    for (let i = 1; i <= count; i++) {
      currentMonth = currentMonth.previous();
      months.push(currentMonth);
    }
    return months;
  }
}
