import { MockedResponse, RequestHandler, ResponseComposition, rest, RestContext, RestRequest } from "msw";

export class SystemController {

  private readonly getBuildNumberHandler: RequestHandler;

  public readonly handlers: RequestHandler[];
  constructor() {
    this.getBuildNumberHandler = rest.get("/api/system/get-build-number", this.getBuildNumber);
    this.handlers = [this.getBuildNumberHandler];
  }

  async getBuildNumber (request: RestRequest, res: ResponseComposition, ctx: RestContext): Promise<MockedResponse>{
    return res(ctx.text("local-debug"));
  }
}
