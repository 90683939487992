import { ThemeOptions } from "@mui/material/styles";

export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#9acccc",
    },
    secondary: {
      main: "#669999",
    },
    background: {
      default: "#EDFCFC",
      paper: "#FBFBFB",
    },
    action: {
      selected: "rgba(0, 0, 0, 0.14)",
      selectedOpacity: 0.15,
    },
  },
  shape: {
    borderRadius: 3,
  },
  typography: {
    h1: {
      fontSize: "1rem",
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 400,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 400,
    },
  },
};
