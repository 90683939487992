import { useCallback, useContext, useMemo } from "react";
import { useUserOrNull } from "shared-ts-utils-authentication";
import { useAppInfo } from "@airmont/shared/ts/ui/app-info";
import { SettingConfig } from "./SettingConfig";
import { UserSettingsContext } from "./UserSettingsContext";
import { SettingKey } from "./SettingKeyDto";
import { NonAsyncSettingsStorage } from "./storage/NonAsyncSettingsStorage";
import { AsyncSettingsStorage } from "./storage/AsyncSettingsStorage";
import { ObjectUtils, UnsupportedError } from "@airmont/shared/ts/utils/core";
import { SettingOverrides } from "./SettingOverrides";

export const useUserSetting = <TValue>(
  name: string,
  settingConfig: SettingConfig<TValue>,
  overrides?: SettingOverrides<TValue>
): [TValue | null, (newValue: TValue | null) => void] => {
  const appInfo = useAppInfo();
  const user = useUserOrNull();
  const { settings, setSettings, storage } = useContext(UserSettingsContext);

  const settingKey = SettingKey.from(name, appInfo.key, user?.id ?? null);
  const settingAsString = settings[settingKey.asString];
  const value = useMemo(() => {
    return settingAsString != null
      ? overrides?.deserialize != null
        ? overrides.deserialize(settingAsString)
        : settingConfig.deserialize(settingAsString)
      : null;
  }, [settingAsString, settingConfig, overrides]);

  const setValue_ = useCallback(
    (newValue: TValue | null) => {
      setSettings((prev) => {
        return {
          ...prev,
          [settingKey.asString]:
            newValue != null
              ? overrides?.serialize != null
                ? overrides.serialize(newValue)
                : settingConfig.serialize(newValue)
              : null,
        };
      });

      if (overrides?.storeLocally === true) {
        localStorage.writeValue(settingKey, newValue, settingConfig, overrides);
      } else if (storage.type === "NonAsync") {
        const nonAsyncStorage = storage as NonAsyncSettingsStorage;
        nonAsyncStorage.writeValue(
          settingKey,
          newValue,
          settingConfig,
          overrides
        );
      } else if (storage.type === "Async") {
        const asyncStorage = storage as AsyncSettingsStorage;
        asyncStorage.writeValue(settingKey, newValue, settingConfig, overrides);
      } else {
        throw new UnsupportedError(
          "Unsupported SettingsStorage.TValue: " + storage.type
        );
      }
    },
    [settingKey, setSettings, settingConfig, overrides, storage]
  );

  return [value, setValue_];
};

export const useUserSettingWithDefault = <TValue, TDefault = TValue>(
  name: string,
  settingConfig: SettingConfig<TValue>,
  defaultValue: TDefault,
  overrides?: SettingOverrides<TValue>
): [TValue | TDefault, (newValue: TValue | null) => void] => {
  const appInfo = useAppInfo();
  const user = useUserOrNull();
  const { settings, setSettings, storage, localStorage } =
    useContext(UserSettingsContext);

  const settingKey = useMemo(
    () => SettingKey.from(name, appInfo.key, user?.id ?? null),
    [name, appInfo.key, user?.id]
  );
  const settingAsString =
    overrides?.storeLocally === true
      ? localStorage.readRawValue(settingKey, settingConfig, overrides)
      : settings[settingKey.asString];

  const value = useMemo(() => {
    return settingAsString != null
      ? overrides?.deserialize != null
        ? overrides.deserialize(settingAsString)
        : settingConfig.deserialize(settingAsString)
      : null;
  }, [settingAsString, settingConfig, overrides]);

  const setValue_ = useCallback(
    (newValue: TValue | null) => {
      const prevValueSerialized = settings[settingKey.asString];
      const newValueSerialized =
        newValue != null
          ? overrides?.serialize != null
            ? overrides.serialize(newValue)
            : settingConfig.serialize(newValue)
          : null;
      const isChange = !ObjectUtils.equals(
        prevValueSerialized,
        newValueSerialized
      );
      if (isChange) {
        setSettings((prev) => {
          return {
            ...prev,
            [settingKey.asString]: newValueSerialized,
          };
        });

        if (overrides?.storeLocally === true) {
          localStorage.writeValue(
            settingKey,
            newValue,
            settingConfig,
            overrides
          );
        } else if (storage.type === "NonAsync") {
          const nonAsyncStorage = storage as NonAsyncSettingsStorage;
          nonAsyncStorage.writeValue(
            settingKey,
            newValue,
            settingConfig,
            overrides
          );
        } else if (storage.type === "Async") {
          const asyncStorage = storage as AsyncSettingsStorage;
          asyncStorage.writeValue(
            settingKey,
            newValue,
            settingConfig,
            overrides
          );
        } else {
          throw new UnsupportedError(
            "Unsupported SettingsStorage.TValue: " + storage.type
          );
        }
      }
    },
    [settingKey, settings, setSettings, overrides, settingConfig, storage]
  );

  if (value != null) {
    return [value, setValue_];
  }

  return [defaultValue, setValue_];
};
