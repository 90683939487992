import { ChartsYReferenceLineProps } from "@mui/x-charts/ChartsReferenceLine/ChartsYReferenceLine";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import {
  FlueMetrics,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { DateTime } from "luxon";
import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { resolveTimeUnitLabel } from "./resolveTimeUnitLabel";
import { SizeClass, useWindowWidth } from "@airmont/shared/ts/ui/responsive";

export const useResolveMaxTemperatureReferenceLineProps = (
  flue: Flue,
  unit: string,
  month?: DateTime
): Array<ChartsYReferenceLineProps<number>> => {
  const theme = useTheme();
  const windowWidth = useWindowWidth();
  const { t } = useTranslation("firefly-shared-ts-domain");
  return useMemo(() => {
    const timeUnits: Array<FlueMetricsTimeUnitEnum> = [
      FlueMetricsTimeUnitEnum.Ever,
      FlueMetricsTimeUnitEnum.SinceSweep,
      FlueMetricsTimeUnitEnum.Year,
      FlueMetricsTimeUnitEnum.Month,
    ];
    const flueMetricsArray: Array<FlueMetrics> = [];
    timeUnits.forEach((timeUnit) => {
      const flueMetrics = flue.querySingleMetricsOrUndef(timeUnit, month);
      if (flueMetrics !== undefined) {
        flueMetricsArray.push(flueMetrics);
      }
    });

    const referenceLines: Array<ChartsYReferenceLineProps<number>> = [];
    flueMetricsArray.forEach((flueMetrics) => {
      if (flueMetrics) {
        const timeUnitLabel = resolveTimeUnitLabel(
          t,
          flueMetrics.timeUnit,
          flueMetrics.time
        );

        referenceLines.push({
          y: flueMetrics.metrics.temperatureMax,
          labelAlign: "end",
          lineStyle: {
            stroke: theme.palette.warning.main,
            strokeDasharray: theme.spacing(1),
            strokeDashoffset: theme.spacing(1),
          },
          label: `${t("Max Temp.")} (${timeUnitLabel.toLowerCase()}`,
          labelStyle: {
            fill: theme.palette.text.secondary,
            fontSize:
              windowWidth === SizeClass.Compact
                ? "10px"
                : windowWidth === SizeClass.Medium
                ? "smaller"
                : undefined,
          },
        });
      }
    });

    const consolidated: Array<ChartsYReferenceLineProps<number>> = [];
    referenceLines.forEach((it, index) => {
      if (index === 0) {
        consolidated.push(it);
      } else if (index > 0) {
        const prev = referenceLines[index - 1];
        if (it.y !== prev.y) {
          const lastConsolidated = consolidated[consolidated.length - 1];
          consolidated[consolidated.length - 1] = {
            ...lastConsolidated,
            label: (lastConsolidated.label += `): ${prev.y} ${unit}`),
          };
          consolidated.push(it);
        } else {
          const lastConsolidated = consolidated[consolidated.length - 1];
          const flueMetrics = flueMetricsArray[index];
          const timeUnitLabel = resolveTimeUnitLabel(
            t,
            flueMetrics.timeUnit,
            flueMetrics.time
          );
          consolidated[consolidated.length - 1] = {
            ...lastConsolidated,
            label:
              (lastConsolidated.label += `, ${timeUnitLabel.toLowerCase()}`),
          };
        }
      }
    });
    const lastConsolidated = consolidated[consolidated.length - 1];
    consolidated[consolidated.length - 1] = {
      ...lastConsolidated,
      label: (lastConsolidated.label += `): ${lastConsolidated.y} ${unit}`),
    };

    return consolidated;
  }, [month, flue, windowWidth, theme, t, unit]);
};
