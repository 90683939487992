import { Breadcrumbs, Typography } from "@mui/material";
import React, { FC } from "react";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { useTranslation } from "react-i18next";

export interface FlueBreadcrumbsProps {
  flue: Flue;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  noWrap?: boolean;
}
export const FlueBreadcrumbs: FC<FlueBreadcrumbsProps> = (props) => {
  const { flue } = props;
  const noWrap = props.noWrap ?? false;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    props.onClick?.(event);
  };
  const chimney =
    flue.chimney ??
    _throw(new IllegalStateError("chimney not set on flue: " + flue.id));

  let chimneyLabel = "";
  if (chimney.mcFields?.name != null) {
    chimneyLabel += chimney.mcFields?.name;
  } else {
    chimneyLabel += t("Chimney");
  }

  let flueLabel = "";
  if (flue.mcFields?.name != null) {
    flueLabel += flue.mcFields?.name;
  } else {
    flueLabel += t("Flue");
  }

  return (
    <Breadcrumbs
      onClick={handleClick}
      sx={{ ".MuiBreadcrumbs-ol": { flexWrap: noWrap ? "nowrap" : undefined } }}
    >
      <Typography title={t("Chimney")}>{chimneyLabel}</Typography>
      <Typography title={t("Flue")}>{flueLabel}</Typography>
    </Breadcrumbs>
  );
};
