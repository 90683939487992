import React, { FC, useMemo } from "react";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { NavigationItem } from "../../NavigationItem";
import { ActionGroup } from "@airmont/shared/ts/ui/action";
import { Layout } from "../../../Layout";

export interface GroupNodeProps {
  layout: Layout;
  item: ActionGroup;
  hideLabel?: boolean;
  hasSiblings: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}
export const GroupNode: FC<GroupNodeProps> = (props) => {
  const { layout, item, hasSiblings, onClick } = props;
  const hideLabel = props.hideLabel ?? false;

  return (
    <NavigationItem
      layout={layout}
      className={"GroupNode" + (hasSiblings ? " hasSiblings" : "")}
      onClick={onClick}
      icon={item?.icon}
      selected={item?.selected}
      textPrimary={
        !hideLabel
          ? layout === "medium" || layout === "compact"
            ? item.nameBreakable ?? props.item?.name
            : item.name
          : undefined
      }
      endAdornment={
        layout === "large" ? <ArrowDropDownRoundedIcon /> : undefined
      }
      sx={{
        "&&&": {
          borderRadius: "inherit",
          pl: 0,
          pr: 0,
          "&.hasSiblings": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      }}
    />
  );
};
