import { FC, ReactElement, useMemo, useState } from "react";
import { Box } from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { Button } from "@airmont/shared/ts/ui/mui";
import { TooltipOrNot } from "@airmont/shared/ts/ui/tooltip";

export interface DeckProps {
  navigator?: boolean;
  children: Array<ReactElement>;
  selected?: number;
  layout?: "compact" | "standard";
  sx?: SxProps<Theme>;
  className?: string | undefined;
  renderNavigationButtons?: boolean;
  previousButtonProps?: {
    tooltip?: string;
  };
  nextButtonProps?: {
    tooltip?: string;
  };
  onChange?: (index: number) => void;
}

export const Deck: FC<DeckProps> = (props) => {
  const renderNavigationButtons = props.renderNavigationButtons ?? true;
  const singleChild = props.children.length === 1;
  const [selected, setSelected] = useState<number | undefined>(
    props.selected === undefined ? 0 : undefined
  );
  const sel = props.selected ?? selected;
  if (sel === undefined) {
    throw new Error("Illegal state");
  }
  const disablePrev = sel === 0;
  const disableNext = sel === props.children.length - 1;

  const handlePrev = () => {
    const nextIndex = sel > 0 ? sel - 1 : 0;
    if (props.onChange) {
      props.onChange(nextIndex);
    } else {
      setSelected(nextIndex);
    }
  };

  const handleNext = () => {
    const nextIndex =
      sel < props.children.length - 1 ? sel + 1 : props.children.length - 1;
    if (props.onChange) {
      props.onChange(nextIndex);
    } else {
      setSelected(nextIndex);
    }
  };
  const layout = props.layout ?? "standard";
  const gutterSize = layout === "compact" ? "24px" : "36px";
  const defaultSx: SxProps = {
    display: "flex",
    paddingLeft: singleChild ? gutterSize : 0,
    paddingRight: singleChild ? gutterSize : 0,
  };
  const sx: SxProps = useMemo(
    () =>
      props.sx != null ? ({ ...props.sx, ...defaultSx } as SxProps) : defaultSx,
    [props.sx, defaultSx]
  );

  return (
    <Box sx={sx} className={props.className}>
      {renderNavigationButtons && !singleChild && (
        <TooltipOrNot title={props.previousButtonProps?.tooltip}>
          <Button
            startIcon={<ChevronLeftRoundedIcon />}
            onClick={handlePrev}
            disabled={disablePrev}
            sx={{ maxWidth: gutterSize }}
          />
        </TooltipOrNot>
      )}
      {props.children[sel]}
      {renderNavigationButtons && !singleChild && (
        <TooltipOrNot title={props.nextButtonProps?.tooltip}>
          <Button
            startIcon={<ChevronRightRoundedIcon />}
            disabled={disableNext}
            onClick={handleNext}
            sx={{ maxWidth: gutterSize }}
          />
        </TooltipOrNot>
      )}
    </Box>
  );
};
