import { FC, ReactNode, useMemo } from "react";
import {
  ChartsAxisHighlight,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  LineSeriesType,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { TimeSeries } from "@airmont/firefly/shared/ts/timeseries";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import {
  AxisConfig,
  ChartsXAxisProps,
  ChartsYAxisProps,
  ScaleName,
} from "@mui/x-charts/models/axis";
import { SxProps } from "@mui/material";
import { resolveXAxisConfig } from "./resolveXAxisConfig";
import { resolveYAxisConfig } from "./resolveYAxisConfig";
import { merge } from "lodash";
import {
  XAxisDefaultConfig,
  YAxisDefaultConfig,
} from "@airmont/shared/ts/ui/mui";
import { AllSeriesType } from "@mui/x-charts/models/seriesType";
import { MathUtils } from "@airmont/shared/ts/utils/core";
import { ChartsTooltipAxisContent } from "./ChartsTooltipAxisContent";

export type MuiTimeSeriesOptions = Omit<
  MakeOptional<LineSeriesType, "type">,
  "data"
>;

export interface TimeSeriesMuiChartProps {
  timeSeries: Array<TimeSeries<number | null>>;
  timeSeriesOptions?: Array<MuiTimeSeriesOptions>;
  yAxisConfig?: Array<
    MakeOptional<AxisConfig<ScaleName, any, ChartsYAxisProps>, "id">
  >;
  xAxisConfig?: MakeOptional<
    AxisConfig<ScaleName, any, ChartsXAxisProps>,
    "id"
  >;
  width?: number;
  height?: number;
  sx?: SxProps;
  children?: ReactNode;
}

export const TimeSeriesMuiChart: FC<TimeSeriesMuiChartProps> = (props) => {
  const {
    timeSeries,
    timeSeriesOptions,
    xAxisConfig,
    yAxisConfig,
    width,
    height,
    sx,
  } = props;
  const xAxis: Array<XAxisDefaultConfig> = useMemo(() => {
    return resolveXAxisConfig(timeSeries, xAxisConfig);
  }, [timeSeries, xAxisConfig]);

  const yAxis: Array<YAxisDefaultConfig> = useMemo(() => {
    return resolveYAxisConfig(timeSeries, yAxisConfig);
  }, [timeSeries, yAxisConfig]);

  const series: Array<AllSeriesType> = useMemo(() => {
    return timeSeries.map((series, seriesIndex) => {
      const defaultOptions: LineSeriesType = {
        type: "line",
        showMark: false,
        data: series.points.map((point) => point.value),
        valueFormatter: (value) => {
          return value == null
            ? ""
            : `${MathUtils.round(value)} ${series.info.unit}`;
        },
      };
      const options: MakeOptional<LineSeriesType, "type"> | undefined =
        timeSeriesOptions?.[seriesIndex];

      return merge(defaultOptions, options);
    });
  }, [timeSeries, timeSeriesOptions]);

  return (
    <ResponsiveChartContainer
      xAxis={xAxis}
      yAxis={yAxis}
      series={series}
      width={width}
      height={height}
      sx={sx}
    >
      <LinePlot />
      <ChartsYAxis />
      <ChartsXAxis />
      <ChartsTooltip
        slots={{
          axisContent: ChartsTooltipAxisContent,
        }}
      />
      <ChartsAxisHighlight x={"line"} />
      {props.children}
    </ResponsiveChartContainer>
  );
};
