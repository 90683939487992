import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { FlueUpdateSpec } from "./FlueUpdate";

export class FlueDao {
  private fetchHelper: FetchHelper;

  constructor() {
    this.fetchHelper = new FetchHelper({ basePath: "/api/flue/" });
  }
  update(update: FlueUpdateSpec): Promise<void> {
    return this.fetchHelper.post("update", update).fetch();
  }
}
