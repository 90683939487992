import React, { FC, ReactNode } from "react";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { PropertyValue } from "./PropertyValue";
import { TextField_size } from "@airmont/shared/ts/ui/mui";
import { SxProps } from "@mui/system";
import { ValueOptions } from "./ValueOptions";
import { isValueOption, ValueOption } from "./ValueOption";
import { PropertyValueType } from "./PropertyValueType";
import { _throw } from "@airmont/shared/ts/utils/core";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { useMergeSx } from "./useMergeSx";
import { useOptionLabelResolver } from "./OptionLabelResolver";

export interface PropertyEditAutocompleteMultipleProps {
  label: string;
  name: string;
  value: PropertyValue;
  valueOptions: ValueOptions;
  onChange: (value: PropertyValue, name: string) => void;
  unit?: string;
  type?: PropertyValueType;
  size?: TextField_size;
  fullWidth?: boolean;
  info?: ReactNode;
  autoFocus?: boolean;
  helperText?: ReactNode;
  inputRef?: React.Ref<HTMLInputElement>;
  sx?: SxProps;
}

export const PropertyEditAutocompleteMultiple: FC<
  PropertyEditAutocompleteMultipleProps
> = (props) => {
  const sx = useMergeSx(
    {
      width: props.fullWidth ? "100%" : "25ch",
    },
    props.sx,
    [props.sx, props.fullWidth]
  );

  const freeSolo = !props.valueOptions.restrictToOptions;

  if (!freeSolo) {
    const optionForValue = props.valueOptions.options.find((it) => {
      if (isValueOption(it)) {
        return it.id === props.value;
      } else {
        return it === props.value;
      }
    });

    if (optionForValue == null) {
      throw new Error(
        `Given value [${
          props.value
        }] does not exists in given options: ${JSON.stringify(
          props.valueOptions.options
        )}`
      );
    }
  }

  const optionLabelResolver = useOptionLabelResolver(props.valueOptions);

  return (
    <Autocomplete
      freeSolo={freeSolo}
      options={props.valueOptions.options}
      multiple={props.valueOptions.multiple}
      filterSelectedOptions={props.valueOptions.filterSelectedOptions}
      getOptionLabel={optionLabelResolver.getOptionLabel}
      size={props.size}
      value={props.value as unknown as Array<string>}
      fullWidth={props.fullWidth}
      sx={sx}
      onChange={(event, value) => {
        const value_ = value as Array<string | ValueOption>;
        if (value_.length > 0) {
          const valuesAsStrings: Array<string | number> = value_.map(
            (option) => {
              if (isValueOption(option)) {
                return option.id;
              } else {
                return option;
              }
            }
          );

          props.onChange(
            valuesAsStrings,
            props.name ?? _throw(new Error("name cannot be null"))
          );
        } else {
          props.onChange(
            [],
            props.name ?? _throw(new Error("name cannot be null"))
          );
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={props.label}
            helperText={props.helperText}
            autoFocus={props.autoFocus}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {props.info && (
                    <InputAdornment position="end">
                      {props.unit}{" "}
                      {props.info && (
                        <Tooltip title={props.info}>
                          <IconButton size={"small"}>
                            <HelpOutlineRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            inputRef={props.inputRef}
          />
        );
      }}
    />
  );
};
