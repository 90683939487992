import {
  BurnDto,
  BurnTemperatureSeriesDto,
  createBurnDto,
  createBurnTemperatureSeries,
} from "@airmont/firefly/shared/ts/domain";
import { ChimneyDto } from "@airmont/firefly/my-chimney/ts/building";

export const createBurnTemperatureSeriesMocks = (
  chimneys: Array<ChimneyDto>
): { burns: Array<BurnDto>; burnSeries: Array<BurnTemperatureSeriesDto> } => {
  const burns: Array<BurnDto> = [];
  const burnSeries: Array<BurnTemperatureSeriesDto> = [];

  chimneys.forEach((chimney) => {
    chimney.flues.forEach((flue) => {
      const burn = createBurnDto({
        flueId: flue.id,
      });
      burns.push(burn);
      const burnTempereatureSerie: BurnTemperatureSeriesDto =
        createBurnTemperatureSeries({ burn });
      burnSeries.push(burnTempereatureSerie);
    });
  });

  return {
    burns: burns,
    burnSeries: burnSeries,
  };
};
