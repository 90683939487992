import { FlueMetrics } from "../metrics/FlueMetrics";
import { ArrayUtils, MathUtils } from "@airmont/shared/ts/utils/core";
import { useMemo } from "react";
import { useSummarizeFlueMetricValues } from "./useSummarizeFlueMetricValues";
import { DateTime } from "luxon";
import { MonthWheel } from "@airmont/shared/ts/utils/luxon";

export const useResolveSootIndexCumulativePredictedSeries = (args: {
  selectedYear: number;
  selectedMonth: number;
  nowYear: number;
  nowMonth: number;
  flueMetricsByMonth: Record<string, FlueMetrics>;
  predictedFlueMetricsByMonth: Record<string, FlueMetrics>;
}): Array<number | null> => {
  const {
    selectedYear,
    nowYear,
    nowMonth,
    flueMetricsByMonth,
    predictedFlueMetricsByMonth,
  } = args;

  const startMetrics = useSummarizeFlueMetricValues(
    DateTime.local(nowYear, nowMonth).plus({ month: 1 }) as DateTime<true>,
    flueMetricsByMonth
  );

  return useMemo(() => {
    const untilTime = DateTime.local(nowYear).plus({
      year: 1,
    }) as DateTime<true>;
    let currSootIndex = startMetrics.sootIndex;

    return new MonthWheel({
      startYear: selectedYear,
      startMonth: 1,
    }).runUntilTime(untilTime, (month, year) => {
      if (year !== nowYear || month <= nowMonth) {
        return null;
      }
      const flueMetrics = predictedFlueMetricsByMonth[`${year}-${month}`];
      currSootIndex += flueMetrics?.metrics.sootIndex ?? 0;

      return MathUtils.round(currSootIndex);
    });
  }, [
    startMetrics.sootIndex,
    selectedYear,
    nowYear,
    nowMonth,
    predictedFlueMetricsByMonth,
  ]);
};
