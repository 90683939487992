import { AppLayout } from "@airmont/shared/ts/ui/app-layout";
import { Action, ActionGroup, RouteItem } from "@airmont/shared/ts/ui/action";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import PaletteRoundedIcon from "@mui/icons-material/PaletteRounded";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Outlet, useLocation } from "react-router-dom";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {
  SizeClass,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import React from "react";
import { Typography, useTheme } from "@mui/material";
import { NotificationContextProvider } from "@airmont/shared/ts/ui/notification";
import RoofingRoundedIcon from "@mui/icons-material/RoofingRounded";
import { KeyFiguresPage } from "./keyFigures/KeyFiguresPage";
import {
  StringSetting,
  useUserSetting,
} from "@airmont/shared/ts/utils/user-settings";
import { useTranslation } from "react-i18next";
import { useUser } from "shared-ts-utils-authentication";
import { McUser } from "firefly/my-chimney/ts/user";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { MyChimneyContextLoader } from "./MyChimneyContextLoader";
import { useNavigation } from "@airmont/shared/ts/ui/navigation";
import { usePostHogTrackReactRouter } from "shared-ts-posthog";

export const RootPage = () => {
  const theme = useTheme();
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const user = useUser<McUser>();
  const location = useLocation();
  usePostHogTrackReactRouter();
  const [appTheme, setAppTheme] = useUserSetting("theme", StringSetting);
  const windowWidthComparer = useWindowWidthComparer();

  const { navigationItems, handleSelected } = useNavigation(
    [
      {
        type: "Component",
        render: (navigationType) => (
          <AirmontLogoImg
            size={navigationType === "drawer" ? "standard" : "small"}
            sx={{ ml: 1, mr: 1 }}
          />
        ),
      },
      {
        type: "Component",
        render: (navigationType) => (
          <Typography
            variant={"button"}
            color={"secondary"}
            align={"center"}
            sx={{
              paddingTop: theme.spacing(1),
              textTransform: "unset",
              fontSize: navigationType === "drawer" ? "2em" : undefined,
            }}
          >
            {t("My Chimney")}
          </Typography>
        ),
      },
      {
        type: "Component",
        render: (navigationType) => (
          <Typography
            variant={"button"}
            align={"center"}
            color={theme.palette.warning.main}
            sx={{
              textTransform: "unset",
              pb: 1,
              fontSize: navigationType === "rail" ? "10px" : undefined,
              fontWeight: navigationType === "rail" ? 500 : undefined,
            }}
          >
            {t("Public Preview")}
          </Typography>
        ),
      },
      {
        type: "RouteItem",
        selectable: true,
        name: t("Overview"),
        route: "/",
        icon: <RoofingRoundedIcon />,
        selected: false,
      } as RouteItem,
      {
        type: "RouteItem",
        selectable: true,
        name: t("Burns"),
        route: "/burns",
        icon: <AnalyticsRoundedIcon />,
        selected: false,
      } as RouteItem,
      {
        type: "RouteItem",
        selectable: true,
        route: "/guidance",
        name: t("Guidance"),
        icon: <LocalLibraryRoundedIcon />,
        selected: false,
      } as RouteItem,
      {
        type: "RouteItem",
        selectable: true,
        route: "/combustion-plant",
        name: t("Combustion Plant"),
        nameBreakable: t("Combustion Plant:breakable"),
        icon: <SettingsRoundedIcon />,
        selected: false,
      } as RouteItem,
      { type: "Spacer" },
      localStorage.getItem("themePage.enabled") === "true"
        ? ({
            type: "RouteItem",
            selectable: true,
            name: "Theme",
            route: "/theme",
            reference: "/theme",
            icon: <PaletteRoundedIcon />,
          } as RouteItem)
        : undefined,
      {
        type: "ActionGroup",
        icon: <MenuRoundedIcon />,
        preferences: {
          displayInline: true,
        },
        items: [
          {
            type: "RouteItem",
            selectable: true,
            route: "/user",
            name:
              windowWidthComparer.sizeClass === SizeClass.Compact
                ? user.firstName
                : user.fullName,
            icon: <PersonRoundedIcon />,
            selected: false,
          } as RouteItem,
          {
            type: "Action",
            name: appTheme === "dark" ? t("Light") : t("Dark"),
            icon:
              appTheme === "dark" ? (
                <LightModeRoundedIcon />
              ) : (
                <DarkModeRoundedIcon />
              ),
            selected: false,
            onExecute: (item) => {
              setAppTheme(appTheme === "dark" ? "light" : "dark");
            },
          } as Action,
          {
            type: "RouteItem",
            selectable: true,
            route: "/about",
            name: `${t("About")} ${t("My Chimney")}`,
            icon: <InfoRoundedIcon />,
            selected: false,
          } as RouteItem,
        ],
      } as ActionGroup,
    ],
    [windowWidthComparer, appTheme, t]
  );

  return (
    <NotificationContextProvider>
      <AppLayout
        variant={"flat"}
        navigationItems={(navigationType) => {
          if (navigationType === "rail") {
            return navigationItems.filter((it) => !React.isValidElement(it));
          }

          return navigationItems;
        }}
        main={
          location.pathname === "/" ? (
            <MyChimneyContextLoader>
              <KeyFiguresPage />
            </MyChimneyContextLoader>
          ) : (
            <Outlet />
          )
        }
        onNavigationItemSelected={handleSelected}
      />
    </NotificationContextProvider>
  );
};
