import { FC } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  CircularProgress,
  Fab,
  FormControlLabel,
  LinearProgress,
  Rating,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NavigationIcon from "@mui/icons-material/Navigation";

export const ThemeDemo: FC = () => {
  return (
    <Stack direction={"column"} spacing={2} sx={{ pt: 1 }}>
      <Stack direction={"row"} spacing={1}>
        <Button color={"primary"} variant={"contained"}>
          primary
        </Button>
        <Button color={"primary"} variant={"contained"} disabled>
          primary
        </Button>
        <Button color={"primary"} variant={"outlined"}>
          primary
        </Button>
        <Button color={"primary"} variant={"outlined"} disabled>
          primary
        </Button>
        <Button color={"primary"} variant={"text"}>
          primary
        </Button>
        <Button color={"primary"} variant={"text"} disabled>
          primary
        </Button>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Button color={"secondary"} variant={"contained"}>
          seconda
        </Button>
        <Button color={"secondary"} variant={"contained"} disabled>
          seconda
        </Button>
        <Button color={"secondary"} variant={"outlined"}>
          seconda
        </Button>
        <Button color={"secondary"} variant={"outlined"} disabled>
          seconda
        </Button>
        <Button color={"secondary"} variant={"text"}>
          seconda
        </Button>
        <Button color={"secondary"} variant={"text"} disabled>
          seconda
        </Button>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Button variant={"contained"} color={"info"}>
          Info
        </Button>
        <Button variant={"contained"} color={"success"}>
          Success
        </Button>
        <Button variant={"contained"} color={"warning"}>
          Warning
        </Button>
        <Button variant={"contained"} color={"error"}>
          Error
        </Button>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <ButtonGroup variant="contained" color={"primary"}>
          <Button>One</Button>
          <Button>Two</Button>
          <Button>Three</Button>
        </ButtonGroup>
        <ButtonGroup variant="outlined" color={"primary"}>
          <Button>One</Button>
          <Button>Two</Button>
          <Button>Three</Button>
        </ButtonGroup>
        <ButtonGroup variant="text" color={"primary"}>
          <Button>One</Button>
          <Button>Two</Button>
          <Button>Three</Button>
        </ButtonGroup>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <ButtonGroup variant="contained" color={"secondary"}>
          <Button>One</Button>
          <Button>Two</Button>
          <Button>Three</Button>
        </ButtonGroup>
        <ButtonGroup variant="outlined" color={"secondary"}>
          <Button>One</Button>
          <Button>Two</Button>
          <Button>Three</Button>
        </ButtonGroup>
        <ButtonGroup variant="text" color={"secondary"}>
          <Button>One</Button>
          <Button>Two</Button>
          <Button>Three</Button>
        </ButtonGroup>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Fab variant={"circular"} color={"primary"}>
          <AddIcon />
        </Fab>
        <Fab variant={"circular"} color={"secondary"}>
          <EditIcon />
        </Fab>
        <Fab variant={"extended"} color={"primary"}>
          <FavoriteIcon />
        </Fab>
        <Fab variant={"extended"} color={"secondary"}>
          <NavigationIcon />
        </Fab>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <FormControlLabel
          control={<Switch defaultChecked color={"primary"} />}
          label="Primary"
        />
        <FormControlLabel
          control={<Switch defaultChecked color={"secondary"} />}
          label="Secondary"
        />
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Slider defaultValue={50} min={0} max={100} color={"primary"} />
        <Slider defaultValue={50} min={0} max={100} color={"secondary"} />
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Rating defaultValue={3} max={3} />
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <TextField
          variant={"outlined"}
          color={"primary"}
          value={"Primary Outlined"}
        />
        <TextField
          variant={"filled"}
          color={"primary"}
          value={"Primary Filled"}
        />
        <TextField
          variant={"standard"}
          color={"primary"}
          value={"Primary Standard"}
        />
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <TextField
          variant={"outlined"}
          color={"secondary"}
          value={"Secondary Outlined"}
        />
        <TextField
          variant={"filled"}
          color={"secondary"}
          value={"Secondary Filled"}
        />
        <TextField
          variant={"standard"}
          color={"secondary"}
          value={"Secondary Standard"}
        />
      </Stack>
      <Accordion>
        <AccordionSummary>Typography</AccordionSummary>
        <AccordionDetails>
          <Typography variant={"h1"}>Heading h1</Typography>
          <Typography variant={"h2"}>Heading h2</Typography>
          <Typography variant={"h3"}>Heading h3</Typography>
          <Typography variant={"h4"}>Heading h4</Typography>
          <Typography variant={"h5"}>Heading h4</Typography>
          <Typography variant={"h6"}>Heading h6</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Progress, primary</AccordionSummary>
        <AccordionDetails>
          <Stack direction={"column"} spacing={1}>
            <CircularProgress color={"primary"} />
            <LinearProgress color={"primary"} variant={"indeterminate"} />
            <LinearProgress
              color={"primary"}
              variant={"determinate"}
              value={20}
            />
            <LinearProgress
              color={"primary"}
              variant={"buffer"}
              value={50}
              valueBuffer={20}
            />
            <LinearProgress color={"primary"} variant={"query"} />
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Progress, secondary</AccordionSummary>
        <AccordionDetails>
          <Stack direction={"column"} spacing={1}>
            <CircularProgress color={"secondary"} />
            <LinearProgress color={"secondary"} variant={"indeterminate"} />
            <LinearProgress
              color={"secondary"}
              variant={"determinate"}
              value={20}
            />
            <LinearProgress
              color={"secondary"}
              variant={"buffer"}
              value={50}
              valueBuffer={20}
            />
            <LinearProgress color={"secondary"} variant={"query"} />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
