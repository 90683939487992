import { RequestHandler } from "msw";
import { FireplaceDto } from "./Fireplace";

export class FireplaceController {
  public readonly handlers: RequestHandler[];

  private readonly fireplaces: Array<FireplaceDto>;

  constructor(args: { fireplaces: Array<FireplaceDto> }) {
    this.fireplaces = args.fireplaces;
    this.handlers = [];
  }
}
