import { FlueMetricsTimeUnitEnum } from "@airmont/firefly/shared/ts/domain";
import { DateTime, Info } from "luxon";
import { TFunction } from "i18next";

export const resolveTimeUnitLabel = (
  t: TFunction<"firefly-shared-ts-domain">,
  timeUnit: FlueMetricsTimeUnitEnum,
  time?: DateTime
): string => {
  if (timeUnit === FlueMetricsTimeUnitEnum.Ever) {
    return t("TimeUnit.Ever");
  } else if (timeUnit === FlueMetricsTimeUnitEnum.SinceSweep) {
    return t("TimeUnit.SinceSweep");
  } else if (timeUnit === FlueMetricsTimeUnitEnum.Year && time !== undefined) {
    return `${time.year}`;
  } else if (timeUnit === FlueMetricsTimeUnitEnum.Month && time !== undefined) {
    return `${Info.months()[time.month - 1]}`;
  } else {
    return "?";
  }
};
