import { BuildingLoaderPage } from "./BuildingLoaderPage";
import { BuildingSelectionLoader } from "./BuldingSelectionLoader";
import { MyChimneyContextProvider } from "./MyChimneyContext";
import { BuildingLoader } from "@airmont/firefly/my-chimney/ts/building";
import React, { FC, ReactNode } from "react";
import { NoBuildingsPage } from "./NoBuildingsPage";

export interface MyChimneyContextLoaderProps {
  children: ReactNode;
}
export const MyChimneyContextLoader: FC<MyChimneyContextLoaderProps> = (
  props
) => {
  return (
    <BuildingLoader
      loader={<BuildingLoaderPage />}
      noBuildings={<NoBuildingsPage />}
    >
      {(buildings, onBuildingsChange) => {
        return (
          <BuildingSelectionLoader buildings={buildings}>
            {(selectedBuilding, selectedFlue) => (
              <MyChimneyContextProvider
                buildings={buildings}
                onBuildingsChange={onBuildingsChange}
                selectedBuilding={selectedBuilding}
                selectedFlue={selectedFlue}
              >
                {props.children}
              </MyChimneyContextProvider>
            )}
          </BuildingSelectionLoader>
        );
      }}
    </BuildingLoader>
  );
};
