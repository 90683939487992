import { IllegalStateError } from "@airmont/shared/ts/utils/core";
import { Chimney } from "./chimney/Chimney";
import { Flue } from "./chimney/flue/Flue";

export class Chimneys {
  readonly chimneys: Array<Chimney>;
  constructor(chimneys: Array<Chimney>) {
    this.chimneys = chimneys;
  }

  getIndexOfChimney(chimney: Chimney): number {
    return this.chimneys.findIndex((it) => it === chimney);
  }

  getFirstChimney(): Chimney {
    if (this.chimneys.length === 0) {
      throw new IllegalStateError("No chimneys to get");
    }
    return this.chimneys[0];
  }

  getNextChimney(current: Chimney): Chimney {
    const indexOfCurrent = this.chimneys.findIndex((it) => it === current);
    if (indexOfCurrent === -1) {
      throw new IllegalStateError("Could not find Chimney: " + current.id);
    }

    if (indexOfCurrent > this.chimneys.length - 1) {
      return this.chimneys[this.chimneys.length - 1];
    } else {
      return this.chimneys[indexOfCurrent + 1];
    }
  }
  getPreviousChimney(current: Chimney): Chimney {
    const indexOfCurrent = this.chimneys.findIndex((it) => it === current);
    if (indexOfCurrent === -1) {
      throw new IllegalStateError("Could not find Chimney: " + current.id);
    }

    if (indexOfCurrent === 0) {
      return this.chimneys[0];
    } else {
      return this.chimneys[indexOfCurrent - 1];
    }
  }

  getFirstFlue(): Flue {
    return this.getFirstChimney().getFirstFlue();
  }
}
