import {
  CadastralNumbersDto,
  StreetAddress,
  StreetAddressDto,
} from "@airmont/firefly/shared/ts/domain";
import { AddressUser, AddressUserDto } from "./AddressUser";

export type AddressId = string;

export interface AddressDto {
  id: AddressId;
  municipalityId: string;
  postalCode: string;
  streetAddress: StreetAddressDto;
  cadastralNumbers: CadastralNumbersDto;
  users?: Array<AddressUserDto>;
}

export class Address {
  readonly id: AddressId;
  readonly municipalityId: string;
  readonly postalCode: string;
  readonly streetAddress: StreetAddress;
  readonly cadastralNumbers: CadastralNumbersDto;
  readonly users: Array<AddressUser> | undefined;

  constructor(dto: AddressDto) {
    this.id = dto.id;
    this.municipalityId = dto.municipalityId;
    this.postalCode = dto.postalCode;
    this.streetAddress = new StreetAddress(dto.streetAddress);
    this.cadastralNumbers = dto.cadastralNumbers;
    this.users = dto.users?.map((dto) => new AddressUser(dto));
  }
}
