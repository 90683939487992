import { CustomerEnvironment } from "@airmont/firefly/shared/ts/customer-environment";
import { Building, BuildingDto, BuildingImpl } from "./Building";
import { EnvironmentAwareBuilding } from "./EnvironmentAwareBuilding";

export interface EnvironmentBuildingsDto {
  environment: CustomerEnvironment;
  buildings: Array<BuildingDto>;
}

export class EnvironmentBuildings {
  readonly environment: CustomerEnvironment;
  readonly buildings: Array<Building>;

  constructor(args: {
    environment: CustomerEnvironment;
    buildings: Array<Building>;
  }) {
    this.environment = args.environment;
    this.buildings = args.buildings;
  }

  toEnvironmentAwareBuildings(): Array<EnvironmentAwareBuilding> {
    return this.buildings.map(
      (it) =>
        new EnvironmentAwareBuilding({
          environment: this.environment,
          address: it.address,
          chimneys: it.chimneys,
        })
    );
  }

  static fromDto(dto: EnvironmentBuildingsDto): EnvironmentBuildings {
    return new EnvironmentBuildings({
      environment: dto.environment,
      buildings: dto.buildings.map((it) => BuildingImpl.fromDto(it)),
    });
  }
}
