import { DateTime, DateTimeUnit } from "luxon";

export class TimeWheel {
  private readonly start: DateTime<true>;
  private readonly timeUnit: DateTimeUnit;

  constructor(args: { start: DateTime<true>; timeUnit: DateTimeUnit }) {
    this.start = args.start;
    this.timeUnit = args.timeUnit;
  }
  run<T>(count: number, fn: (dateTime: DateTime<true>) => T): Array<T> {
    let currCount = 0;
    let currDateTime = this.start;
    const result: Array<T> = [];

    do {
      result.push(fn(currDateTime));
      currDateTime = currDateTime.plus({ [this.timeUnit]: 1 });
      currCount++;
    } while (currCount <= count);

    return result;
  }

  runUntilTime<T>(
    untilTime: DateTime<true>,
    fn: (dateTime: DateTime) => T
  ): Array<T> {
    let currDateTime = this.start;
    const result: Array<T> = [];

    do {
      result.push(fn(currDateTime));
      currDateTime = currDateTime.plus({ [this.timeUnit]: 1 });
    } while (currDateTime < untilTime);

    return result;
  }
}
