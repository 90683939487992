import { FC, ReactNode } from "react";
import {
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";

export interface LanguageProviderProps {
  children: (language: string) => ReactNode;
}
export const LanguageUserSettingProvider: FC<LanguageProviderProps> = (
  props
) => {
  const [language] = useUserSettingWithDefault(
    "language",
    StringSetting,
    "nb-NO"
  );
  return props.children(language);
};
