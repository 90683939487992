import { FC, ReactNode, useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { Properties } from "posthog-js";

export interface PostHogIdentifierProps {
  enabled: boolean;
  user?: {
    id: string;
    name?: string;
    email?: string;
  };
  children: ReactNode;
}
export const PostHogIdentifier: FC<PostHogIdentifierProps> = (props) => {
  const { user, enabled } = props;
  const posthog = usePostHog();

  useEffect(() => {
    if (enabled) {
      if (user?.id != null) {
        // Identify sends an event, so you want may want to limit how often you call it
        const userProps: Properties = {};
        if (user.email != null) {
          userProps["email"] = user.email;
        }
        if (user.name != null) {
          userProps["name"] = user.name;
        }
        posthog.identify(user.id, userProps);
        //posthog?.group("company", user.company_id);
      }
    }
  }, [user?.id, user?.email, enabled, posthog]);

  return props.children;
};
