import React, { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import {
  Box,
  Card,
  CardContent,
  Container,
  Link,
  List,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import { ListItem } from "./ListItem";
import { TipsIcon } from "./TipsIcon";
import { EnvironmentIcon } from "./EnvironmentIcon";
import { SavingEnergyIcon } from "./SavingEnergyIcon";
import { ActionIcon } from "./ActionIcon";
import { WarningIcon } from "./WarningIcon";

export const GuidancePage: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  return (
    <AppPage
      $key={"GuidancePage"}
      name={t("Guidance")}
      icon={LocalLibraryRoundedIcon}
      mainPaneProps={{
        scrollableContentVertically: true,
      }}
      mainPane={
        <Container
          sx={{
            ul: {
              "padding-inline-start": theme.spacing(3),
            },
            ".MuiTypography-h2": {
              mt: 2,
              mb: 2,
            },
            ".MuiTypography-h3": {
              mt: 3,
              mb: 1,
            },
            ".MuiTypography-body1": {
              m: 0.5,
            },
            ".MuiAlert-root": {
              mt: 1,
              mb: 1,
              ml: 2,
              mr: 1,
            },
          }}
        >
          <Typography variant={"h2"} id={"ignition"}>
            Opptenning
          </Typography>
          <Typography>
            Les også bruksanvisningen til ildstedet om opptenning. Dette er
            generelle råd.
          </Typography>
          <Stack
            direction={"column"}
            useFlexGap
            gap={2}
            sx={{
              pt: 1,
              pl: 1,
              pr: 1,
              "&& .MuiTypography-h3": { mt: 0 },
              "&& ul": { paddingInlineStart: 1 },
              "& ul>li:last-child": { pb: 0 },
            }}
          >
            <Card id={"use-dry-wood"}>
              <CardContent>
                <Typography variant={"h3"}>Bruk tørr ved</Typography>
                <ul>
                  <ListItem icon={<EnvironmentIcon />}>
                    Tørr ved tar lettere fyr og soter mindre, som er bra for
                    miljøet.
                  </ListItem>
                  <ListItem icon={<SavingEnergyIcon />}>
                    Tørr ved er også mer energi-effektivt, da noe av energien
                    fra veden vil gå med til å gjøre om fuktigheten til damp.
                  </ListItem>
                  <ListItem icon={<TipsIcon />}>
                    Ved med mindre enn 20 % fuktighet er tørr nok. Du kan teste
                    vedskiene ved å slå dem imot hverandre; tørr ved lager en
                    tydelig klang, mens våt ved gir en dump lyd.
                  </ListItem>
                </ul>
              </CardContent>
            </Card>
            <Card id={"ensure-good-access-to-air"}>
              <CardContent>
                <Typography variant={"h3"}>
                  Sørg for god tilgang til luft
                </Typography>
                <ul>
                  <ListItem icon={<EnvironmentIcon />}>
                    God trekk sørger for at veden tar letter fyr og soter
                    mindre, som er bra for miljøet.
                  </ListItem>
                  <ListItem icon={<ActionIcon />}>
                    Åpne spjeldet til ildstedet og sørg for at ildstedets
                    ventiler er tilstrekkelig åpne.
                  </ListItem>
                  <ListItem icon={<TipsIcon />}>
                    Hvis ildstedet ikke er tilknyttet uteluft, kan det være
                    nødvendig å åpne et vindu i nærheten av ildstedet før
                    opptettning. La vinduet stå åpent i noen minutter til ilden
                    har tatt tak.
                  </ListItem>
                  <ListItem icon={<WarningIcon />}>
                    Unngå å ha på kjøkkenvifta på når du tenner opp, da dette
                    vil motarbeide den ønskede trekken i røykløpet.
                  </ListItem>
                </ul>
              </CardContent>
            </Card>
            <Card id={"build-the-fire-correctly"}>
              <CardContent>
                <Typography variant={"h3"}>Bygg opp bålet riktig</Typography>
                <ul>
                  <ListItem icon={<ActionIcon />}>
                    Legg den største veden nederst, og den minste øverst. Sørg
                    for god luft mellom veden. På toppen legger du
                    opptenningsveden.
                  </ListItem>
                  <ListItem icon={<TipsIcon />}>
                    Stikk gjerne inn noen tennbrikketter sammen med
                    opptenningsveden, så får du lettere fyr.
                  </ListItem>
                  <ListItem icon={<WarningIcon />}>
                    Ikke bruk papir til opptenning, det lager unødvendig mye
                    aske og lager mer sot, som er skadelig for miljøet og øker
                    sjangsen for pipebrann.
                  </ListItem>
                  <ListItem icon={<WarningIcon />}>
                    Ikke fyll mer enn 25 prosent av brennkammeret med ved.
                    Legger du inn for mye kan du risikere at lufttilførselen
                    ikke blir tilstrekkelig om trekken i pipa er for lav.
                  </ListItem>
                </ul>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Typography variant={"h3"} id={"ignite-on-top-of-wood"}>
                  Tenn opp fra toppen
                </Typography>
                <ul>
                  <ListItem icon={<EnvironmentIcon />}>
                    Vedkubbene som ligger nederst vil etter hvert bli varmet opp
                    og avgi gasser som igjen blir påtent av varmen over. Dette
                    vil redusere utslippet av partikler, som er bra for miljøet.
                  </ListItem>
                  <ListItem icon={<TipsIcon />}>
                    Ha gjerne døren litt på gløtt i starten fram til ilden har
                    tatt tak.
                  </ListItem>
                </ul>
              </CardContent>
            </Card>
          </Stack>
        </Container>
      }
      supportingPaneProps={{
        label: "Innholdsfortegnelse",
      }}
      supportingPane={
        <Box
          sx={{
            a: {
              textDecoration: "none",
            },
            ul: {
              "padding-inline-start": theme.spacing(3),
            },
            "ul > li > a": {
              color: "text.primary",
            },
          }}
        >
          <ul>
            <Typography component={"li"}>
              <Link component={RouterLink} to={"#ignition"} reloadDocument>
                Opptenning
              </Link>
            </Typography>
            <ul>
              <Typography component={"li"}>
                <Link
                  component={RouterLink}
                  to={"#use-dry-wood"}
                  reloadDocument
                >
                  Bruk tørr ved
                </Link>
              </Typography>
              <Typography component={"li"}>
                <Link
                  component={RouterLink}
                  to={"#ensure-good-access-to-air"}
                  reloadDocument
                >
                  Sørg for god tilgang til luft
                </Link>
              </Typography>
              <Typography component={"li"}>
                <Link
                  component={RouterLink}
                  to={"#build-the-fire-correctly"}
                  reloadDocument
                >
                  Bygg opp bålet riktig
                </Link>
              </Typography>
              <Typography component={"li"}>
                <Link
                  component={RouterLink}
                  to={"#ignite-on-top-of-wood"}
                  reloadDocument
                >
                  Tenn opp fra toppen
                </Link>
              </Typography>
            </ul>
          </ul>
          <List subheader={"Tegnforklaring:"}>
            <ListItem icon={<EnvironmentIcon />}>Bra for miljøet</ListItem>
            <ListItem icon={<SavingEnergyIcon />}>Energi-effektivt</ListItem>
            <ListItem icon={<TipsIcon />}>Tips</ListItem>
            <ListItem icon={<WarningIcon />}>Advarsel</ListItem>
            <ListItem icon={<ActionIcon />}>Handling</ListItem>
          </List>
        </Box>
      }
    />
  );
};
