import React, { FC, ReactNode } from "react";
import { Box, IconButton, Switch, Tooltip } from "@mui/material";
import { PropertyValue } from "./PropertyValue";
import { PropertyWrapper } from "./PropertyWrapper";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { TextField_size } from "@airmont/shared/ts/ui/mui";
import { SxProps } from "@mui/system";
import { _throw } from "@airmont/shared/ts/utils/core";

export interface PropertyEditBooleanProps {
  label: string;
  name: string;
  value: boolean;
  onChange: (value: PropertyValue, name: string) => void;
  size?: TextField_size;
  fullWidth?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  sx?: SxProps;
  info?: ReactNode;
}

export const PropertyEditBoolean: FC<PropertyEditBooleanProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    props.onChange(
      value,
      props.name ?? _throw(new Error("name cannot be null"))
    );
  };

  return (
    <PropertyWrapper
      label={props.label}
      size={props.size}
      fullWidth={props.fullWidth}
      sx={props.sx}
    >
      <Switch
        name={props.name}
        checked={props.value}
        onChange={handleChange}
        inputRef={props.inputRef}
        size={props.size}
      />
      {props.info && (
        <Box sx={{ marginLeft: "auto" }}>
          <Tooltip title={props.info}>
            <IconButton size={"small"}>
              <HelpOutlineRoundedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </PropertyWrapper>
  );
};
