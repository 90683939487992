import React, { FC } from "react";
import { Box, SxProps } from "@mui/material";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

const logoUrlStandard =
  "https://airmontstorage.blob.core.windows.net/public-images/airmont_logo.svg";
const logoUrlSmall =
  "https://airmontstorage.blob.core.windows.net/public-images/aM.svg";

export interface AirmontLogoImgProps {
  size?: "standard" | "small";
  title?: string;
  sx?: SxProps;
}
export const AirmontLogoImg: FC<AirmontLogoImgProps> = (props) => {
  const size = props.size ?? "standard";
  const logoUrl = size === "standard" ? logoUrlStandard : logoUrlSmall;
  //const { t } = useTranslation("firefly-my-chimney-ts-pages");

  const sx = useSxMerge(props.sx, {
    width: "auto",
    maxHeight: "62px",
  });

  return (
    <Box
      component={"img"}
      src={logoUrl}
      alt={props.title}
      title={props.title}
      sx={sx}
    />
  );
};
