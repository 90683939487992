import React, { FC } from "react";
import { Box, Divider } from "@mui/material";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import {
  Fireplace,
  FireplaceUpdateSpec,
} from "@airmont/firefly/my-chimney/ts/building";
import { FireplaceCatalyticProperty } from "@airmont/firefly/shared/ts/domain";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { FireplaceForm } from "./FireplaceForm";
import { useTranslation } from "react-i18next";

export interface FireplaceFormValues {
  name: string;
}
export interface FireplacePanelProps {
  environment: CustomerEnvironmentId;
  fireplace: Fireplace;
  onChange: (update: FireplaceUpdateSpec) => void;
}
export const FireplacePanel: FC<FireplacePanelProps> = (props) => {
  const { fireplace, environment } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");

  return (
    <Box>
      <PropertiesCard
        header={{
          title: `${t("Fireplace")}${
            fireplace.mcFields?.name != null
              ? `: ${fireplace.mcFields?.name ?? ""}`
              : ""
          }`,
          subtitle:
            "Brannvesenet har registert følgende informasjon om ildstedet",
        }}
        elevation={0}
      >
        <FireplaceCatalyticProperty
          value={fireplace.fields.catalytic}
          propertyMode={"read"}
        />
      </PropertiesCard>
      {fireplace.mcFields != null && (
        <>
          <Divider />
          <FireplaceForm
            fireplace={fireplace}
            environment={environment}
            onChange={props.onChange}
          />
        </>
      )}
    </Box>
  );
};
