import { AddressId, FlueId } from "@airmont/firefly/shared/ts/domain";
import React, { FC, ReactNode, useCallback, useContext, useMemo } from "react";
import {
  _throw,
  IllegalArgumentError,
  IllegalStateError,
} from "@airmont/shared/ts/utils/core";
import { EnvironmentAwareBuilding } from "@airmont/firefly/my-chimney/ts/building";
import {
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import {
  Chimney,
  ChimneyUpdateSpec,
  FireplaceUpdateSpec,
  Flue,
  FlueUpdateSpec,
} from "@airmont/firefly/my-chimney/ts/building";

export interface MyChimneyContextType {
  buildings: Array<EnvironmentAwareBuilding>;
  selectedBuilding: EnvironmentAwareBuilding;
  selectedChimney: Chimney;
  selectedFlue: Flue;
  setSelectedBuilding: (value: AddressId) => void;
  gotoNextChimney: () => void;
  gotoPreviousChimney: () => void;
  setSelectedFlue: (value: FlueId) => void;
  onChimneyUpdate: (update: ChimneyUpdateSpec) => void;
  onFlueUpdate: (update: FlueUpdateSpec) => void;
  onFireplaceUpdate: (update: FireplaceUpdateSpec) => void;
}

const MyChimneyContext = React.createContext({} as MyChimneyContextType);

export interface MyChimneyContextProviderProps {
  buildings: Array<EnvironmentAwareBuilding>;
  onBuildingsChange: (change: Array<EnvironmentAwareBuilding>) => void;
  selectedBuilding: AddressId;
  selectedFlue: FlueId;
  children: ReactNode;
}

export const MyChimneyContextProvider: FC<MyChimneyContextProviderProps> = (
  props
) => {
  const { buildings, onBuildingsChange } = props;
  if (buildings.length === 0) {
    throw new IllegalArgumentError(
      "MyChimneyContextProvider require at least one building"
    );
  }
  const [selectedBuildingId, setSelectedBuildingId] =
    useUserSettingWithDefault<AddressId>(
      "building",
      StringSetting,
      props.selectedBuilding
    );
  const selectedBuilding: EnvironmentAwareBuilding = useMemo(() => {
    const environmentAwareBuilding = buildings.find(
      (it) => it.id === selectedBuildingId
    );
    if (environmentAwareBuilding === undefined) {
      return buildings[0];
    }
    return environmentAwareBuilding;
  }, [selectedBuildingId, buildings]);

  const [selectedFlueId, setSelectedFlueId] = useUserSettingWithDefault<FlueId>(
    `${selectedBuilding.id}.flue`,
    StringSetting,
    props.selectedFlue
  );

  const selectedFlue: Flue = useMemo(
    () =>
      selectedBuilding.findFlueById(selectedFlueId) ??
      selectedBuilding.getFirstFlue(),
    [selectedFlueId, selectedBuilding]
  );

  const selectedChimney: Chimney = useMemo(
    () =>
      selectedFlue.chimney ??
      _throw(
        new IllegalStateError("Flue has no Chimney set: " + selectedFlue.id)
      ),
    [selectedFlue]
  );

  const handleGotoNextChimney = () => {
    const next = selectedBuilding
      .findFlueById(selectedFlueId)
      ?.chimney?.getNext();
    if (next) {
      setSelectedFlueId(next.getFirstFlue().id);
    }
  };

  const handleGotoPreviousChimney = () => {
    const previous = selectedBuilding
      .findFlueById(selectedFlueId)
      ?.chimney?.getPrevious();
    if (previous) {
      setSelectedFlueId(previous.getFirstFlue().id);
    }
  };

  const handleSelectedFlue = useCallback(
    (flueId: FlueId) => {
      setSelectedFlueId(flueId);
    },
    [setSelectedFlueId]
  );

  const handleChimneyUpdate = useCallback(
    (updateSpec: ChimneyUpdateSpec) => {
      const changedBuildings = buildings.map((building) => {
        if (building.hasChimney(updateSpec.id)) {
          return building.updateChimney(updateSpec);
        } else {
          return building;
        }
      });
      onBuildingsChange(changedBuildings);
    },
    [buildings, onBuildingsChange]
  );

  const handleFlueUpdate = useCallback(
    (updateSpec: FlueUpdateSpec) => {
      const changedBuildings = buildings.map((building) => {
        if (building.hasChimney(updateSpec.chimneyId)) {
          return building.updateFlue(updateSpec);
        } else {
          return building;
        }
      });
      onBuildingsChange(changedBuildings);
    },
    [buildings, onBuildingsChange]
  );

  const handleFireplaceUpdate = useCallback(
    (updateSpec: FireplaceUpdateSpec) => {
      const changedBuildings = buildings.map((building) => {
        if (building.hasChimney(updateSpec.chimneyId)) {
          return building.updateFireplace(updateSpec);
        } else {
          return building;
        }
      });
      onBuildingsChange(changedBuildings);
    },
    [buildings, onBuildingsChange]
  );

  const value: MyChimneyContextType = useMemo(() => {
    return {
      buildings: buildings,
      selectedBuilding: selectedBuilding,
      selectedChimney: selectedChimney,
      selectedFlue: selectedFlue,
      setSelectedBuilding: setSelectedBuildingId,
      gotoNextChimney: handleGotoNextChimney,
      gotoPreviousChimney: handleGotoPreviousChimney,
      setSelectedFlue: handleSelectedFlue,
      onChimneyUpdate: handleChimneyUpdate,
      onFlueUpdate: handleFlueUpdate,
      onFireplaceUpdate: handleFireplaceUpdate,
    };
  }, [
    selectedBuilding,
    selectedChimney,
    selectedFlue,
    buildings,
    setSelectedBuildingId,
    handleSelectedFlue,
    handleChimneyUpdate,
    handleFlueUpdate,
    handleFireplaceUpdate,
  ]);

  return (
    <MyChimneyContext.Provider value={value}>
      {props.children}
    </MyChimneyContext.Provider>
  );
};

export const useMyChimneyContext = (): MyChimneyContextType => {
  const context = useContext(MyChimneyContext);
  if (context == null) {
    throw new Error(
      "useMyChimneyContext must be used within a MyChimneyContextProvider"
    );
  }
  return context;
};
