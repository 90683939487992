import {
  ActionListItem,
  isAction,
  isActionGroup,
  isLinkItem,
  isRouteItem,
  Item,
  ItemVariant,
  LinkListItem,
  resolveActionGroupPreferences,
  RouteListItem,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import React, { ReactNode } from "react";
import { GroupMenu } from "../menu/GroupMenu";
import { GroupList } from "./GroupList";
import { Layout } from "../../../Layout";
import { LayoutDirection } from "../../../LayoutDirection";

export interface RenderItemParams {
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  item: Item;
  index?: number;
  hideLabels: boolean;
  onSelected: (item: SelectableItem) => void;
}

export const renderItem = (params: RenderItemParams): ReactNode => {
  const { variant, layout, layoutDirection, item, onSelected } = params;

  const index = params.index ?? 0;

  if (isRouteItem(item)) {
    const hideLabels = params.hideLabels && item.icon != null;
    return (
      <RouteListItem
        key={index}
        item={item}
        variant={variant}
        layout={layout}
        onSelected={onSelected}
        hideLabel={hideLabels}
      />
    );
  } else if (isLinkItem(item)) {
    const hideLabels = params.hideLabels && item.icon != null;
    return (
      <LinkListItem
        key={index}
        item={item}
        hideLabel={hideLabels}
        variant={variant}
        layout={layout}
        layoutDirection={layoutDirection}
      />
    );
  } else if (isAction(item)) {
    const hideLabels = params.hideLabels && item.icon != null;
    return (
      <ActionListItem
        key={index}
        item={item}
        variant={variant}
        layout={layout}
        hideLabel={hideLabels}
      />
    );
  } else if (isActionGroup(item)) {
    const preferences = resolveActionGroupPreferences(item.preferences, layout);
    const hideLabels =
      (params.hideLabels || preferences?.hideLabels === true) &&
      item.icon != null;
    const displayInline =
      preferences?.displayInline != null
        ? preferences.displayInline
        : item.items.length < 4;

    if (displayInline) {
      return (
        <GroupList
          variant={variant}
          layout={layout}
          layoutDirection={layoutDirection}
          key={index}
          item={item}
          onSelected={onSelected}
          hideLabels={true}
        />
      );
    } else {
      return (
        <GroupMenu
          layout={layout}
          layoutDirection={layoutDirection}
          key={index}
          item={item}
          hideLabel={hideLabels}
          onSelected={onSelected}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        />
      );
    }
  } else {
    throw new Error("Unsupported type: \n" + item.type);
  }
};
