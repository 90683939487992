import { NavigationItemObject } from "./shared/NavigationItemObject";
import {
  isActionGroup,
  isRouteItem,
  isSelectableAction,
  Item,
  RouteItem,
  SelectableAction,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

const noDeps: DependencyList = [];

const updateSelectedRoute = (
  items: Array<NavigationItemObject>,
  selected: RouteItem | undefined
): Array<NavigationItemObject> => {
  return items.map((it) => {
    if (it === undefined) {
      return it;
    } else if (isRouteItem(it)) {
      if (selected !== undefined) {
        if (selected === it || selected.route === it.route) {
          return { ...it, selected: true };
        } else {
          return it.selected ? { ...it, selected: false } : it;
        }
      } else {
        return it.selected ? { ...it, selected: false } : it;
      }
    } else if (isActionGroup(it)) {
      return {
        ...it,
        items: updateSelectedRoute(it.items, selected),
      } as NavigationItemObject;
    } else {
      return it;
    }
  });
};

const updateSelectedAction = (
  items: Array<NavigationItemObject>,
  selected: SelectableAction | undefined
): Array<NavigationItemObject> => {
  return items.map((it) => {
    if (it === undefined) {
      return it;
    } else if (isSelectableAction(it)) {
      if (selected !== undefined) {
        if (selected === it || selected.name === it.name) {
          return { ...it, selected: true };
        } else {
          return it.selected ? { ...it, selected: false } : it;
        }
      } else {
        return it.selected ? { ...it, selected: false } : it;
      }
    } else if (isActionGroup(it)) {
      return {
        ...it,
        items: updateSelectedAction(it.items, selected),
      } as NavigationItemObject;
    } else {
      return it;
    }
  });
};

const resolveSelectedRouteItem = (
  items: Array<NavigationItemObject>,
  path: string
): RouteItem | undefined => {
  for (const item of items) {
    if (item == null) {
      continue;
    }
    if (isRouteItem(item) && item.route === path) {
      return item;
    } else if (isActionGroup(item)) {
      const selectedItem = resolveSelectedRouteItem(item.items, path);
      if (selectedItem != null) {
        return selectedItem;
      }
    }
  }
  return undefined;
};

export const useNavigation = (
  initialItems: Array<NavigationItemObject>,
  deps: DependencyList
): {
  navigationItems: Array<NavigationItemObject>;
  handleSelected: (item: SelectableItem) => void;
} => {
  const resolvedDeps = deps ?? noDeps;
  const navigate = useNavigate();
  const location = useLocation();
  const [navigationItems, setNavigationItems] =
    useState<Array<NavigationItemObject>>(initialItems);
  const [selectedRouteItem, setSelectedRouteItem] = useState<
    RouteItem | undefined
  >(resolveSelectedRouteItem(navigationItems, location.pathname));

  useEffect(() => {
    setNavigationItems(updateSelectedRoute(initialItems, selectedRouteItem));
  }, [...resolvedDeps]);

  useEffect(() => {
    setNavigationItems(updateSelectedRoute(navigationItems, selectedRouteItem));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRouteItem]);

  const handleSelect = useCallback(
    (newSelectedItem: SelectableItem) => {
      if (isRouteItem(newSelectedItem)) {
        setSelectedRouteItem(newSelectedItem);
        navigate(newSelectedItem.route);
      } else if (isSelectableAction(newSelectedItem)) {
        setNavigationItems(
          updateSelectedAction(navigationItems, newSelectedItem)
        );
      }
    },
    [navigationItems, navigate]
  );

  return useMemo(
    () => ({
      navigationItems: navigationItems,
      handleSelected: handleSelect,
    }),
    [navigationItems, handleSelect]
  );
};
