import React, { FC, ReactNode } from "react";
import { ListItem as MuiListItem, ListItemIcon } from "@mui/material";

export interface ListItemProps {
  icon?: ReactNode;
  children: ReactNode;
}
export const ListItem: FC<ListItemProps> = (props) => {
  return (
    <MuiListItem
      component={"li"}
      sx={{ ".MuiListItemIcon-root": { alignSelf: "start", mt: 0.5 } }}
    >
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      {props.children}
    </MuiListItem>
  );
};
