import {
  ActionListItem,
  isAction,
  isActionGroup,
  isDivider,
  isRouteItem,
  isSpacer,
  ItemVariant,
  RouteListItem,
  resolveActionGroupPreferences,
} from "@airmont/shared/ts/ui/action";
import { Box, Divider, List, Paper, SxProps } from "@mui/material";
import { GroupList } from "../shared/group/list/GroupList";
import { GroupMenu } from "../shared/group/menu/GroupMenu";
import React from "react";
import { NavigationComponentProps } from "../NavigationComponentProps";
import { isComponent } from "../shared/Component";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";
import { Layout } from "../Layout";

const itemVariant: ItemVariant = "rounded";
const layout: Layout = "large";

export const NavigationDrawer = (props: NavigationComponentProps) => {
  const variant = props.variant ?? "elevation";
  const paperVariant = variant === "flat" ? "outlined" : variant;

  const items = props.items;

  const sx: SxProps = useSxMerge(props.sx, {
    minWidth: "360px",
    maxWidth: "360px",
    overflowY: "auto",
    "&.flat": {
      border: "unset",
      backgroundColor: "unset",
      boxShadow: "unset",
    },
  });

  return (
    <Paper
      sx={sx}
      variant={paperVariant}
      style={props.style}
      className={
        `NavigationDrawer ${variant}` +
        (props.className !== null ? ` ${props.className}` : "")
      }
      classes={props.classes}
    >
      <List
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          ">.Spacer": {
            marginTop: "-12px",
            flex: 1,
          },
        }}
      >
        {items.map((item, index) => {
          if (item === undefined) {
            return undefined;
          } else if (React.isValidElement(item)) {
            return React.cloneElement(item, { key: index });
          } else if (isComponent(item)) {
            return React.cloneElement(item.render("drawer"), { key: index });
          } else {
            if (isAction(item)) {
              return (
                <ActionListItem
                  key={index}
                  item={item}
                  variant={itemVariant}
                  layout={layout}
                />
              );
            } else if (isRouteItem(item)) {
              return (
                <RouteListItem
                  key={index}
                  item={item}
                  variant={itemVariant}
                  layout={layout}
                  onSelected={props.onSelected}
                />
              );
            } else if (isActionGroup(item)) {
              const preferences = resolveActionGroupPreferences(
                item.preferences,
                layout
              );
              const displayInline =
                preferences?.displayInline != null
                  ? preferences.displayInline
                  : item.items.length < 4;

              if (displayInline) {
                return (
                  <GroupList
                    variant={itemVariant}
                    layout={layout}
                    key={index}
                    item={item}
                    onSelected={props.onSelected}
                    hideLabels={false}
                  />
                );
              } else {
                return (
                  <GroupMenu
                    variant={itemVariant}
                    layout={layout}
                    key={index}
                    item={item}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    onSelected={props.onSelected}
                  />
                );
              }
            } else if (isDivider(item)) {
              return <Divider key={index} />;
            } else if (isSpacer(item)) {
              return <Box key={index} className={"Spacer"} />;
            } else {
              throw new Error("Unsupported item type: \n" + item.type);
            }
          }
        })}
      </List>
    </Paper>
  );
};
