import { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppInfo } from "@airmont/shared/ts/ui/app-info";
import { AboutPublicPreview } from "./AboutPublicPreview";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { PrivacyPolicyContent } from "../privacyPolicy/PrivacyPolicyContent";
import { SizeClass, useWindowWidth } from "@airmont/shared/ts/ui/responsive";

export const AboutPage: FC = () => {
  const width = useWindowWidth();
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const [publicPreviewInfoIsOpen, setPublicPreviewInfoIsOpen] =
    useUserSettingWithDefault(
      "AboutPage.publicPreviewInfoIsOpen",
      BooleanSetting,
      false
    );
  const [privacyPolicyIsOpen, setPrivacyPolicyIsOpen] =
    useUserSettingWithDefault(
      "AboutPage.privacyPolicyIsOpen",
      BooleanSetting,
      false,
      {
        storeLocally: true,
      }
    );
  const appInfo = useAppInfo();
  const handlePublicPreviewAccordionChange = () => {
    setPublicPreviewInfoIsOpen(!publicPreviewInfoIsOpen);
  };

  const handlePrivacyPolicyAccordionChange = () => {
    setPrivacyPolicyIsOpen(!privacyPolicyIsOpen);
  };

  return (
    <AppPage
      $key={"AboutPage"}
      name={`${t("About")} ${t("My Chimney")}`}
      mainPaneProps={{
        scrollableContentVertically: true,
      }}
      mainPane={
        <Container
          maxWidth={
            width === SizeClass.Compact
              ? "xs"
              : width === SizeClass.Medium
              ? "sm"
              : "md"
          }
        >
          <Card>
            <CardContent>
              <Stack direction={"column"} useFlexGap gap={1}>
                <Typography variant={"h6"}>
                  {t("My Chimney")}
                  {"  ("}
                  {t("Public Preview")}
                  {")"}
                </Typography>
                <Typography>
                  {t("Release")}: {appInfo.release}
                </Typography>
                <Typography>
                  {t("Build Version")}: {appInfo.buildVersion}
                </Typography>
                <Typography>
                  {t("Made in Halden with Love")}
                  {" ❤️"}
                </Typography>
                <Typography>Copyright © 2024 airMont AS️</Typography>
                <Accordion
                  expanded={publicPreviewInfoIsOpen}
                  onChange={handlePublicPreviewAccordionChange}
                  disableGutters
                  variant={"elevation"}
                  elevation={3}
                >
                  <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                    {t("About")} {t("Public Preview")}
                  </AccordionSummary>
                  <AccordionDetails>
                    <AboutPublicPreview />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={privacyPolicyIsOpen}
                  onChange={handlePrivacyPolicyAccordionChange}
                  disableGutters
                  variant={"elevation"}
                  elevation={3}
                >
                  <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                    {t("Privacy Policy")}
                  </AccordionSummary>
                  <AccordionDetails>
                    <PrivacyPolicyContent />
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </CardContent>
          </Card>
        </Container>
      }
    />
  );
};
