import React, { FC, ReactNode } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { DotsProgress } from "@airmont/shared/ts/ui/progress";
import { BootError } from "./BootError";
import { useThisTranslation } from "./i18n";
import {
  SizeClass,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";

export interface BootPageProps {
  appIcon?: ReactNode;
  booting?: string;
  appName: ReactNode;
  messages: Array<string>;
  error?: Error;
}

export const BootPage: FC<BootPageProps> = (props) => {
  const { t } = useThisTranslation();
  const widthComparer = useWindowWidthComparer();

  return (
    <Container
      maxWidth={
        widthComparer.gte(SizeClass.Expanded)
          ? "md"
          : widthComparer.gte(SizeClass.Medium)
          ? "md"
          : "xs"
      }
      component={"main"}
      sx={{
        alignSelf: "center",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      <Stack direction={"column"} useFlexGap gap={1}>
        {props.appIcon != null && props.appIcon}
        {React.isValidElement(props.appName) && props.appName}
        {typeof props.appName === "string" && (
          <Typography
            align={"center"}
            variant={
              widthComparer.gte(SizeClass.Expanded)
                ? "h1"
                : widthComparer.gte(SizeClass.Medium)
                ? "h2"
                : "h3"
            }
          >
            {props.appName}
          </Typography>
        )}
        <Box
          sx={{
            pt: 1,
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              pl: widthComparer.gte(SizeClass.Expanded)
                ? 30
                : widthComparer.gte(SizeClass.Medium)
                ? 20
                : 5,
            }}
          >
            <Typography>
              {props.booting != null ? props.booting : t("Booting")}
              {props.error && ` ${t("failed")}`}:
            </Typography>
            <Box sx={{ pl: 1 }}>
              {props.messages.length > 0 && (
                <Typography>
                  {props.messages[props.messages.length - 1]}
                  {!props.error && <DotsProgress length={5} />}
                </Typography>
              )}
              {props.error && <BootError error={props.error} />}
            </Box>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};
