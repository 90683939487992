import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { MetricColors } from "./MetricColors";

export const useMetricColors = (): MetricColors => {
  const theme = useTheme();
  return useMemo(() => {
    if (theme.palette.mode === "light") {
      return {
        textColor: theme.palette.text.primary,
        backgroundColor: "#F8F4E5",
      };
    } else {
      return {
        textColor: theme.palette.getContrastText("#E6E4CC"),
        backgroundColor: "#E6E4CC",
      };
    }
  }, [theme]);
};
