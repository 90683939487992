import { IllegalStateError } from "@airmont/shared/ts/utils/core";
import { Flue } from "./flue/Flue";

export class Flues {
  readonly flues: Array<Flue>;
  constructor(flues: Array<Flue>) {
    this.flues = flues;
  }

  getFirstFlue(): Flue {
    if (this.flues.length === 0) {
      throw new IllegalStateError("No flues to get");
    }
    return this.flues[0];
  }
}
