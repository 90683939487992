import { Breadcrumbs, Tooltip, Typography } from "@mui/material";
import React from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { SizeClass, useWindowSize } from "@airmont/shared/ts/ui/responsive";
import { MunicipalityDto } from "../../municipality/Municipality";
import { StreetAddressDto } from "../../address/StreetAddress";
import { PostalPlace } from "../../postalCode/PostalPlace";

export interface FlueBreadcrumbsProps {
  municipality: MunicipalityDto;
  streetAddress: StreetAddressDto;
  postalAddress: PostalPlace;
  flue: {
    name?: string;
  };
  hideMunicipality?: boolean;
  hidePostalAddress?: boolean;
  sx?: SxProps<Theme>;
}

export const FlueBreadcrumbs = (props: FlueBreadcrumbsProps) => {
  const { windowWidth } = useWindowSize();
  const hideMunicipality = props.hideMunicipality ?? windowWidth !== "Compact";
  const hidePostalAddress = props.hidePostalAddress ?? false;

  let streetAddress = "";
  if (props.streetAddress != null) {
    streetAddress = props.streetAddress.street ?? "";
    if (props.streetAddress.houseNumber != null) {
      streetAddress += " " + props.streetAddress.houseNumber;
    }
    if (props.streetAddress.houseLetter != null) {
      streetAddress += props.streetAddress.houseLetter;
    }
    if (props.streetAddress.houseSection != null) {
      streetAddress += " - " + props.streetAddress.houseSection;
    }
  }
  const postalAddress = `${props.postalAddress?.code ?? ""} ${
    props.postalAddress?.place ?? ""
  }`;
  const flue = props.flue?.name ?? null;
  let maxItems = 4;
  let itemsBeforeCollapse = 0;
  let itemsAfterCollapse = 2;
  if (windowWidth === SizeClass.Medium) {
    maxItems = 2;
    itemsBeforeCollapse = 0;
    itemsAfterCollapse = 2;
  } else if (windowWidth === SizeClass.Compact) {
    maxItems = 2;
    itemsBeforeCollapse = 0;
    itemsAfterCollapse = 2;
  }

  const sx: SxProps<Theme> | undefined = Object.assign(
    {},
    { ...props.sx },
    { flexWrap: "nowrap" }
  );

  return (
    <Breadcrumbs
      maxItems={maxItems}
      itemsBeforeCollapse={itemsBeforeCollapse}
      itemsAfterCollapse={itemsAfterCollapse}
      sx={sx}
    >
      {!hideMunicipality && props.municipality?.name && (
        <Typography>{props.municipality?.name}</Typography>
      )}
      {windowWidth !== "Compact" && !hidePostalAddress && (
        <Typography>{postalAddress}</Typography>
      )}
      <Typography>{streetAddress}</Typography>
      {flue != null && flue.length > 0 && (
        <Tooltip title={"Røykløp"} arrow>
          <Typography>{flue}</Typography>
        </Tooltip>
      )}
    </Breadcrumbs>
  );
};
