import { FlueMetrics } from "../metrics/FlueMetrics";
import { ArrayUtils, MathUtils } from "@airmont/shared/ts/utils/core";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { useSummarizeFlueMetricValues } from "./useSummarizeFlueMetricValues";

export const useResolveSootIndexCumulativeSeries = (
  thisYear: number,
  thisMonth: number | null,
  flueMetricsByMonth: Record<string, FlueMetrics>
): Array<number | null> => {
  const startOfYearMetrics = useSummarizeFlueMetricValues(
    DateTime.local(thisYear, 1) as DateTime<true>,
    flueMetricsByMonth
  );

  return useMemo(() => {
    let currSootIndex = startOfYearMetrics.sootIndex;
    return ArrayUtils.createAndFill(12, (index) => {
      const month = index + 1;
      if (thisMonth != null && month > thisMonth) {
        return null;
      }
      const flueMetrics =
        thisMonth != null && month > thisMonth
          ? flueMetricsByMonth[`${thisYear - 1}-${month}`]
          : flueMetricsByMonth[`${thisYear}-${month}`];

      if (flueMetrics != null) {
        currSootIndex += flueMetrics.metrics.sootIndex;
      }
      return MathUtils.round(currSootIndex);
    });
  }, [thisYear, thisMonth, flueMetricsByMonth, startOfYearMetrics]);
};
