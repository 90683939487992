import { Card, CardContent, CardHeader } from "@mui/material";
import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactNode,
  useMemo,
} from "react";
import { SxProps } from "@mui/system";
import { merge } from "lodash";
import { PropertyProps } from "./Property";
import { isFragment } from "react-is";
import { OverridableStringUnion } from "@mui/types";
import { PaperPropsVariantOverrides } from "@mui/material/Paper/Paper";
import * as React from "react";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export type ChildPropertyProps = Partial<
  Omit<PropertyProps, "label" | "name" | "value" | "onChange" | "type" | "info">
>;

export type Variant = OverridableStringUnion<
  "elevation" | "outlined",
  PaperPropsVariantOverrides
>;

export interface PropertiesCardProps {
  elevation?: number;
  variant?: Variant;
  children: ReactNode;
  childPropertyProps?: ChildPropertyProps;
  direction?: "row" | "column";
  header?: {
    title?: ReactNode;
    subtitle?: ReactNode;
    action?: React.ReactNode;
  };
  sx?: SxProps;
  MuiFormControlProps?: {
    maxWidth?: string | number;
    minWidth?: string | number;
  };
  slotProps?: {
    CardContent?: {
      sx?: SxProps;
    };
  };
}

export const PropertiesCard: FC<PropertiesCardProps> = (props) => {
  const direction = props.direction ?? "column";
  const variant = props.variant ?? "elevation";
  const elevation = props.elevation ?? 1;
  const rootSx = useMemo(() => {
    return merge(
      {},
      props.sx,
      props.MuiFormControlProps != null
        ? { ".MuiFormControl-root": { ...props.MuiFormControlProps } }
        : undefined,
      props.elevation === 0 ? { backgroundColor: "inherit" } : undefined
    );
  }, [props.sx, props.MuiFormControlProps, props.elevation]);

  const minimizePadding = variant === "elevation" && elevation === 0;

  const cardContentSx = useSxMerge(props.slotProps?.CardContent?.sx, {
    display: "flex",
    flexFlow: direction === "row" ? direction + " wrap" : direction,
    gap: 2,
    pt: minimizePadding ? 1 : undefined,
    pl: minimizePadding ? 0.5 : undefined,
    pr: minimizePadding ? 0.5 : undefined,
    pb: minimizePadding ? 0.5 : undefined,
  });

  const children = unwrapChildren(props.children);

  return (
    <Card
      className={"PropertiesCard"}
      raised={true}
      sx={rootSx}
      elevation={props.elevation}
      variant={props.variant}
    >
      {props.header != null && (
        <CardHeader
          title={props.header?.title}
          action={props.header.action}
          titleTypographyProps={{
            sx: {
              color: "text.secondary",
              fontSize: "18px",
            },
          }}
          subheader={props.header?.subtitle}
        />
      )}
      <CardContent sx={cardContentSx}>
        {Children.map(children, (child, index) => {
          if (isValidElement(child)) {
            return cloneElement(child, props.childPropertyProps);
          } else {
            return child;
          }
        })}
      </CardContent>
    </Card>
  );
};

type LimitedReactNode = Exclude<ReactNode, boolean | null | undefined>;
const unwrapChildren = (
  nodes: ReactNode | ReactNode[]
): Array<LimitedReactNode> => {
  const children = Children.toArray(nodes);
  if (children.length === 1 && isFragment(children[0])) {
    const fragment = children[0] as { props: { children: ReactNode } };
    return Children.toArray(fragment.props.children);
  } else {
    return children;
  }
};
