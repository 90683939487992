import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  useTheme,
} from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import React, { ReactNode, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export interface NavigationItemProps extends CommonProps {
  layout: Layout;
  textPrimary?: string;
  textOrientation?: "horizontal" | "vertical";
  selected?: boolean;
  icon?: ReactNode;
  endAdornment?: ReactNode;
  route?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  sx?: SxProps;
}

export const NavigationItem = (props: NavigationItemProps) => {
  const { layout, textPrimary, icon, endAdornment, route, onClick } = props;
  const textOrientation = props.textOrientation ?? "horizontal";
  const selected = props.selected ?? false;

  const theme = useTheme();
  const navigate = useNavigate();
  const classNameRoot = useMemo(() => {
    let className = "Item NavigationItem layout-" + layout;
    if (selected) {
      className += " selected";
    }
    if (props.className) {
      className += " " + props.className;
    }
    return className;
  }, [selected, layout, props.className]);

  const classNameButton = useMemo(() => {
    let className = "NavigationItem-button layout-" + layout;
    if (selected) {
      className += " selected";
    }
    return className;
  }, [selected, layout]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    if (route != null) {
      navigate(route);
    }
  };

  const sx = useSxMerge(props.sx, {
    "&.layout-large": {
      minHeight: "56px",
      maxHeight: "56px",
      alignItems: "stretch",
    },
    "&.layout-medium": {
      pl: "2px",
      pr: "2px",
    },
  });
  return (
    <ListItem className={classNameRoot} disableGutters disablePadding sx={sx}>
      <ListItemButton
        className={classNameButton}
        onClick={handleClick}
        disableRipple={onClick == null}
        disableTouchRipple={onClick == null}
        sx={{
          borderRadius: "inherit",
          justifyContent:
            textPrimary === undefined || textOrientation === "vertical"
              ? "center"
              : undefined,
          flexDirection: textOrientation === "horizontal" ? "row" : "column",
          ...(onClick == null && {
            "&:hover": {
              cursor: "auto",
              backgroundColor: "unset",
            },
          }),
          ".MuiListItemText-root": {
            ".MuiTypography-root": {
              fontSize: "inherit",
              textAlign: "inherit",
            },
          },
          "&.layout-large": {
            pt: 0,
            pb: 0,
          },
          "&.layout-medium": {
            flexGrow: 1,
            minWidth: 0,
            flexDirection: "column",
            ">.MuiListItemIcon-root": {
              justifyContent: "center",
            },
            ">.MuiListItemText-root": {
              fontSize: "0.75rem",
              textAlign: "center",
            },
            "&.selected": {
              ".MuiListItemIcon-root": {
                borderRadius: "15px",
                backgroundColor: theme.palette.action.selected,
              },
            },
          },
        }}
      >
        {icon != null && (
          <ListItemIcon
            sx={{
              color: selected ? theme.palette.secondary.main : undefined,
              minWidth:
                props.textPrimary === undefined ||
                textOrientation === "vertical"
                  ? "unset"
                  : undefined,
            }}
          >
            {props.icon}
          </ListItemIcon>
        )}
        {textPrimary != null && (
          <ListItemText
            primary={textPrimary}
            primaryTypographyProps={{
              sx: {
                color: selected ? theme.palette.secondary.main : undefined,
              },
            }}
          />
        )}
        {endAdornment != null && endAdornment}
      </ListItemButton>
    </ListItem>
  );
};
