import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { alpha, Tooltip, useTheme } from "@mui/material";
import React, { FC } from "react";

export const EnvironmentIcon: FC = () => {
  const theme = useTheme();
  return (
    <Tooltip title={"Bra for miljøet"}>
      <FavoriteRoundedIcon
        sx={{ color: alpha(theme.palette.success.main, 0.7) }}
      />
    </Tooltip>
  );
};
