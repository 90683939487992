import React, { FC, ReactNode } from "react";
import { PropertyValue } from "./PropertyValue";
import { TextField_size } from "@airmont/shared/ts/ui/mui";
import { SxProps } from "@mui/system";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { _throw } from "@airmont/shared/ts/utils/core";
import { useMergeSx } from "./useMergeSx";

export interface PropertyEditDateProps {
  label: string;
  name: string;
  value: DateTimeISO;
  disableFuture?: boolean;
  onChange: (value: PropertyValue, name: string) => void;
  size?: TextField_size;
  fullWidth?: boolean;
  sx?: SxProps;
  inputRef?: React.Ref<HTMLInputElement>;
  info?: ReactNode;
}

export const PropertyEditDate: FC<PropertyEditDateProps> = (props) => {
  const sx = useMergeSx(
    {
      width: props.fullWidth ? "100%" : "25ch",
    },
    props.sx,
    [props.sx, props.fullWidth]
  );

  if (props.value != null && typeof props.value !== "string") {
    throw new Error("value must be of type string: " + props.value);
  }
  const value =
    props.value != null
      ? DateTime.fromISO(props.value).setLocale("no")
      : undefined;

  const handleChange = (value: DateTime | null) => {
    props.onChange(
      value != null ? value.toISO() : null,
      props.name ?? _throw(new Error("name cannot be null"))
    );
  };

  return (
    <DatePicker
      label={props.label}
      value={value}
      disableFuture={props.disableFuture}
      sx={sx}
      inputRef={props.inputRef}
      onChange={handleChange}
      slotProps={{
        textField: {
          size: props.size,
          fullWidth: props.fullWidth,
        },
      }}
    />
  );
};
