import React, { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Box, Card, Stack, Typography } from "@mui/material";
import { EnvironmentAwareBuilding } from "@airmont/firefly/my-chimney/ts/building";
import { AddressId, FlueId } from "@airmont/firefly/shared/ts/domain";
import { useTranslation } from "react-i18next";
import { BuildingList } from "@airmont/firefly/my-chimney/ts/building";

export interface BuildingSelectionPageProps {
  buildings: Array<EnvironmentAwareBuilding>;
  selectedBuildingId?: AddressId;
  selectedFlueId?: FlueId;
  onChange: (building: AddressId, flue?: FlueId) => void;
}

export const BuildingSelectionPage: FC<BuildingSelectionPageProps> = (
  props
) => {
  const { buildings, selectedBuildingId, selectedFlueId } = props;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");

  const handleBuildingSelect = (value: AddressId) => {
    props.onChange(value, selectedFlueId);
  };

  return (
    <AppPage
      name={"Building loader"}
      hideName
      mainPane={
        <Box
          sx={{
            flexGrow: 1,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Stack
            direction={"column"}
            useFlexGap
            gap={1}
            sx={{
              alignSelf: "center",
              minWidth: "100px",
              width: "fit-content",
            }}
          >
            {buildings.length === 0 && (
              <Box>{t("You have no buildings available")}</Box>
            )}
            {buildings.length > 0 && (
              <>
                <Typography align={"center"}>{t("Choose Building")}</Typography>
                <Card variant={"outlined"}>
                  <BuildingList
                    buildings={buildings}
                    selected={selectedBuildingId}
                    onSelect={handleBuildingSelect}
                  />
                </Card>
              </>
            )}
          </Stack>
        </Box>
      }
    />
  );
};
