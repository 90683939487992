import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { alpha, Tooltip, useTheme } from "@mui/material";
import React, { FC } from "react";

export const ActionIcon: FC = () => {
  const theme = useTheme();
  return (
    <Tooltip title={"Handling"}>
      <ArrowCircleRightRoundedIcon
        sx={{
          color:
            theme.palette.mode === "light"
              ? alpha(theme.palette.common.black, 0.4)
              : alpha(theme.palette.common.white, 0.7),
        }}
      />
    </Tooltip>
  );
};
