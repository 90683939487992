import {
  Address,
  AddressDto,
  AddressId,
  FlueId,
} from "@airmont/firefly/shared/ts/domain";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";
import { Chimneys } from "./Chimneys";
import { Chimney, ChimneyDto, ChimneyId, ChimneyImpl } from "./chimney/Chimney";
import { Flue, FlueImpl } from "./chimney/flue/Flue";
import {
  Fireplace,
  FireplaceId,
  FireplaceImpl,
} from "./chimney/fireplace/Fireplace";

export interface BuildingDto {
  readonly address: AddressDto;
  readonly chimneys: Array<ChimneyDto>;
}

export interface Building {
  readonly id: AddressId;
  readonly address: Address;
  readonly name: string;
  readonly chimneys: Array<Chimney>;

  hasChimney(id: ChimneyId): boolean;
  getIndexOfChimney(chimney: Chimney): number;
  getFirstChimney(): Chimney;
  getNextChimney(current: Chimney): Chimney;
  getPreviousChimney(current: Chimney): Chimney;
  findFlueById(id: FlueId): Flue | undefined;
  getNumberOfFlues(): number;
  getFlues(): Array<Flue>;

  getFireplaces(): Array<Fireplace>;
}
export class BuildingImpl implements Building {
  private readonly _chimneys: Chimneys;
  readonly address: Address;

  constructor(args: { address: Address; chimneys: Array<Chimney> }) {
    this.address = args.address;
    args.chimneys.forEach((chimney) => {
      chimney.setBuilding(this);
    });
    this._chimneys = new Chimneys(args.chimneys);
  }

  get id(): AddressId {
    return this.address.id;
  }

  get name(): string {
    return this.address.streetAddress.asString;
  }

  get chimneys(): Array<Chimney> {
    return this._chimneys.chimneys;
  }

  getChimneys(): Chimneys {
    return this._chimneys;
  }

  getIndexOfChimney(chimney: Chimney): number {
    return this._chimneys.getIndexOfChimney(chimney);
  }

  getFirstChimney(): Chimney {
    return this._chimneys.getFirstChimney();
  }

  getNextChimney(current: Chimney): Chimney {
    return this._chimneys.getNextChimney(current);
  }
  getPreviousChimney(current: Chimney): Chimney {
    return this._chimneys.getPreviousChimney(current);
  }

  getFirstFlue(): Flue {
    return this._chimneys.getFirstFlue();
  }

  copy(args: { address?: Address; chimneys?: Array<Chimney> }): BuildingImpl {
    return new BuildingImpl({
      address: args.address ?? this.address,
      chimneys: args.chimneys ?? this.chimneys,
    });
  }

  hasChimney(id: ChimneyId): boolean {
    return this.chimneys.some((it) => it.id === id);
  }

  findChimneyById(id: ChimneyId): Chimney | undefined {
    for (let c = 0; c < this.chimneys.length; c++) {
      const chimney = this.chimneys[c];
      if (chimney.id === id) {
        return chimney;
      }
    }
    return undefined;
  }

  findFlueById(id: FlueId): Flue | undefined {
    for (let c = 0; c < this.chimneys.length; c++) {
      const chimney = this.chimneys[c];
      for (let f = 0; f < chimney.flues.length; f++) {
        const flue = chimney.flues[f];
        if (flue.id === id) {
          return flue;
        }
      }
    }
    return undefined;
  }

  findFireplaceById(id: FireplaceId): Fireplace | undefined {
    for (let c = 0; c < this.chimneys.length; c++) {
      const chimney = this.chimneys[c];
      for (let f = 0; f < chimney.flues.length; f++) {
        const flue = chimney.flues[f];
        for (let fp = 0; fp < flue.fireplaces.length; fp++) {
          const fireplace = flue.fireplaces[fp];
          if (fireplace.id === id) {
            return fireplace;
          }
        }
      }
    }
    return undefined;
  }

  getNumberOfFlues(): number {
    let count = 0;
    this.chimneys.forEach((it) => {
      count += it.flues.length;
    });
    return count;
  }
  getFlues(): Array<Flue> {
    const flues: Array<Flue> = [];
    this.chimneys.forEach((it) => {
      flues.push(...it.flues);
    });
    return flues;
  }

  getFireplaces(): Array<Fireplace> {
    const fireplaces: Array<Fireplace> = [];
    this.chimneys.forEach((it) => {
      fireplaces.push(...it.getFireplaces());
    });
    return fireplaces;
  }

  static fromDto(dto: BuildingDto): Building {
    const chimneys = dto.chimneys.map((chimneyDto) => {
      const chimney = new ChimneyImpl({
        id: chimneyDto.id,
        mcFields: chimneyDto.mcFields,
        fields: chimneyDto.fields,
      });

      chimneyDto.flues.forEach((flueDto) => {
        const flue = new FlueImpl({
          id: flueDto.id,
          mcFields: flueDto.mcFields,
          fields: flueDto.fields,
          metrics: flueDto.metrics,
        });
        flueDto.fireplaces.forEach((fireplaceDto) => {
          flue.addFireplace(new FireplaceImpl(fireplaceDto));
        });
        chimney.addFlue(flue);
      });
      return chimney;
    });

    return new BuildingImpl({
      address: new Address(dto.address),
      chimneys: chimneys,
    });
  }

  static getById(id: AddressId, buildings: Array<Building>): Building {
    return (
      buildings.find((it) => it.id === id) ??
      _throw(
        new IllegalStateError(
          `Building with id [${id}] not found in: \n${JSON.stringify(
            buildings,
            null,
            " "
          )}`
        )
      )
    );
  }
}
