import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import { BuildingDto } from "./Building";
import { AddressDto } from "@airmont/firefly/shared/ts/domain";
import { ChimneyDto } from "./chimney/Chimney";

export class BuildingController {
  public readonly handlers: RequestHandler[];

  private readonly addresses: Array<AddressDto>;
  private readonly chimneys: Array<ChimneyDto>;
  private readonly buildings: Array<BuildingDto>;

  constructor(args: {
    addresses: Array<AddressDto>;
    chimneys: Array<ChimneyDto>;
  }) {
    this.addresses = args.addresses;
    this.chimneys = args.chimneys;
    this.buildings = this.addresses.map((address) => {
      return {
        address: address,
        chimneys: this.chimneys,
        /*chimneys: this.chimneys.filter(
          (chimney) => chimney.addressId === address.id
        ),*/
      };
    });
    this.handlers = [
      rest.get("/api/building/get-my-buildings", this.getMyBuildings),
    ];
  }

  getMyBuildings = async (
    req: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse> => {
    const result = this.buildings;
    return res(ctx.json(result));
  };
}
