import React, { FC, FocusEvent, ReactNode, useMemo } from "react";
import { PropertyValue } from "./PropertyValue";
import { TextField_size } from "@airmont/shared/ts/ui/mui";
import { SxProps } from "@mui/system";
import { _throw } from "@airmont/shared/ts/utils/core";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { PropertyValueType } from "./PropertyValueType";
import { InputBaseProps } from "@mui/material/InputBase";
import { merge } from "lodash";
import { resolvePropertyValueTypeFromValue } from "./resolvePropertyValueTypeFromValue";

export interface PropertyEditStringProps {
  label: string;
  name: string;
  value: PropertyValue;
  multiline?: boolean;
  type?: PropertyValueType;
  onChange: (value: PropertyValue, name: string) => void;
  onBlur?: (event: FocusEvent) => void;
  unit?: string;
  size?: TextField_size;
  fullWidth?: boolean;
  info?: ReactNode;
  autoFocus?: boolean;
  helperText?: ReactNode;
  error?: boolean;
  inputProps?: InputBaseProps["inputProps"];
  inputRef?: React.Ref<HTMLInputElement>;
  sx?: SxProps;
}

export const PropertyEditString: FC<PropertyEditStringProps> = (props) => {
  const inputType = resolveHtmlInputType(props.type ?? "string");

  const sx: SxProps = useMemo(() => {
    return merge(props.sx, {
      width: props.fullWidth !== true ? "25ch" : undefined,
    });
  }, [props.sx, props.fullWidth]);

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const type =
      props.type != null
        ? props.type
        : resolvePropertyValueTypeFromValue(props.value);
    const typedValue = typeValue(event.target.value, type);

    props.onChange(
      typedValue,
      props.name ?? _throw(new Error("name cannot be null"))
    );
  };

  return (
    <TextField
      className={"Property"}
      autoFocus={props.autoFocus}
      label={props.label}
      name={props.name ?? _throw(new Error("name cannot be null"))}
      value={props.value ?? ""}
      size={props.size}
      type={inputType}
      multiline={props.multiline}
      onChange={handleTextFieldChange}
      onBlur={(event) => props.onBlur?.(event)}
      fullWidth={props.fullWidth ?? false}
      inputProps={props.inputProps}
      inputRef={props.inputRef}
      InputProps={{
        endAdornment:
          props.unit || props.info ? (
            <InputAdornment position="end">
              {props.unit}{" "}
              {props.info && (
                <Tooltip title={props.info}>
                  <IconButton size={"small"}>
                    <HelpOutlineRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ) : undefined,
      }}
      helperText={props.helperText}
      error={props.error}
      sx={sx}
    />
  );
};

const resolveHtmlInputType = (
  type: PropertyValueType
): React.InputHTMLAttributes<unknown>["type"] | undefined => {
  if (type === "number") {
    return "number";
  } else if (type === "password") {
    return "password";
  } else {
    return "text";
  }
};

const typeValue = (
  valueAsString: string,
  type: PropertyValueType
): string | number => {
  if (type === "number") {
    return +valueAsString;
  } else {
    return valueAsString;
  }
};
