import { Action, isAction } from "./Action";

export interface SelectableAction extends Action {
  selectable: true;
  selected: boolean;
}

export function isSelectableAction(obj: object): obj is SelectableAction {
  return isAction(obj) && "selectable" in obj && obj.selectable === true;
}
