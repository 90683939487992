import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";
import { alpha, Tooltip, useTheme } from "@mui/material";
import React, { FC } from "react";
import { yellow } from "@mui/material/colors";

export const TipsIcon: FC = () => {
  const theme = useTheme();
  return (
    <Tooltip title={"Tips"}>
      <TipsAndUpdatesRoundedIcon
        sx={{
          color:
            theme.palette.mode === "light"
              ? alpha(yellow["700"], 0.9)
              : alpha(yellow["500"], 0.8),
        }}
      />
    </Tooltip>
  );
};
