import React, { FC, ReactNode } from "react";
import { PropertyWrapper } from "./PropertyWrapper";
import { TextField_size } from "@airmont/shared/ts/ui/mui";
import { SxProps } from "@mui/system";
import { _throw } from "@airmont/shared/ts/utils/core";

export interface PropertyEditFileProps {
  label: string;
  name: string;
  value: File | null | undefined;
  onChange: (value: File, name: string) => void;
  size?: TextField_size;
  fullWidth?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  sx?: SxProps;
  info?: ReactNode;
}

export const PropertyEditFile: FC<PropertyEditFileProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.files &&
      event.target.files.length > 0 &&
      event.target.files[0] != null
    ) {
      props.onChange(
        event.target.files[0],
        props.name ?? _throw(new Error("name cannot be null"))
      );
    }
  };

  return (
    <PropertyWrapper
      label={props.label}
      size={props.size}
      fullWidth={props.fullWidth}
      sx={props.sx}
    >
      <input
        ref={props.inputRef}
        type={"file"}
        onChange={handleChange}
        name={props.name}
      />
    </PropertyWrapper>
  );
};
