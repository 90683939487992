import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

export const PrivacyPolicyContent: FC = (props) => {
  return (
    <Box
      sx={{
        ".MuiTypography-h4": { pt: 1, mb: 1 },
        ".MuiTypography-h5": { mt: 1, pb: 1 },
      }}
    >
      <Typography>
        Denne personvernerklæringen gir informasjon om hvordan
        personopplysninger behandles i airMont og i appen «My Chimney».
        Betegnelsen «vi», «oss» og «airMont» henviser til Airmont AS med
        organisasjonsnummer 922 810 753. Henvisninger til «du», «deg» og «den
        registrerte» henviser til den fysiske personen vi behandler
        personopplysninger om. airMont er behandlingsansvarlig for opplysningene
        beskrevet i denne personvernerklæringen og du finner våre
        kontaktopplysninger nederst i denne erklæringen.
      </Typography>
      <Typography variant={"h4"}>1 Formål og rettslig grunnlag</Typography>
      <Typography variant={"h5"}>1.1 Appen My Chimney</Typography>
      <Typography>
        airMont tilbyr appen «My Chimney». Formålet med å behandle
        personopplysninger er å tilby appen og gi brukere innsikt i og
        veiledning knyttet til effektiv, miljømessig og sikker fyring. Det er
        helt frivillig å ta i bruk My Chimney. For å kunne få tilgang til
        informasjon fra appen, må brukeren ha registrert bostedsadresse i
        Folkeregisteret og boligen må ha installert Pipesensor.
      </Typography>
      <Typography>
        Personopplysningene som behandles for å tilby appen inkluderer fornavn
        og etternavn, telefonnummer, e-post, adresse, innloggingsdetaljer fra
        (Bank-ID), personnummer, fødselsdato, unik bruker ID og data samlet inn
        fra Pipesensor (mengde sot, fyringstid og fyringstidspunkter).
        Opplysninger samlet inn fra Pipesensor er ikke personopplysninger i seg
        selv, men de kan si noe om aktiviteter som foregår i husstanden, f.eks.
        knyttet til lokasjon og aktivitet i husstanden (fyringskurve). De vil
        dessuten bli koblet sammen med innloggingsinformasjonen.
        Kontaktopplysningene kan også benyttes til å sende varsler om pipebrann.
      </Typography>
      <Typography>
        Det rettslige grunnlaget for behandlingen er avtale etter GDPR art. 6
        (1)(b).
      </Typography>
      <Typography>
        Merk at airMont kun er ansvarlig for innsamling av person-data i den
        grad brukeren har gitt tillatelse til dette gjennom å ta i bruk My
        Chimney. Det er den enkelte kommune som er ansvarlig for øvrig
        innsamling og bruk av data samlet inn via Pipesensor.
      </Typography>
      <Typography variant={"h5"}>1.2 Husstandsmedlemmer</Typography>
      <Typography>
        Dersom du bor på en adresse med Pipesensor installert og en annen person
        i husstanden har lastet ned tjenesten «My Chimney» kan vi behandle
        personopplysninger om husstanden. Opplysningene som samles inn er
        opplysninger om husstandens data samlet inn fra Pipesensor (mengde sot,
        fyringstid og fyringstidspunkter) som indirekte kan si noe om
        tilstedeværelse og aktivitet i husstanden du er medlem av. airMont har
        ingen direkte tilgang til informasjon om hvem som bor i husstanden, men
        dette kan være tilgjengelig f.eks. på nett eller i offentlige registre.
      </Typography>
      <Typography>
        Behandlingen av opplysninger om husstanden, som følge av at et
        husstandsmedlem har gitt oss tillatelse til innsamling av
        personopplysninger gjennom Pipesensor, er vår berettigede interesse i å
        tilby en tjeneste med formål om å sikre effektiv, miljømessig og sikker
        fyring etter GDPR art. 6(1)(f).
      </Typography>
      <Typography variant={"h5"}>1.3 Feillogging og monitorering</Typography>
      <Typography>
        Vi benytter feilloggingstjenesten Session Replay fra Sentry til å
        monitorene en brukersesjon for å avdekke feil, rar oppførsel og treghet.
        Tjenesten er nødvendig for at tjenestene våre skal fungere, og blir
        automatisk aktivert. Opplysningene som logger er tekst/data som fylles
        inn i tekstfelter, samt museklikk og bevegelser i appen. Dataene blir
        sladdet.
      </Typography>
      <Typography>
        I den grad det behandles personopplysninger er dette basert på en
        berettiget interesse etter GDPR art. 6(1)(b). Den berettigede interessen
        består av å kunne tilby en sikker og fungerende app.
      </Typography>
      <Typography>Opplysningene lagres i opptil 90 dager.</Typography>
      <Typography variant={"h5"}>
        1.4 Avtaler med samarbeidspartnere og leverandører
      </Typography>
      <Typography>
        airMont behandler personopplysninger om samarbeidspartnere og
        leverandører med formål om avtaleoppfølging, tjenestelevering og
        tjenesteutveksling. Personopplysningene som behandles er
        kontaktopplysninger som navn, telefonnummer, adresser og e-postadresser
        samt eventuelt fakturainformasjon. Det rettslige grunnlaget for
        behandlingen er avtalen virksomheten har med de enkelte
        samarbeidspartnere og leverandører, dersom avtalen er inngått med
        personen virksomheten behandler personopplysninger om direkte jf. GDPR
        artikkel 6(1)(b). Dersom det er inngått avtale på vegne av en virksomhet
        er det rettslige grunnlaget vår berettigede interesse i å kunne
        gjennomføre avtaleforhold jf. GDPR artikkel 6(1)(f).{" "}
      </Typography>
      <Typography variant={"h4"}>
        2 Mottakere av personopplysningene og bruk av underleverandører
      </Typography>
      <Typography>
        airMont gir ikke dine personopplysninger videre til tredjeparter med
        mindre slik utlevering følger av rettslige forpliktelser for
        virksomheten eller vi har et annet lovlig grunnlag for dette.{" "}
      </Typography>
      <Typography>
        Vi benytter underleverandører (databehandlere) for enkelte
        administrative oppgaver, herunder Microsoft for å «hoste» tjenesten My
        Chimney. For de tilfeller at vi deler opplysningene med databehandler er
        behandlingen av personopplysninger sikret gjennom databehandleravtaler.
        Databehandleravtalene sikrer at personopplysningene ikke brukes til
        annet formål og at behandlingen oppfyller kravene i GDPR.{" "}
      </Typography>
      <Typography>
        airMont behandler enkelte personopplysninger utenfor EU/EØS herunder
        USA. I slike tilfeller er overføringen basert på EU-US Data Privacy
        Framework, eller EU Standard Contractual Clauses.{" "}
      </Typography>
      <Typography variant={"h4"}>3 Den registrertes rettigheter</Typography>
      <Typography>
        Ved å henvende seg til oss har du som registrert rett til å kreve:
      </Typography>
      <ul>
        <Typography component={"li"}>
          <b>Retting, innsyn og sletting.</b> Du har rett til å be om en gratis
          kopi av dine personopplysninger, be om retting av uriktige
          opplysninger, og under visse omstendigheter be om at
          personopplysningene dine slettes.
        </Typography>
        <Typography component={"li"}>
          <b>Begrensning.</b> Du har rett til å kreve at vi begrenser
          behandlingen av dine personopplysninger under visse omstendigheter,
          for eksempel mens vi undersøker eventuelle innvendinger fra deg
          knyttet til vår behandling av personopplysninger.
        </Typography>
        <Typography component={"li"}>
          <b>Dataportabilitet.</b> I noen tilfeller har du rett til
          dataportabilitet, det vil si at du kan kreve at vi utleverer
          opplysninger om deg i et strukturert, anvendbart og maskinlesbart
          format, for å kunne overføre disse til en annen behandlingsansvarlig.
          Dette gjelder bare dersom vi behandler personopplysningene basert på
          ditt samtykke eller en avtale med deg.
        </Typography>
        <Typography component={"li"}>
          <b>Protestere.</b> Du har rett til å protestere mot vår direkte
          markedsføring (inkludert profilering for disse formålene). Vi vil da
          stanse vår behandling av dine personopplysninger for slike formålene.
        </Typography>
        <Typography component={"li"}>
          <b>Protest mot direkte markedsføring.</b>Du har i noen tilfeller rett
          til å protestere mot vår behandling av dine personopplysninger. Vi vil
          da stanse behandlingen av opplysningene, med mindre vi kan vise til
          tvingende berettigede grunner til at vi kan fortsette behandlingen.
        </Typography>
        <Typography component={"li"}>
          <b>Trekke tilbake samtykke.</b> Hvis behandlingen av
          personopplysninger er basert på ditt samtykke, har du rett til å
          trekke tilbake ditt samtykke når som helst. Vi vil da stanse fremtidig
          behandling av personopplysninger som er basert på ditt samtykke.
        </Typography>
        <Typography component={"li"}>
          <b>Klage til Datatilsynet.</b> Du har rett til å klage til
          Datatilsynet over behandlingen av dine personopplysninger. Vi setter
          pris på om du tar kontakt med oss direkte først.
        </Typography>
      </ul>
      <Typography variant={"h4"}>4 Oppbevaringstid</Typography>
      <Typography>
        Vi behandler personopplysninger så lenge vi har et rettslig grunnlag for
        dette. For eksempel vil vi behandle dine opplysninger så lenge du har en
        avtale med oss, for eksempel om bruk av tjenesten My Chimney og deretter
        utgangspunkt slette de når du avslutter tjenesten eller avtaleforholdet.
      </Typography>
      <Typography>
        Hvis grunnlaget er samtykke, vil airMont lagre personopplysninger så
        lenge vi har et gyldig samtykke.
      </Typography>
      <Typography>
        Vi vil dessuten oppbevare personopplysninger dersom vi har en lovpålagt
        plikt til dette, f.eks. etter bokførings- eller regnskapslovgivning.{" "}
      </Typography>
      <Typography>
        I visse tilfeller vil vi dessuten kunne fortsette å behandle
        personopplysninger ut over dette, for eksempel dersom det er nødvendig
        for å fastsette, forsvare oss mot eller gjøre gjeldende et rettskrav.
      </Typography>
      <Typography variant={"h4"}>
        5 Kontaktinformasjon til behandlingsansvarlig
      </Typography>
      <Typography>
        Dersom du har spørsmål til hvordan airMont AS som behandlingsansvarlig
        for aktivitetene beskrevet i denne personvernerklæringen behandler
        personopplysninger, kontakt oss gjerne på:
      </Typography>
      <Typography>
        Adresse: <br />
        Langbrygga 3,
        <br />
        1767 Halden
      </Typography>
      <Typography>E-post: hello@airmont.no</Typography>
    </Box>
  );
};
