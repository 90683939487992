export interface StreetAddressDto {
  street: string;
  houseNumber?: number;
  houseLetter?: string;
  houseSection?: string;
}

export class StreetAddress {
  readonly street: string;
  readonly houseNumber?: number;
  readonly houseLetter?: string;
  readonly houseSection?: string;
  readonly asString: string;

  constructor(args: StreetAddressDto) {
    this.street = args.street;
    this.houseNumber = args.houseNumber;
    this.houseLetter = args.houseLetter;
    this.houseSection = args.houseSection;

    let asString = this.street;
    if (this.houseNumber != null) {
      asString += " " + this.houseNumber;
      if (this.houseLetter != null) {
        asString += " " + this.houseLetter;
      }
    }
    if (this.houseSection != null) {
      asString += " " + this.houseSection;
    }
    this.asString = asString;
  }

  toString(): string {
    return this.asString;
  }
}
