import { MuiThemeOption } from "./editor/MuiThemeOption";

export const overridableThemeOptions : Array<MuiThemeOption> = [
  {
    name: "Primary.main",
    path: "palette.primary.main"
  },
  {
    name: "Secondary.main",
    path: "palette.secondary.main"
  },
  {
    name: "Backround.default",
    path: "palette.background.default"
  },
  {
    name: "Backround.paper",
    path: "palette.background.paper"
  }
]
