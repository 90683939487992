import { SettingConfig } from "../SettingConfig";
import { SettingKey } from "../SettingKeyDto";
import { NonAsyncSettingsStorage } from "./NonAsyncSettingsStorage";
import { AppKey } from "@airmont/shared/ts/ui/app-info";
import { SettingOverrides } from "../SettingOverrides";

export const LocalSettingsStorage: NonAsyncSettingsStorage = {
  type: "NonAsync",

  loadSettings(
    appKey: AppKey,
    user: string | null | undefined
  ): Record<string, string | null> {
    const keyPrefix = user != null ? `${appKey}:${user}:` : `${appKey}::`;
    const loadedEntries: Record<string, string | null> = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key != null && key.startsWith(keyPrefix)) {
        loadedEntries[key] = localStorage.getItem(key);
      }
    }

    return loadedEntries;
  },
  readRawValue<Type>(
    key: SettingKey,
    config: SettingConfig<Type>,
    overrides?: SettingOverrides<Type>
  ): string | null {
    const asString = localStorage.getItem(key.asString);
    if (asString == null) {
      return null;
    }
    return asString;
  },
  readValue<Type>(
    key: SettingKey,
    config: SettingConfig<Type>,
    overrides?: SettingOverrides<Type>
  ): Type | null {
    const asString = localStorage.getItem(key.asString);
    if (asString == null) {
      return null;
    }
    return overrides?.deserialize != null
      ? overrides.deserialize(asString)
      : config.deserialize(asString);
  },
  writeValue<Type>(
    key: SettingKey,
    newValue: Type | null,
    settingConfig: SettingConfig<Type>,
    overrides?: SettingOverrides<Type>
  ) {
    if (newValue == null) {
      localStorage.removeItem(key.asString);
    } else {
      const serialized =
        overrides?.serialize != null
          ? overrides.serialize(newValue)
          : settingConfig.serialize(newValue);
      localStorage.setItem(key.asString, serialized);
    }
  },
};
