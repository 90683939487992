import React, { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Box } from "@mui/material";
import { BurnsLoader } from "@airmont/firefly/my-chimney/ts/burn";
import { useTimeframe } from "@airmont/shared/ts/ui/timeframe";
import { BurnsPageContent } from "./BurnsPageContent";
import { useTranslation } from "react-i18next";
import { useMyChimneyContext } from "../MyChimneyContext";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";

export const BurnsPage: FC = (props) => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const now = useDateTimeNow();
  const { selectedBuilding, selectedFlue } = useMyChimneyContext();
  const timeframeProps = useTimeframe({
    disallowFuture: true,
    maxEnd: now.startOf("day"),
    allowedUnits: ["month"],
    persistUsingUserSettings: true,
    storageId: () => "BurnsPage.timeframe",
  });

  return (
    <AppPage
      $key={"BurnsPage"}
      name={t("Burns")}
      hideName
      mainPane={
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 0,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <BurnsLoader
            environment={selectedBuilding.environment.id}
            flueId={selectedFlue.id}
            fromDate={timeframeProps.timeframe.start}
            toDate={timeframeProps.timeframe.end}
          >
            {({
              burns,
              isAnonymizedTime,
              isBurnsLoading,
              isBurnsFetching,
              isTemperatureSeriesLoading,
              isTemperatureSeriesFetching,
              temperatureSeries,
            }) => {
              return (
                <BurnsPageContent
                  timeframeProps={timeframeProps}
                  burns={burns}
                  isAnonymizedTime={isAnonymizedTime}
                  isBurnsLoading={isBurnsLoading}
                  isBurnsFetching={isBurnsFetching}
                  temperatureSeries={temperatureSeries}
                  isTemperatureSeriesLoading={isTemperatureSeriesLoading}
                  isTemperatureSeriesFetching={isTemperatureSeriesFetching}
                />
              );
            }}
          </BurnsLoader>
        </Box>
      }
    />
  );
};
