import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import {
  SettingsContextDictionary,
  UserSettingsContext,
  UserSettingsContextType,
} from "./UserSettingsContext";
import { useUserOrNull } from "shared-ts-utils-authentication";
import { useAppInfo } from "@airmont/shared/ts/ui/app-info";
import { SettingsStorage } from "./storage/SettingsStorage";
import { LocalSettingsStorage } from "./storage/LocalSettingsStorage";
import { NonAsyncSettingsStorage } from "./storage/NonAsyncSettingsStorage";
import { AsyncSettingsStorage } from "./storage/AsyncSettingsStorage";
import { UnsupportedError } from "@airmont/shared/ts/utils/core";

export interface UserSettingsProviderProps {
  storage?: SettingsStorage;
  children: ReactNode;
}
export const UserSettingsProvider: FC<UserSettingsProviderProps> = (props) => {
  const user = useUserOrNull();
  const userId = user?.id;
  const app = useAppInfo();
  const appKey = app.key;

  const [entries, setEntries] = useState<SettingsContextDictionary>({});
  const storage = props.storage ?? LocalSettingsStorage;
  useEffect(() => {
    const doEffect = async () => {
      let loadedEntries: SettingsContextDictionary;

      if (storage.type === "NonAsync") {
        const nonAsyncStorage = storage as NonAsyncSettingsStorage;
        loadedEntries = nonAsyncStorage.loadSettings(appKey, userId);

        console.debug(
          `UserSettingsProvider loaded the following settings user: ${userId}`
        );
        Object.entries(loadedEntries).forEach(([key, value]) => {
          const debugKey = key.substring(key.lastIndexOf(":") + 1);
          console.debug(`${debugKey}: ${value}`);
        });

        setEntries(loadedEntries);
      } else if (storage.type === "Async") {
        loadedEntries = {};
        const asyncStorage = storage as AsyncSettingsStorage;
        const userSettingDtos = await asyncStorage.loadSettings(appKey, userId);
        userSettingDtos
          .filter((it) => it.user === it.app)
          .forEach((userSetting) => {
            const key = `${userSetting.app}:${userSetting.user}:${userSetting.name}`;
            loadedEntries[key] = userSetting.value;
          });
        userSettingDtos
          .filter((it) => it.user !== it.app)
          .forEach((userSetting) => {
            const key = `${userSetting.app}:${userSetting.user}:${userSetting.name}`;
            loadedEntries[key] = userSetting.value;
          });

        setEntries(loadedEntries);
        console.debug(
          `UserSettingsProvider loaded the following settings for user : ${userId}):`
        );
        Object.entries(loadedEntries).forEach(([key, value]) => {
          const debugKey = key.substring(key.lastIndexOf(":") + 1);
          console.debug(`${debugKey}: ${value}`);
        });
      } else {
        throw new UnsupportedError(
          "Unsupported SettingsStorage.type: " + storage.type
        );
      }
    };
    doEffect();
  }, []);

  const contextValue: UserSettingsContextType = useMemo(
    () => ({
      settings: entries,
      setSettings: setEntries,
      storage: storage,
      localStorage: LocalSettingsStorage,
    }),
    [entries, setEntries, storage]
  );

  return (
    <UserSettingsContext.Provider value={contextValue}>
      {props.children}
    </UserSettingsContext.Provider>
  );
};
