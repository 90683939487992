import React, { FC, FocusEvent, ReactNode } from "react";
import { PropertyValue } from "./PropertyValue";
import { PropertyEditBoolean } from "./PropertyEditBoolean";
import { PropertyValueType } from "./PropertyValueType";
import { resolvePropertyValueTypeFromValue } from "./resolvePropertyValueTypeFromValue";
import { TextField_size } from "@airmont/shared/ts/ui/mui";
import { SxProps } from "@mui/system";
import { ValueOptions } from "./ValueOptions";
import { PropertyEditAutocomplete } from "./PropertyEditAutocomplete";
import { PropertyEditDate } from "./PropertyEditDate";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { PropertyEditDateTime } from "./PropertyEditDateTime";
import { _throw } from "@airmont/shared/ts/utils/core";
import { InputBaseProps } from "@mui/material/InputBase";
import { PropertyEditFile } from "./PropertyEditFile";
import { PropertyEditAutocompleteMultiple } from "./PropertyEditAutocompleteMultiple";
import { PropertyEditString } from "./PropertyEditString";

export interface PropertyEditProps {
  label: string;
  name?: string;
  value: PropertyValue;
  disableFuture?: boolean;
  multiline?: boolean;
  valueOptions?: ValueOptions;
  onChange: (value: PropertyValue, name: string) => void;
  onBlur?: (event: FocusEvent) => void;
  unit?: string;
  type?: PropertyValueType;
  size?: TextField_size;
  fullWidth?: boolean;
  info?: ReactNode;
  autoFocus?: boolean;
  helperText?: ReactNode;
  error?: boolean;
  inputProps?: InputBaseProps["inputProps"];
  inputRef?: React.Ref<HTMLInputElement>;
  sx?: SxProps;
}

export const PropertyEdit: FC<PropertyEditProps> = (props) => {
  const type = props.type ?? resolvePropertyValueTypeFromValue(props.value);

  if (type === "boolean") {
    return (
      <PropertyEditBoolean
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value as boolean}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        inputRef={props.inputRef}
        sx={props.sx}
        onChange={props.onChange}
      />
    );
  } else if (type === "date") {
    return (
      <PropertyEditDate
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        disableFuture={props.disableFuture}
        value={props.value as DateTimeISO}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        sx={props.sx}
        inputRef={props.inputRef}
        onChange={props.onChange}
      />
    );
  } else if (type === "dateTime") {
    return (
      <PropertyEditDateTime
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value as DateTimeISO}
        disableFuture={props.disableFuture}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        sx={props.sx}
        inputRef={props.inputRef}
        onChange={props.onChange}
      />
    );
  } else if (type === "file") {
    return (
      <PropertyEditFile
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value as File}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        inputRef={props.inputRef}
        sx={props.sx}
        onChange={props.onChange}
      />
    );
  } else if (
    props.valueOptions != null &&
    props.valueOptions.multiple === true
  ) {
    return (
      <PropertyEditAutocompleteMultiple
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value}
        valueOptions={props.valueOptions}
        onChange={props.onChange}
        unit={props.unit}
        type={props.type}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        autoFocus={props.autoFocus}
        inputRef={props.inputRef}
        helperText={props.helperText}
        sx={props.sx}
      />
    );
  } else if (props.valueOptions != null) {
    return (
      <PropertyEditAutocomplete
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value}
        valueOptions={props.valueOptions}
        onChange={props.onChange}
        unit={props.unit}
        type={props.type}
        size={props.size}
        info={props.info}
        fullWidth={props.fullWidth}
        autoFocus={props.autoFocus}
        inputRef={props.inputRef}
        helperText={props.helperText}
        sx={props.sx}
      />
    );
  } else {
    return (
      <PropertyEditString
        autoFocus={props.autoFocus}
        label={props.label}
        name={props.name ?? _throw(new Error("name cannot be null"))}
        value={props.value ?? ""}
        unit={props.unit}
        size={props.size}
        type={props.type}
        multiline={props.multiline}
        onChange={props.onChange}
        onBlur={(event) => props.onBlur?.(event)}
        fullWidth={props.fullWidth ?? false}
        inputProps={props.inputProps}
        inputRef={props.inputRef}
        helperText={props.helperText}
        error={props.error}
        sx={props.sx}
      />
    );
  }
};
