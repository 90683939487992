import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { resolveSizeClassFromPixels, SizeClass } from "./SizeClass";
import { WindowSize } from "./WindowSize";
import { useWindowSize } from "usehooks-ts";

export const WindowSizeContext = React.createContext<WindowSize>(
  undefined as unknown as WindowSize
);

WindowSizeContext.displayName = "WindowSize";

export interface WindowSizeProviderProps {
  children?: ReactNode;
}

export const WindowSizeProvider: FC<WindowSizeProviderProps> = (props) => {
  const { width, height } = useWindowSize();
  const [windowWidth, setWindowWidth] = useState<SizeClass>(SizeClass.Expanded);
  const [windowHeight, setWindowHeight] = useState<SizeClass>(
    SizeClass.Expanded
  );

  useEffect(() => {
    setWindowWidth(resolveSizeClassFromPixels(width));
  }, [width]);

  useEffect(() => {
    setWindowHeight(resolveSizeClassFromPixels(height));
  }, [height]);

  const value: WindowSize = useMemo(
    () => ({ windowWidth: windowWidth, windowHeight: windowHeight }),
    [windowWidth, windowHeight]
  );

  return (
    <WindowSizeContext.Provider value={value}>
      {props.children}
    </WindowSizeContext.Provider>
  );
};
