import { Alert, AlertTitle, Button, Stack } from "@mui/material";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PropertiesCard,
  Property,
  PropertyMode,
} from "@airmont/shared/ts/ui/properties-card";
import { LoadingButton } from "@mui/lab";
import { Formik, FormikErrors } from "formik";
import { ResetPasswordResult } from "./IdentityDao";
import { useIdentityDao } from "./useIdentityDao";

type ResetPasswordFormValues = {
  username: string;
  code: string;
  password: string;
  confirmPassword: string;
};

export const SetNewPasswordForm: FC = () => {
  const { t } = useTranslation("shared-ts-ui-identity");
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search]
  );
  const [resetPasswordResult, setResetPasswordResult] = useState<
    ResetPasswordResult | undefined
  >();
  const [resetPasswordResultDescription, setResetPasswordResultDescription] =
    useState<string | undefined>();
  const identityDao = useIdentityDao();
  const propertyMode: PropertyMode =
    resetPasswordResult === "invalid-token" ||
    resetPasswordResult === "succeeded"
      ? "read"
      : "edit";
  const initialValues: ResetPasswordFormValues = {
    username: searchParams.get("email") ?? "",
    code: searchParams.get("code") ?? "",
    password: "",
    confirmPassword: "",
  };

  const handleValidate = (
    values: ResetPasswordFormValues
  ): FormikErrors<ResetPasswordFormValues> => {
    const errors: FormikErrors<ResetPasswordFormValues> = {};

    if (values.username.isBlank()) {
      errors.username = t("Required");
    }
    if (values.password.isBlank()) {
      errors.password = t("Required");
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = t(
        "Password and confirmation of password must match"
      );
    }
    return errors;
  };

  const handleSubmit = async (values: ResetPasswordFormValues) => {
    const resetPasswordResponse = await identityDao.resetPassword({
      email: values.username,
      code: values.code,
      password: values.password,
      confirmPassword: values.confirmPassword,
    });
    setResetPasswordResult(resetPasswordResponse.result);
    setResetPasswordResultDescription(resetPasswordResponse.description);
  };

  const handleTryAgainClick = () => {
    window.location.href = "/";
  };

  const handleGotoLoginClick = () => {
    window.location.href = "/";
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validate={handleValidate}
    >
      {({
        dirty,
        values,
        submitForm,
        isSubmitting,
        setFieldValue,
        touched,
        errors,
        handleBlur,
      }) => {
        const handleSubmitClick = () => {
          submitForm();
        };

        const handleBlurOnLastInput = (event: React.FocusEvent) => {
          handleBlur(event);
        };

        return (
          <PropertiesCard
            header={{
              title: t("Set New Password"),
            }}
            sx={{
              justifyContent: "center",
            }}
          >
            <Property
              name={"username"}
              label={t("E-mail")}
              value={values.username}
              mode={"read"}
              fullWidth
              onFormikFieldValueChange={setFieldValue}
            />
            <Property
              name={"password"}
              label={t("New Password")}
              type={"string"}
              value={values.password}
              mode={propertyMode}
              fullWidth
              onFormikFieldValueChange={setFieldValue}
            />
            <Property
              name={"confirmPassword"}
              label={t("Confirm New Password")}
              type={"string"}
              value={values.confirmPassword}
              mode={propertyMode}
              fullWidth
              onFormikFieldValueChange={setFieldValue}
              onBlur={handleBlurOnLastInput}
              helperText={touched.confirmPassword && errors.confirmPassword}
              error={touched.confirmPassword && errors.confirmPassword != null}
            />
            {(resetPasswordResult === undefined ||
              resetPasswordResult === "failed") && (
              <Stack
                direction={"row"}
                sx={{ width: "100%", justifyContent: "space-evenly" }}
              >
                <LoadingButton
                  color={"secondary"}
                  variant={"contained"}
                  disabled={!dirty}
                  loading={isSubmitting}
                  onClick={handleSubmitClick}
                >
                  {t("Save")}
                </LoadingButton>
              </Stack>
            )}
            {resetPasswordResult === "succeeded" && (
              <>
                <Alert severity={"info"}>{t("Password Changed")}</Alert>
                <Button onClick={handleGotoLoginClick}>{t("Login")}</Button>
              </>
            )}
            {resetPasswordResult === "failed" && (
              <Alert severity={"info"}>
                <AlertTitle>{t("Failed to Change Password")}</AlertTitle>
                {resetPasswordResultDescription &&
                  resetPasswordResultDescription}
              </Alert>
            )}
            {resetPasswordResult === "invalid-token" && (
              <>
                <Alert severity={"info"}>
                  {t("Change Password Token Expired")}
                  {t("Please request '{{val}}' again", {
                    val: t("Forgot Password"),
                  })}
                </Alert>
                <Button onClick={handleTryAgainClick}>{t("Try again")}</Button>
              </>
            )}
          </PropertiesCard>
        );
      }}
    </Formik>
  );
};
