import { List as MuiList, SxProps, useTheme } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import React from "react";
import {
  ItemType,
  ItemVariant,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import { renderItem } from "./renderItem";
import { Layout } from "../../../Layout";
import { LayoutDirection } from "../../../LayoutDirection";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export interface ListProps extends CommonProps {
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  items: Array<ItemType>;
  hideLabels?: boolean;
  onSelected?: (item: SelectableItem) => void;
  sx?: SxProps;
}

export const List = (props: ListProps) => {
  const { variant, layout, layoutDirection, items } = props;
  const theme = useTheme();
  const hideLabels = props.hideLabels ?? false;

  const handleSelectedItem = (item: SelectableItem) => {
    props.onSelected?.(item);
  };

  const sx = useSxMerge(props.sx, {
    "&.layout-large": {
      ".GroupMenu": {
        backgroundColor: "unset",
      },
    },
    "&.layout-medium": {
      ml: "2px",
      mr: "2px",
    },
  });

  return (
    <MuiList disablePadding className={"GroupList layout-" + layout} sx={sx}>
      {items.map((item, index) => {
        return renderItem({
          variant: variant,
          layout: layout,
          index: index,
          item: item,
          onSelected: handleSelectedItem,
          hideLabels: hideLabels,
        });
      })}
    </MuiList>
  );
};
