import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { alpha, Tooltip, useTheme } from "@mui/material";
import React, { FC } from "react";

export const WarningIcon: FC = () => {
  const theme = useTheme();
  return (
    <Tooltip title={"Advarsel"}>
      <WarningRoundedIcon
        sx={{ color: alpha(theme.palette.warning.main, 0.7) }}
      />
    </Tooltip>
  );
};
