import { ReactNode } from "react";
import { Item, ItemType } from "./Item";

export interface ActionGroupPreferenceProps {
  displayInline?: boolean;
  displayGroupNode?: boolean;
  hideLabels?: boolean;
}
export interface ActionGroupPreferences extends ActionGroupPreferenceProps {
  compact?: ActionGroupPreferenceProps;
  medium?: ActionGroupPreferenceProps;
  large?: ActionGroupPreferenceProps;
}
export interface ActionGroup extends Item {
  type: "ActionGroup";
  name?: string;
  nameBreakable?: string;
  reference?: string;
  items: ItemType[];
  icon?: ReactNode;
  disabled?: boolean;
  selectable?: boolean;
  selected?: boolean;
  preferences?: ActionGroupPreferences;
}

export function isActionGroup(obj: object): obj is ActionGroup {
  return typeof obj === "object" && "type" in obj && obj.type === "ActionGroup";
}
