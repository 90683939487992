import {amber} from '@mui/material/colors';
import {blue} from '@mui/material/colors';
import {blueGrey} from '@mui/material/colors';
import {brown} from '@mui/material/colors';
import {cyan} from '@mui/material/colors';
import {deepOrange} from '@mui/material/colors';
import {deepPurple} from '@mui/material/colors';
import {green} from '@mui/material/colors';
import {grey} from '@mui/material/colors';
import {indigo} from '@mui/material/colors';
import {lightBlue} from '@mui/material/colors';
import {lightGreen} from '@mui/material/colors';
import {lime} from '@mui/material/colors';
import {orange} from '@mui/material/colors';
import {pink} from '@mui/material/colors';
import {purple} from '@mui/material/colors';
import {red} from '@mui/material/colors';
import {teal} from '@mui/material/colors';
import {yellow} from '@mui/material/colors';

export type ColorPalette = Record<string, string>

export class ColorSchemeGenerator {
    static complementaries: Array<ColorPalette> = [
        teal,
        orange,
        pink,
        green,
        red,
        yellow,
        deepPurple,
        amber,
        blue,
        lime,
        indigo,
        deepOrange,
        lightBlue,
        brown,
        purple,
        lightGreen,
        blueGrey,
        cyan,
        grey,
    ];

    static getComplementaries(length: number): Array<string> {
        const shade = "500";
        let colorIndex = 0;
        const colors = Array.from({length: length},
            (v, k) => {
                const palette = ColorSchemeGenerator.complementaries[colorIndex++];
                if (colorIndex > ColorSchemeGenerator.complementaries.length - 1) {
                    colorIndex = 0;
                }
                return palette[shade];
            });

        return colors;
    }
}
