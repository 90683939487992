import { Building } from "./Building";
import { FlueId } from "@airmont/firefly/shared/ts/domain";
import { NotFoundError } from "@airmont/shared/ts/utils/core";
import { Flue } from "./chimney/flue/Flue";

export class Buildings {
  private readonly buildings: Array<Building>;
  constructor(buildings: Array<Building>) {
    this.buildings = buildings;
  }

  findFlueById(flueId: FlueId): Flue {
    const flue = this.findFlueByIdOrUndef(flueId);
    if (flue === undefined) {
      throw new NotFoundError("flue: " + flueId);
    }
    return flue;
  }

  findFlueByIdOrUndef(flueId: FlueId): Flue | undefined {
    for (let b = 0; b < this.buildings.length; b++) {
      const found = this.buildings[b].findFlueById(flueId);
      if (found != null) {
        return found;
      }
    }
    return undefined;
  }
}
