import { setupWorker } from "msw";
import { MockControllers } from "./MockControllers";

export const bootDemo = async (): Promise<void> => {
  const mockControllers = new MockControllers();
  const worker = setupWorker(...mockControllers.getHandlers());

  await worker.start({
    onUnhandledRequest: "bypass",
  });
};
