import { TimeDirection } from "./DurationFormatter";
import { UnsupportedError } from "@airmont/shared/ts/utils/core";

export class PartsFixer {
  private readonly locale: Intl.UnicodeBCP47LocaleIdentifier;
  private readonly timeDirection: TimeDirection;
  private readonly isPast: boolean;

  constructor(args: {
    locale: Intl.UnicodeBCP47LocaleIdentifier;
    timeDirection: TimeDirection;
  }) {
    this.locale = args.locale;
    this.timeDirection = args.timeDirection;
    this.isPast = args.timeDirection === "past";
  }

  flatten = (
    allParts: Array<Array<Intl.RelativeTimeFormatPart>>
  ): Array<Intl.RelativeTimeFormatPart> => {
    const modifiedParts: Array<Intl.RelativeTimeFormatPart> = [];

    const isPast = this.isPast;
    for (let o = 0; o < allParts.length; o++) {
      const parts = allParts[o];
      const isLastDurationUnit = o === allParts.length - 1;
      if (
        (parts[0].type === "literal" && this.timeDirection === "none") ||
        o !== 0
      ) {
        parts.splice(0, 1);
      }
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        const isLastPart = i === parts.length - 1;
        if (
          isPast &&
          isLastPart &&
          !isLastDurationUnit &&
          this.partHasAgo(part)
        ) {
          modifiedParts.push({
            ...part,
            value: part.value.substring(0, part.value.indexOf(" siden")),
          });
        } else {
          modifiedParts.push(part);
        }
      }
    }
    return modifiedParts;
  };

  private getAgoTranslated(): string {
    if (this.locale === "no") {
      return " siden";
    } else if (this.locale === "en") {
      return " ago";
    }
    throw new UnsupportedError("locale: " + this.locale);
  }

  private partHasAgo(part: Intl.RelativeTimeFormatPart): boolean {
    return (
      part.type === "literal" && part.value.includes(this.getAgoTranslated())
    );
  }
}
