import { responsiveFontSizes, Theme, ThemeOptions } from "@mui/material/styles";
import { createTheme } from "@mui/material";

export class AppThemeUtils {
  static createThemes(
    themeOptions: Record<string, ThemeOptions>
  ): Record<string, Theme> {
    const themes: Record<string, Theme> = {};
    Object.keys(themeOptions).forEach((key) => {
      const value = themeOptions[key];
      let theme = createTheme(value);
      theme = responsiveFontSizes(theme);
      themes[key] = theme;
    });

    return themes;
  }
}
