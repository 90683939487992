import React, { FC, ReactNode } from "react";
import { Divider, SvgIconProps, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { PageToolbar } from "./PageToolbar";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export interface PageHeaderProps {
  title?: string;
  hideTitle?: boolean;
  titleProps?: {
    sx: SxProps;
  };
  icon?: React.ComponentType<SvgIconProps>;
  children?: ReactNode;
}

export const PageHeader: FC<PageHeaderProps> = (props) => {
  const Icon = props.icon;
  const titleSx = useSxMerge(props.titleProps?.sx, {
    ml: 1,
    mr: 1,
  });
  return (
    <PageToolbar>
      {Icon != null && (
        <Icon
          color={"secondary"}
          sx={{
            ml: 1,
            mr: props.title != null && !props.hideTitle ? 1 : undefined,
          }}
        />
      )}
      {props.title != null && !props.hideTitle && (
        <Typography
          variant={"subtitle2"}
          className={"title"}
          color={"secondary"}
          component={"h1"}
          sx={titleSx}
        >
          {props.title}
        </Typography>
      )}
      {props.title != null && props.children != null && (
        <Divider orientation={"vertical"} light />
      )}
      {props.children}
    </PageToolbar>
  );
};
