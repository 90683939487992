import {DependencyList, useEffect, useState} from "react";
import {DateTime} from "luxon";

export const useDateTimeNow = (deps?: DependencyList) => {
    const [now, setNow] = useState<DateTime>(DateTime.now());

    useEffect(() => {
            setNow(DateTime.now());
        },
      // eslint-disable-next-line react-hooks/exhaustive-deps
        deps ?? []
    );

    return now;
}
