import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import React, { FC, UIEventHandler, useState } from "react";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";
import { useTranslation } from "react-i18next";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  SizeClass,
  useWindowHeightComparer,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";
import { useCriiptoVerify } from "@criipto/verify-react";
import {
  PrivacyPolicyContent,
  UserRegistrationPrivacyPolicyContent,
} from "@airmont/firefly/my-chimney/ts/pages";

export interface RegisterUserPrivacyPolicyProps {
  onPrivacyConsentGiven?: () => void;
  sx?: SxProps;
}

export const RegisterUserPrivacyPolicy: FC<RegisterUserPrivacyPolicyProps> = (
  props
) => {
  const { loginWithRedirect } = useCriiptoVerify();
  const { t } = useTranslation("app");
  const windowWithComparer = useWindowWidthComparer();
  const windowHeightComparer = useWindowHeightComparer();
  const [displayFullPrivacyPolicy, setDisplayFullPrivacyPolicy] =
    useState<boolean>(false);
  const [consentRead, setConsentRead] = useState<boolean>(false);

  const handleConsent = () => {
    loginWithRedirect();
  };

  const handleDetailsScroll: UIEventHandler<HTMLDivElement> = (event) => {
    if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
      event.currentTarget.clientHeight
    ) {
      setConsentRead(true);
    }
  };

  const sx = useSxMerge(props.sx, {
    flex: 1,
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  const handleShortPrivacyPolicyAccordionChange = (
    event: React.SyntheticEvent,
    expanded: boolean
  ) => {
    setDisplayFullPrivacyPolicy(!expanded);
  };

  const handleFullPrivacyPolicyAccordionChange = (
    event: React.SyntheticEvent,
    expanded: boolean
  ) => {
    setDisplayFullPrivacyPolicy(expanded);
  };

  return (
    <Container
      maxWidth={
        windowWithComparer.sizeClass === SizeClass.Compact
          ? "xs"
          : windowWithComparer.sizeClass === SizeClass.Medium
          ? "sm"
          : windowWithComparer.sizeClass === SizeClass.Expanded
          ? "md"
          : "lg"
      }
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stack direction={"column"} useFlexGap gap={2} sx={sx}>
        <AirmontLogoImg />
        <Typography variant={"h2"} align={"center"} sx={{ mb: 2 }}>
          {t("My Chimney")} {t("Privacy Policy")}
        </Typography>
        <Accordion
          expanded={!displayFullPrivacyPolicy}
          onChange={handleShortPrivacyPolicyAccordionChange}
        >
          <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
            {t("In Short")}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              maxHeight:
                windowHeightComparer.sizeClass === SizeClass.Compact
                  ? "150px"
                  : windowHeightComparer.sizeClass === SizeClass.Medium
                  ? "250px"
                  : windowHeightComparer.sizeClass === SizeClass.Expanded
                  ? "450px"
                  : "650px",
              overflowY: "auto",
            }}
          >
            <UserRegistrationPrivacyPolicyContent />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={displayFullPrivacyPolicy}
          onChange={handleFullPrivacyPolicyAccordionChange}
        >
          <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
            {t("Full Privacy Policy")}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              maxHeight:
                windowHeightComparer.sizeClass === SizeClass.Compact
                  ? "75px"
                  : windowHeightComparer.sizeClass === SizeClass.Medium
                  ? "250px"
                  : windowHeightComparer.sizeClass === SizeClass.Expanded
                  ? "450px"
                  : "650px",
              overflowY: "auto",
            }}
            onScroll={handleDetailsScroll}
          >
            <PrivacyPolicyContent />
          </AccordionDetails>
        </Accordion>
        <Stack
          direction={"row"}
          sx={{ width: "100%", justifyContent: "space-evenly" }}
        >
          <Button
            variant={"contained"}
            onClick={handleConsent}
            disabled={!consentRead}
          >
            {t("I Consent")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};
