import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { DateTime } from "luxon";
import {
  BurnDto,
  BurnId,
  BurnTemperatureSeriesDto,
  FlueId,
} from "@airmont/firefly/shared/ts/domain";

export class BurnDao {
  private readonly fetchHelper: FetchHelper;

  constructor(args: { accessToken: string | undefined }) {
    this.fetchHelper = new FetchHelper({
      basePath: "/api/burn/",
    });
    if (args.accessToken !== undefined) {
      this.fetchHelper.authorizationBearer(args.accessToken);
    }
  }

  getByFlue(
    environment: CustomerEnvironmentId,
    flue: FlueId,
    fromDate: DateTime,
    toDate: DateTime
  ): Promise<Array<BurnDto>> {
    return this.fetchHelper
      .get(
        `get-by-flue/${flue}?fromDate=${fromDate
          .toUTC()
          .toISO()}&toDate=${toDate.toUTC().toISO()}`
      )
      .setHeader("firefly-environment", environment)
      .fetchAsJson();
  }

  getTemperatureSeriesByBurn(
    environment: CustomerEnvironmentId,
    burnIds: Array<BurnId>
  ): Promise<Array<BurnTemperatureSeriesDto>> {
    return this.fetchHelper
      .post("get-temperature-series-by-burn", burnIds)
      .setHeader("firefly-environment", environment)
      .fetchAsJson();
  }
}
