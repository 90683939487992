import { useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { useContext, useEffect } from "react";
import { PosthogEnabledContext } from "./PostHogInitializer";

export const usePostHogTrackReactRouter = () => {
  const posthogEnabled = useContext(PosthogEnabledContext);
  const posthog = usePostHog();
  const { pathname, search } = useLocation();
  useEffect(() => {
    if (posthogEnabled) {
      posthog.capture("$pageview", {
        $current_url: pathname + search,
      });
    }
  }, [pathname, search, posthogEnabled, posthog]);
};
