import * as React from "react";
import { FC } from "react";
import { AddressId } from "@airmont/firefly/shared/ts/domain";
import { List, ListItemButton } from "@mui/material";
import { EnvironmentAwareBuilding } from "./EnvironmentAwareBuilding";

export interface BuildingListProps {
  buildings: Array<EnvironmentAwareBuilding>;
  selected: AddressId | undefined;
  onSelect: (value: AddressId) => void;
}
export const BuildingList: FC<BuildingListProps> = (props) => {
  const handleListItemClick = (item: AddressId) => {
    props.onSelect(item);
  };

  return (
    <List>
      {props.buildings.map((it) => {
        return (
          <ListItemButton
            key={it.id}
            selected={it.id === props.selected}
            onClick={() => handleListItemClick(it.id)}
          >
            {it.name} ({it.environment.name})
          </ListItemButton>
        );
      })}
    </List>
  );
};
