import React, { FC } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  SxProps,
  useTheme,
} from "@mui/material";
import { Layout } from "./Layout";
import { LayoutDirection } from "./LayoutDirection";
import { RouteItem } from "./RouteItem";
import { isSelectableItem } from "./SelectableItem";
import { useClassName } from "./useResolveClassName";
import { ItemVariant } from "./ItemVariant";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export interface RouteMenuItemProps {
  item: RouteItem;
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  hideSelection?: boolean;
  hideLabel?: boolean;
  onSelected?: (item: RouteItem) => void;
  sx?: SxProps;
}
export const RouteMenuItem: FC<RouteMenuItemProps> = (props) => {
  const { item, layout, onSelected } = props;
  const hideSelection = props.hideSelection ?? false;
  const hideLabel = props.hideLabel ?? false;
  const selected = isSelectableItem(item) ? item.selected : undefined;
  const selectable = selected !== undefined;

  const classNameRoot = useClassName(props, ["Item", "RouteMenuItem"]);

  const theme = useTheme();

  const handleClick = () => {
    onSelected?.(item);
  };

  const sx = useSxMerge(props.sx, {
    ">.MuiListItemText-root": {
      ">.MuiTypography-root": {
        fontSize: "inherit",
        textAlign: "inherit",
      },
    },
    "&.layout-medium": {
      fontSize: "0.75rem",
    },
    "&.direction-row": {
      textAlign: "left",
    },
    "&.direction-column": {
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
      gap: "4px",
      ".MuiListItemIcon-root": {
        minWidth: "56px",
        maxWidth: "56px",
        minHeight: "32px",
        maxHeight: "32px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "15px",
      },
    },
    "&.direction-column.selected": {
      ".MuiListItemIcon-root": {
        backgroundColor: theme.palette.action.selected,
      },
    },
  });

  return (
    <MenuItem onClick={handleClick} className={classNameRoot} sx={sx}>
      {item.icon != null && (
        <ListItemIcon
          sx={{ color: selected ? theme.palette.secondary.main : undefined }}
        >
          {item.icon}
        </ListItemIcon>
      )}
      {!hideLabel && (
        <ListItemText
          primary={item.name}
          primaryTypographyProps={{
            sx: {
              color: selected ? theme.palette.secondary.main : undefined,
            },
          }}
        />
      )}
    </MenuItem>
  );
};
