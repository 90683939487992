import { Attributes, FC } from "react";
import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import { Metric } from "./Metric";
import { FireplacePanel } from "./FireplacePanel";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { MathUtils } from "@airmont/shared/ts/utils/core";
import { useCombustionPlantColors } from "./useCombustionPlantColors";
import { useTranslation } from "react-i18next";
import { FlueMetricNaturalKey } from "@airmont/firefly/shared/ts/domain";

export interface FluePanelProps extends Attributes {
  flue: Flue;
  selectedFlueMetric: FlueMetricNaturalKey;
  hideName?: boolean;
}
export const FluePanel: FC<FluePanelProps> = (props) => {
  const { t } = useTranslation("firefly-shared-ts-domain");
  const theme = useTheme();
  const hideName = props.hideName ?? false;
  const { backgroundColor } = useCombustionPlantColors();
  const { flue, selectedFlueMetric } = props;
  const flueMetrics = flue.querySingleMetricsOrUndef(
    selectedFlueMetric.unit,
    selectedFlueMetric.time
  );
  return (
    <Card
      className={"Flue"}
      elevation={0}
      sx={{
        backgroundColor: "inherit",
        flexGrow: 1,
        minWidth: "fit-content",
        minHeight: 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      {!hideName && (
        <Typography
          align={"center"}
          sx={{
            position: "absolute",
            top: 10,
            marginLeft: "50%",
            marginRight: "50%",
          }}
        >
          {flue.mcFields?.name}
        </Typography>
      )}
      <Box
        className={"FlueBody"}
        sx={{
          flexGrow: 1,
          minWidth: "fit-content",
          maxWidth: "480px",
          display: "flex",
          flexDirection: "row",
          justifyItems: "center",
        }}
      >
        <Stack
          direction={"column"}
          className={"FlueLeftSide"}
          useFlexGap
          gap={2}
          sx={{
            flex: 1,
            minWidth: "140px",
            width: "140px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {flueMetrics != null && (
            <Metric label={t("Burns")} value={flueMetrics?.metrics.burnCount} />
          )}
        </Stack>
        <Box
          className={"FlueVisualization"}
          sx={{
            minHeight: "100px",
            minWidth: "40px",
            maxWidth: "60px",
            borderLeft: "1px solid " + theme.palette.divider,
            borderRight: "1px solid " + theme.palette.divider,
            backgroundColor: backgroundColor,
          }}
        />
        <Stack
          direction={"column"}
          className={"FlueRightSide"}
          useFlexGap
          gap={2}
          sx={{
            flex: 1,
            minWidth: "140px",
            width: "140px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {flueMetrics != null && (
            <Metric
              label={t("Burn Hours")}
              value={MathUtils.round(flueMetrics?.metrics.burnHourCount ?? 0)}
            />
          )}
        </Stack>
      </Box>
      <Stack
        className={"Fireplaces"}
        direction={"row"}
        gap={2}
        useFlexGap
        sx={{ justifyContent: "center" }}
      >
        {flue.fireplaces.map((fireplace) => {
          return (
            <FireplacePanel
              key={fireplace.id}
              fireplace={fireplace}
              color={backgroundColor}
            />
          );
        })}
      </Stack>
    </Card>
  );
};
