import React, { ReactNode, useEffect, useState } from "react";
import { LoginState } from "./LoginState";
import { useIdentityDao } from "./useIdentityDao";
import { UserContext } from "shared-ts-utils-authentication";
import { IdentityUser, IdentityUserDto } from "./IdentityUser";
import { FetchError } from "@airmont/shared/ts/utils/fetch";

export interface LoginComponentProps {
  loginState: LoginState | undefined;
  onRegisterNewUser: () => void;
  onLoginStateChange: (result: LoginState) => void;
}

export interface LoginProviderProps<U extends IdentityUserDto> {
  loginComponent: React.ElementType<LoginComponentProps>;
  privacyPolicy?: {
    path: string;
    component: React.ElementType;
  };
  registerUser?: {
    path: string;
    component: React.ElementType;
  };
  acceptUserInvitation?: {
    path: string;
    component: React.ElementType;
  };
  confirmUser?: {
    path: string;
    component: React.ElementType;
  };
  resetPassword?: {
    path: string;
    component: React.ElementType;
  };
  authenticationCheck: () => Promise<boolean>;
  userMapper?: (user: U) => IdentityUser;
  children?: ReactNode | ((user: IdentityUser) => ReactNode);
}

export const LoginProvider = <U extends IdentityUserDto>(
  props: LoginProviderProps<U>
) => {
  const {
    loginComponent,
    privacyPolicy,
    registerUser,
    acceptUserInvitation,
    confirmUser,
    resetPassword,
    authenticationCheck,
    userMapper,
  } = props;
  const identityDao = useIdentityDao();
  const [accessChecked, setAccessChecked] = useState<boolean>(false);
  const [accessAuthorized, setAccessAuthorized] = useState<boolean>(false);
  const [loginResult, setLoginResult] = useState<LoginState | undefined>(
    undefined
  );
  const [user, setUser] = useState<IdentityUser | undefined>(undefined);
  const isPrivacyPolicyRoute =
    privacyPolicy != null
      ? window.location.pathname.startsWith(privacyPolicy?.path)
      : false;
  const isRegisterUser =
    registerUser != null
      ? window.location.pathname.startsWith(registerUser?.path)
      : false;
  const isAcceptUserInvitation =
    acceptUserInvitation != null
      ? window.location.pathname.startsWith(acceptUserInvitation?.path)
      : false;
  const isConfirmUser =
    confirmUser != null
      ? window.location.pathname.startsWith(confirmUser?.path)
      : false;
  const isResetPassword =
    resetPassword != null
      ? window.location.pathname.startsWith(resetPassword?.path)
      : false;

  useEffect(
    function checkAccess() {
      async function checkAccess() {
        try {
          const authenticated = await authenticationCheck();
          setAccessChecked(true);
          setAccessAuthorized(authenticated);
        } catch (e) {
          console.error(
            "LoginProvider: error while checking authentication: ",
            e
          );
          setAccessChecked(true);
        }
      }
      if (!(isRegisterUser || isConfirmUser)) {
        checkAccess();
      }
    },
    [authenticationCheck, isRegisterUser, isConfirmUser]
  );

  useEffect(
    function whenAuthorized() {
      async function whenAuthorized() {
        try {
          const userDto = await identityDao.getUser<U>();
          const user =
            userMapper != null
              ? userMapper(userDto)
              : new IdentityUser(userDto);
          setUser(user);
        } catch (e) {
          console.error("Failed to get logged in user", e);
          if (e instanceof FetchError && e.response?.status === 401) {
            setAccessAuthorized(false);
          }
        }
      }
      if (accessAuthorized) {
        whenAuthorized();
      }
    },
    [accessAuthorized, userMapper, identityDao]
  );

  const handleLogin = (result: LoginState) => {
    if (result === "Success") {
      setAccessAuthorized(true);
    } else {
      setAccessAuthorized(false);
    }
    setLoginResult(result);
  };

  const handleRegisterNewUser = () => {
    if (registerUser != null) {
      window.location.href = registerUser?.path;
    }
  };

  if (resetPassword && isResetPassword) {
    const ResetPasswordComponent = resetPassword?.component;
    return <ResetPasswordComponent />;
  }

  if (privacyPolicy !== undefined && isPrivacyPolicyRoute) {
    const PrivacyPolicyComponent = privacyPolicy?.component;
    return <PrivacyPolicyComponent />;
  }

  if (registerUser !== undefined && isRegisterUser) {
    const RegisterUserComponent = registerUser?.component;
    return <RegisterUserComponent />;
  }

  if (acceptUserInvitation !== undefined && isAcceptUserInvitation) {
    const AcceptUserInvitationComponent = acceptUserInvitation?.component;
    return <AcceptUserInvitationComponent />;
  }

  if (confirmUser !== undefined && isConfirmUser) {
    const ConfirmUserComponent = confirmUser?.component;
    return <ConfirmUserComponent />;
  }

  const LoginComponent = loginComponent;
  return (
    <>
      {!accessChecked && (
        <LoginComponent
          onLoginStateChange={handleLogin}
          onRegisterNewUser={handleRegisterNewUser}
          loginState={"CheckingAccess"}
        />
      )}
      {accessChecked && !accessAuthorized && (
        <LoginComponent
          onLoginStateChange={handleLogin}
          onRegisterNewUser={handleRegisterNewUser}
          loginState={loginResult}
        />
      )}
      {accessChecked && accessAuthorized && user !== undefined && (
        <UserContext.Provider value={user}>
          {typeof props.children === "function"
            ? props.children(user)
            : props.children}
        </UserContext.Provider>
      )}
    </>
  );
};
