import { Container, Stack, SxProps, Typography } from "@mui/material";
import React, { FC } from "react";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";
import { useTranslation } from "react-i18next";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { SetNewPasswordForm } from "@airmont/shared/ts/ui/identity";

export interface ResetPasswordProps {
  sx?: SxProps;
}

export const SetNewPassword: FC<ResetPasswordProps> = (props) => {
  const { t } = useTranslation("app");

  const sx = useSxMerge(props.sx, {
    flex: 1,
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  return (
    <Container
      maxWidth={"xs"}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stack
        className={"ResetPassword"}
        direction={"column"}
        useFlexGap
        gap={2}
        sx={sx}
      >
        <AirmontLogoImg />
        <Typography variant={"h2"} align={"center"} sx={{ mb: 2 }}>
          {t("My Chimney")}
        </Typography>
        <SetNewPasswordForm />
      </Stack>
    </Container>
  );
};
