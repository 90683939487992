import ElderlyRoundedIcon from "@mui/icons-material/ElderlyRounded";
import SensorsOffRoundedIcon from "@mui/icons-material/SensorsOffRounded";
import Battery0BarRoundedIcon from "@mui/icons-material/Battery0BarRounded";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import QuestionMarkRounded from "@mui/icons-material/QuestionMarkRounded";

export enum ChimneySensorStatusEnum {
  Starting = "Starting",
  FailedToStart = "FailedToStart",
  Active = "Active",
  Retired = "Retired",
  NotCommunicating = "NotCommunicating",
  Dead = "Dead",
}

export type ChimneySensorStatusStrings = keyof typeof ChimneySensorStatusEnum;

export const resolveChimneySensorStatusIcon = (
  status: ChimneySensorStatusEnum | undefined
) => {
  if (status === undefined) {
    return <QuestionMarkRounded/>;
  } else if (status === ChimneySensorStatusEnum.Starting) {
    return <HourglassEmptyRoundedIcon/>;
  } else if (status === ChimneySensorStatusEnum.FailedToStart) {
    return <HourglassEmptyRoundedIcon color="error"/>;
  } else if (status === ChimneySensorStatusEnum.Retired) {
    return <ElderlyRoundedIcon/>;
  } else if (status === ChimneySensorStatusEnum.NotCommunicating) {
    return <SensorsOffRoundedIcon color="error"/>;
  } else if (status === ChimneySensorStatusEnum.Dead) {
    return <Battery0BarRoundedIcon color="error"/>;
  } else {
    return <CheckRoundedIcon/>;
  }
};
