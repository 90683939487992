import React, { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { BurnItem } from "./BurnItem";
import { Burn } from "@airmont/firefly/shared/ts/domain";
import { useHotkeys } from "react-hotkeys-hook";
import { Key } from "ts-key-enum";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface BurnListProps {
  burns: Array<Burn> | undefined;
  anonymizedTime: boolean | undefined;
  selectedBurn?: Burn;
  maxWidth: number | undefined;
  onSelected?: (selected: Burn) => void;
}
export const BurnList: FC<BurnListProps> = (props) => {
  const { burns, anonymizedTime, selectedBurn, maxWidth } = props;

  const handleSelectedBurn = (burn: Burn) => {
    props.onSelected?.(burn);
  };

  useHotkeys(Key.ArrowUp, () => {
    if (burns != null) {
      const selectedIndex = burns?.findIndex(
        (it) => it.id === selectedBurn?.id
      );
      if (selectedIndex > 0) {
        const nextBurn = burns[selectedIndex - 1];
        props.onSelected?.(nextBurn);
      }
    }
  });

  useHotkeys(Key.ArrowDown, () => {
    if (burns != null) {
      const selectedIndex = burns?.findIndex(
        (it) => it.id === selectedBurn?.id
      );
      if (selectedIndex < burns.length - 1) {
        const nextBurn = burns[selectedIndex + 1];
        props.onSelected?.(nextBurn);
      }
    }
  });

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {/*<Box
        sx={{
          height: "100%",
          position: "absolute",
          width: "100%",
          background: `linear-gradient(to right, rgba(0,0,0,0) 95%, ${alpha(
            "#282728",
            0.5
          )})`,
          zIndex: 100,
          pointerEvents: "none",
        }}
      ></Box>*/}
      <Stack
        direction={"row"}
        sx={{
          minWidth: 0,
          maxWidth: maxWidth ?? 100 - 100,
          flexWrap: "nowrap",
          overflowX: "auto",
          "> .MuiButton-root": {
            marginBottom: "5px",
            marginRight: "5px",
            marginLeft: "2px",
            marginTop: "2px",
          },
        }}
      >
        {burns != null && burns.length === 0 && (
          <Typography sx={{ minHeight: "78.7px" }}>Ingen fyringer</Typography>
        )}
        {burns != null &&
          burns?.map((burn) => {
            const selected = burn.id === selectedBurn?.id;
            return (
              <BurnItem
                key={burn.id}
                burn={burn}
                anonymizedTime={
                  anonymizedTime ??
                  _throw(
                    new IllegalStateError(
                      "anonymizedTime should not be undefined when burns are not"
                    )
                  )
                }
                selected={selected}
                onSelect={handleSelectedBurn}
              />
            );
          })}
      </Stack>
    </Box>
  );
};
