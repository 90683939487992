import { FC, ReactNode } from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  useTheme,
} from "@mui/material";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";

export interface NavigationBarMenuItemProps {
  label: string;
  icon?: ReactNode;
  value: unknown;
  selected?: boolean;
  hideLabel?: boolean;
  onClick?: () => void;
  sx?: SxProps;
}
export const NavigationBarMenuItem: FC<NavigationBarMenuItemProps> = (
  props
) => {
  const { label, value, icon, onClick } = props;
  const displayLabel = !props.hideLabel ?? true;
  const selected = props.selected ?? false;
  const theme = useTheme();
  const handleClick = () => {
    onClick?.();
  };
  const sx = useSxMerge(props.sx, {
    justifyContent: !displayLabel ? "center" : undefined,
    flexDirection: "column",
  });
  return (
    <ListItemButton onClick={handleClick} sx={sx}>
      {icon != null && (
        <ListItemIcon
          sx={{
            color: selected ? theme.palette.secondary.main : undefined,
            minWidth: "unset",
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      {displayLabel && (
        <ListItemText
          primaryTypographyProps={{
            whiteSpace: "pre-wrap",
            fontSize: "0.75rem",
          }}
          primary={label}
        />
      )}
    </ListItemButton>
  );
};
