import { BottomNavigationAction, Menu, useTheme } from "@mui/material";
import React, { ReactNode } from "react";
import {
  ActionGroup,
  isAction,
  isActionGroup,
  isLinkItem,
  isRouteItem,
  isSelectableAction,
  isSelectableItem,
  LinkListItem,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import { PopoverOrigin } from "@mui/material/Popover/Popover";
import { NavigationBarMenuItem } from "./NavigationBarMenuItem";
import { GroupList } from "../shared/group/list/GroupList";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

export interface NavigationBarMenuProps {
  item: ActionGroup;
  children?: ReactNode;
  onSelected?: (item: SelectableItem) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const NavigationBarMenu = (props: NavigationBarMenuProps) => {
  const { item } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <BottomNavigationAction
        label={item.name}
        showLabel={true}
        value={item.reference}
        icon={item?.icon ?? <MenuRoundedIcon />}
        onClick={handleItemClick}
        sx={{ flexGrow: 0 }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={
          props.anchorOrigin ?? {
            vertical: "top",
            horizontal: "right",
          }
        }
        transformOrigin={
          props.transformOrigin ?? {
            vertical: "top",
            horizontal: "left",
          }
        }
        sx={{
          button: {
            display: "block",
          },
          ".GroupList:not(:first-child)": {
            mt: 1,
          },
          ".GroupList": {
            ml: 1,
            mr: 1,
          },
        }}
      >
        {props.item?.items.map((item, index) => {
          if (isAction(item) || isRouteItem(item)) {
            const handleItemClick = () => {
              if (isRouteItem(item)) {
                props.onSelected?.(item);
              } else if (isSelectableAction(item)) {
                item.onExecute(item);
                props.onSelected?.(item);
              } else if (isAction(item)) {
                item.onExecute(item);
              }
            };

            const selected = isSelectableItem(item) ? item.selected : undefined;
            return (
              <NavigationBarMenuItem
                key={index}
                label={item.name}
                value={item.reference}
                icon={item.icon}
                selected={selected}
                onClick={handleItemClick}
                sx={{
                  color: selected ? theme.palette.secondary.main : undefined,
                }}
              />
            );
          } else if (isLinkItem(item)) {
            return <LinkListItem key={index} item={item} hideLabel={true} />;
          } else if (isActionGroup(item)) {
            return (
              <GroupList
                layout={"compact"}
                key={index}
                item={item}
                labelOrientation={"vertical"}
                hideLabels={true}
                onSelected={props.onSelected}
              />
            );
          } else {
            throw new Error("Unsupported type: \n" + item.type);
          }
        })}
      </Menu>
    </>
  );
};
