import { createContext, FC, ReactNode, useMemo } from "react";
import { AppInfo } from "./AppInfo";

export const AppInfoContext = createContext<AppInfo>({} as AppInfo);

export interface AppInfoProviderProps {
  appInfo: AppInfo;
  children: ReactNode;
}
export const AppInfoProvider: FC<AppInfoProviderProps> = (props) => {
  const appInfo: AppInfo = useMemo(() => {
    const buildVersion = process.env["NX_PUBLIC_BUILD_NUMBER"];
    return { ...props.appInfo, buildVersion: buildVersion };
  }, [props.appInfo]);

  return (
    <AppInfoContext.Provider value={appInfo}>
      {props.children}
    </AppInfoContext.Provider>
  );
};
