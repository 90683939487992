import React, { FC } from "react";
import { Box, Divider } from "@mui/material";
import {
  PropertiesCard,
  Property,
} from "@airmont/shared/ts/ui/properties-card";
import {
  Chimney,
  ChimneyUpdateSpec,
} from "@airmont/firefly/my-chimney/ts/building";
import { useTranslation } from "react-i18next";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { ChimneyForm } from "./ChimneyForm";

export interface ChimneyPanelProps {
  environment: CustomerEnvironmentId;
  chimney: Chimney;
  onChange: (update: ChimneyUpdateSpec) => void;
}

export const ChimneyPanel: FC<ChimneyPanelProps> = (props) => {
  const { chimney, environment } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");

  return (
    <Box>
      <PropertiesCard
        header={{
          title: `${t("Chimney")}${
            chimney.mcFields?.name != null
              ? `: ${chimney.mcFields?.name ?? ""}`
              : ""
          }`,
          subtitle:
            "Brannvesenet har registert følgende informasjon om skorsteinen",
        }}
        elevation={0}
      >
        <Property
          label={t("Chimney Hat")}
          value={t(`ChimneyHat.${chimney.fields.hat}`)}
        />
      </PropertiesCard>
      {chimney.mcFields != null && (
        <>
          <Divider />
          <ChimneyForm
            chimney={chimney}
            environment={environment}
            onChange={props.onChange}
          />
        </>
      )}
    </Box>
  );
};
