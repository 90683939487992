import { SettingConfig } from "./SettingConfig";
import { AppKey } from "@airmont/shared/ts/ui/app-info";
import { User } from "shared-ts-utils-authentication";
import { LocalSettingsStorage } from "./storage/LocalSettingsStorage";
import { SettingKey } from "./SettingKeyDto";

export class UserSettings {
  readonly app: AppKey;
  readonly user: User | null;

  constructor(params: { app: AppKey; user: User | null }) {
    this.app = params.app;
    this.user = params.user;
  }

  read<Type>(name: string, config: SettingConfig<Type>): Type | null {
    const key = SettingKey.from(name, this.app, this.user?.id);
    return LocalSettingsStorage.readValue(key, config);
  }

  write<Type>(name: string, value: Type | null, config: SettingConfig<Type>) {
    const key = SettingKey.from(name, this.app, this.user?.id);
    return LocalSettingsStorage.writeValue(key, value, config);
  }
}
