import { FC } from "react";
import { FlueMetricValues } from "./FlueMetricValues";
import {
  PropertiesCard,
  Property,
} from "@airmont/shared/ts/ui/properties-card";
import { SxProps } from "@mui/material";
import { useFireflySharedTsDomainTranslation } from "../../../i18n";
import { MathUtils } from "@airmont/shared/ts/utils/core";

export interface FlueMetricValuesPanelProps {
  metrics: FlueMetricValues;
  elevation?: number;
  header?: {
    title?: string;
    subtitle?: string;
  };
  sx?: SxProps;
}

export const FlueMetricValuesPanel: FC<FlueMetricValuesPanelProps> = (
  props
) => {
  const { t } = useFireflySharedTsDomainTranslation();
  return (
    <PropertiesCard
      sx={props.sx}
      header={props.header}
      elevation={props.elevation}
    >
      <Property label={t("Burns")} value={props.metrics.burnCount} />
      <Property
        label={t("Burn Hours")}
        value={MathUtils.round(props.metrics.burnHourCount)}
      />
      <Property
        label={t("Max Temperature")}
        value={props.metrics.temperatureMax}
        unit={"°C"}
      />
      <Property
        label={t("Soot Index")}
        value={MathUtils.round(props.metrics.sootIndex)}
      />
      <Property
        label={t("Chimney Fires")}
        value={props.metrics.chimneyFireCount}
      />
    </PropertiesCard>
  );
};
