import { Duration } from "luxon";
import { MathUtils } from "@airmont/shared/ts/utils/core";
import { PartsFixer } from "./PartsFixer";

export type TimeDirection = "past" | "future" | "none";
export interface DurationFormatterOptions {
  timeDirection?: TimeDirection;
  excludePrefix?: boolean;
  roundValues?: boolean;
  maxNumberOfParts?: number;
  lastUnit?: Intl.RelativeTimeFormatUnit;
  style?: Intl.RelativeTimeFormatStyle;
}

export class DurationFormatter {
  private static readonly allDurationUnits: Array<Intl.RelativeTimeFormatUnit> =
    [
      "years",
      "quarters",
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds",
    ];
  private readonly durationUnits: Array<Intl.RelativeTimeFormatUnit>;
  private readonly timeDirection: TimeDirection;
  private readonly isPast: boolean;
  private readonly roundValues: boolean;
  private readonly maxNumberOfParts: number;
  private readonly rtf: Intl.RelativeTimeFormat;
  private readonly locale: Intl.UnicodeBCP47LocaleIdentifier;
  public constructor(args: {
    locales?:
      | Intl.UnicodeBCP47LocaleIdentifier
      | Array<Intl.UnicodeBCP47LocaleIdentifier>;
    options?: DurationFormatterOptions;
  }) {
    this.timeDirection = args.options?.timeDirection ?? "none";
    this.isPast = this.timeDirection === "past";
    this.maxNumberOfParts = args.options?.maxNumberOfParts ?? Number.MAX_VALUE;
    this.roundValues = args.options?.roundValues ?? true;
    this.durationUnits =
      args.options?.lastUnit == null
        ? DurationFormatter.allDurationUnits
        : DurationFormatter.allDurationUnits.slice(
            0,
            DurationFormatter.allDurationUnits.indexOf(args.options.lastUnit) +
              1
          );

    this.rtf = new Intl.RelativeTimeFormat(args.locales ?? "en", {
      style: args.options?.style,
    });
    this.locale = this.rtf.resolvedOptions().locale;
  }

  format(duration: Duration): string {
    return this.rtf.format(duration.hours, "hours");
  }

  formatToParts(duration: Duration): Intl.RelativeTimeFormatPart[] {
    const allParts: Array<Array<Intl.RelativeTimeFormatPart>> = [];
    let numberOfParts = 0;

    this.durationUnits.forEach((durationUnit, index) => {
      const value =
        this.timeDirection && this.isPast
          ? this.roundValues
            ? MathUtils.round(duration.get(durationUnit)) * -1
            : duration.get(durationUnit) * -1
          : this.roundValues
          ? MathUtils.round(duration.get(durationUnit))
          : duration.get(durationUnit);

      if (
        (value !== 0 && numberOfParts < this.maxNumberOfParts) ||
        (durationUnit === this.durationUnits[this.durationUnits.length - 1] &&
          allParts.length === 0)
      ) {
        const parts = this.rtf.formatToParts(value, durationUnit);

        allParts.push(parts);
        numberOfParts++;
      }
    });

    return new PartsFixer({
      locale: this.locale,
      timeDirection: this.timeDirection,
    }).flatten(allParts);
  }
}
