import React, { FC, ReactNode } from "react";
import { TreeItem } from "@mui/x-tree-view";
import CropPortraitRoundedIcon from "@mui/icons-material/CropPortraitRounded";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { useTranslation } from "react-i18next";

export interface FlueTreeItemProps {
  flue: Flue;
  children?: ReactNode;
}

export const FlueTreeItem: FC<FlueTreeItemProps> = (props) => {
  const { flue } = props;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");

  let label = t("Flue");
  if (flue.mcFields?.name != null) {
    label += `: ${flue.mcFields?.name}`;
  }
  return (
    <TreeItem
      itemId={`Flue:${flue.id}`}
      label={label}
      slots={{ expandIcon: CropPortraitRoundedIcon }}
    >
      {props.children}
    </TreeItem>
  );
};
