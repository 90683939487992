import { FC, useEffect, useState } from "react";
import { Card, css, Stack, Typography, useTheme } from "@mui/material";
import { useMetricColors } from "./useMetricColors";
import styled, { keyframes } from "styled-components";

const valueChangeAnimation = (color: string) => keyframes`
  0% {
    color: rgba(${color}, 0.2);
  }
  30% {
    color: rgba(${color}, 0.4);
  }
  70% {
    color: rgba(${color}, 0.8);
  }
  100% {
    color: rgba(${color}, 1);
  }
`;

export interface MetricProps {
  label: string;
  value: number;
}
export const Metric: FC<MetricProps> = (props) => {
  const theme = useTheme();
  const { backgroundColor, textColor } = useMetricColors();
  const [showValueChangeAnimation, setShowValueChangeAnimation] =
    useState(false);

  useEffect(() => {
    setShowValueChangeAnimation(true);
    setTimeout(() => {
      setShowValueChangeAnimation(false);
    }, 1000);
  }, [props.value]);
  return (
    <Stack direction={"column"} useFlexGap gap={1}>
      <Typography align={"center"}>{props.label}</Typography>
      <Card>
        <Typography
          className={"value"}
          align={"center"}
          sx={{
            lineHeight: 3,
            minWidth: "90px",
            maxWidth: "90px",
            animation: showValueChangeAnimation ? `move 1s ease` : undefined,
            "@keyframes move": {
              "0%": {
                opacity: 0.2,
              },
              "30%": {
                opacity: 0.4,
              },
              "70%": {
                opacity: 0.8,
              },
              "100%": {
                opacity: 1,
              },
            },
          }}
        >
          {props.value}
        </Typography>
      </Card>
    </Stack>
  );
};
