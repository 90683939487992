import React, { FC } from "react";
import { TreeItem } from "@mui/x-tree-view";
import FireplaceIcon from "@mui/icons-material/Fireplace";
import { Fireplace } from "@airmont/firefly/my-chimney/ts/building";
import { useTranslation } from "react-i18next";
import CropPortraitRoundedIcon from "@mui/icons-material/CropPortraitRounded";

export interface FireplaceTreeItemProps {
  fireplace: Fireplace;
}

export const FireplaceTreeItem: FC<FireplaceTreeItemProps> = (props) => {
  const { fireplace } = props;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");

  let label = t("Fireplace");
  if (fireplace.mcFields?.name != null) {
    label += `: ${fireplace.mcFields?.name}`;
  }

  return (
    <TreeItem
      itemId={`Fireplace:${fireplace.id}`}
      label={label}
      slots={{ expandIcon: FireplaceIcon }}
    />
  );
};
