import {
  BurnId,
  BurnTemperatureSeries,
} from "@airmont/firefly/shared/ts/domain";
import React, { FC } from "react";
import { SxProps } from "@mui/material";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { DateTime } from "luxon";
import { BurnTimeSeriesAnonymizedChart } from "./BurnTimeSeriesAnonymizedChart";
import { BurnTimeSeriesSensitiveChart } from "./BurnTimeSeriesSensitiveChart";
import { notUndef } from "@airmont/shared/ts/utils/core";

export interface BurnTimeSeriesChartProps {
  flue: Flue;
  burnTimeSeries?: Array<BurnTemperatureSeries>;
  anonymizedTime?: boolean;
  selected: Array<BurnId>;
  selectedMonth?: DateTime;
  sx?: SxProps;
}

export const BurnTimeSeriesChart: FC<BurnTimeSeriesChartProps> = (props) => {
  const { flue, burnTimeSeries, selected, selectedMonth } = props;
  const anonymizedTime = props.anonymizedTime ?? false;

  if (anonymizedTime) {
    const burnTimeSeriesNonAnonymized = notUndef(
      burnTimeSeries,
      (burnTimeSeriesArray) =>
        burnTimeSeriesArray.map((burnTs) => burnTs.toNonSensitive())
    );
    return (
      <BurnTimeSeriesAnonymizedChart
        flue={flue}
        burnTimeSeries={burnTimeSeriesNonAnonymized}
        selected={selected}
        selectedMonth={selectedMonth}
        sx={props.sx}
      />
    );
  }

  return (
    <BurnTimeSeriesSensitiveChart
      flue={flue}
      burnTimeSeries={burnTimeSeries}
      selected={selected}
      selectedMonth={selectedMonth}
      sx={props.sx}
    />
  );
};
