import { FC, useMemo } from "react";
import { Form, Formik } from "formik";
import {
  PropertiesCard,
  Property,
  ValueOption,
} from "@airmont/shared/ts/ui/properties-card";
import {
  FormControlLabel,
  Stack,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import { Button } from "@airmont/shared/ts/ui/mui";
import {
  BooleanSetting,
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { useI18Next } from "@airmont/shared/ts/utils/i18n";
import { useTranslation } from "react-i18next";
import { McUser } from "firefly/my-chimney/ts/user";
import { ConsentBurnTimeIsSavedProperty } from "./ConsentBurnTimeIsSavedProperty";

type UserSettingsFormValues = {
  language: string;
  consentBurnTimeIsSaved: boolean;
};

export interface UserSettingsProps {
  user: McUser;
}

export const UserSettings: FC<UserSettingsProps> = (props) => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const [language, setLanguage] = useUserSettingWithDefault(
    "language",
    StringSetting,
    "nb-NO"
  );
  const [consentBurnTimeIsSaved, setConsentBurnTimeIsSaved] =
    useUserSettingWithDefault("consentBurnTimeIsSaved", BooleanSetting, false);

  const { languages } = useI18Next();
  const languagesValueOptions: Array<ValueOption> = useMemo(
    () =>
      languages.map((it) => {
        /* eslint @typescript-eslint/no-explicit-any: 0 */ // --> OFF
        return {
          id: it,
          label: t(it as any),
        };
      }),
    [languages, t]
  );

  const initialFormValues: UserSettingsFormValues = {
    language: language,
    consentBurnTimeIsSaved: consentBurnTimeIsSaved,
  };

  const handleSubmit = async (values: UserSettingsFormValues) => {
    setLanguage(values.language);
    setConsentBurnTimeIsSaved(values.consentBurnTimeIsSaved);
  };

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, dirty, setFieldValue, resetForm }) => {
        const handleReset = () => {
          resetForm();
        };
        return (
          <Form>
            <PropertiesCard
              elevation={0}
              header={{
                title: t("User Settings"),
              }}
            >
              <Property
                name={"language"}
                label={t("Language")}
                value={values.language}
                type={"string"}
                valueOptions={{
                  multiple: false,
                  restrictToOptions: true,
                  options: languagesValueOptions,
                }}
                onChange={(value, name) => setFieldValue(name, value)}
                mode={"edit"}
                fullWidth
              />
              <ConsentBurnTimeIsSavedProperty
                value={values.consentBurnTimeIsSaved}
                onChange={setFieldValue}
              />
            </PropertiesCard>
            <Toolbar>
              <Button type={"submit"} variant={"outlined"} disabled={!dirty}>
                {t("Save")}
              </Button>
              <Button variant={"text"} disabled={!dirty} onClick={handleReset}>
                {t("Reset")}
              </Button>
            </Toolbar>
          </Form>
        );
      }}
    </Formik>
  );
};
