import { createContext, FC, ReactNode, useMemo } from "react";
import { PostHogProvider } from "posthog-js/react";
import { PostHogConfig } from "posthog-js";

export const PosthogEnabledContext = createContext(false);
export interface PostHogInitializerProps {
  apiKey: string;
  enabled: boolean;
  options?: Partial<PostHogConfig> | undefined;
  children: ReactNode;
}
export const PostHogInitializer: FC<PostHogInitializerProps> = (props) => {
  const options = useMemo(() => {
    return { ...props.options, capture_pageview: false };
  }, [props.options]);
  if (!props.enabled) {
    return (
      <PosthogEnabledContext.Provider value={false}>
        {props.children}
      </PosthogEnabledContext.Provider>
    );
  }
  return (
    <PostHogProvider apiKey={props.apiKey} options={options}>
      <PosthogEnabledContext.Provider value={true}>
        {props.children}
      </PosthogEnabledContext.Provider>
    </PostHogProvider>
  );
};
