import { cloneElement, FC, ReactElement } from "react";
import { Box, SxProps } from "@mui/material";
import { useResizeDetector } from "react-resize-detector";
import { TimeSeriesMuiChartProps } from "./TimeSeriesMuiChart";

export interface TimeSeriesMuiChartContainerProps {
  children: ReactElement<TimeSeriesMuiChartProps> | false;
  sx?: SxProps;
}

export const TimeSeriesMuiChartContainer: FC<
  TimeSeriesMuiChartContainerProps
> = (props) => {
  const { ref, width, height } = useResizeDetector();
  return (
    <Box ref={ref} sx={props.sx}>
      {props.children !== false &&
        cloneElement(props.children, { width: width, height: height })}
    </Box>
  );
};
