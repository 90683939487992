import React, { FC, ReactNode } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { SizeClass, useWindowWidth } from "@airmont/shared/ts/ui/responsive";

export interface UnauthenticatedPageProps {
  appIcon?: ReactNode;
  booting?: string;
  appName: ReactNode;
  loginPath?: string;
}

export const UnauthenticatedPage: FC<UnauthenticatedPageProps> = (props) => {
  const size = useWindowWidth();
  const navigate = useNavigate();
  const handleLoginClick = () => {
    if (props.loginPath != null) {
      navigate(props.loginPath);
    }
  };
  return (
    <Paper
      component={"main"}
      elevation={1}
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {props.appIcon != null && props.appIcon}
        {React.isValidElement(props.appName) && props.appName}
        {typeof props.appName === "string" && (
          <Typography
            align={"center"}
            variant={size === SizeClass.Compact ? "h2" : "h1"}
          >
            {props.appName}
          </Typography>
        )}
        <Button
          variant={"contained"}
          onClick={handleLoginClick}
          sx={{
            marginTop: "30px",
            alignSelf: "center",
          }}
        >
          Logg inn
        </Button>
      </Box>
    </Paper>
  );
};
