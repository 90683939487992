import {
  MockedResponse,
  RequestHandler,
  ResponseComposition,
  rest,
  RestContext,
  RestRequest,
} from "msw";
import {
  BurnDto,
  BurnTemperatureSeriesDto,
} from "@airmont/firefly/shared/ts/domain";

export class BurnController {
  public readonly handlers: RequestHandler[];

  private readonly burns: Array<BurnDto>;
  private readonly burnSeries: Array<BurnTemperatureSeriesDto>;

  constructor(args: {
    burns: Array<BurnDto>;
    burnSeries: Array<BurnTemperatureSeriesDto>;
  }) {
    this.burns = args.burns;
    this.burnSeries = args.burnSeries;
    this.handlers = [
      rest.get("/api/burn/get-by-flue/:id", this.getByFlue),
      rest.post(
        "/api/burn/get-temperature-series-by-burn",
        this.getTemperatureSeriesByBurn
      ),
    ];
  }

  getByFlue = async (
    req: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<Array<BurnDto>>> => {
    /*const { id } = req.params;
    const fromDate = req.url.searchParams.get("fromDate");
    const toDate = req.url.searchParams.get("toDate");*/
    const result = this.burns.reverse();
    return res(ctx.json(result));
  };

  getTemperatureSeriesByBurn = async (
    req: RestRequest,
    res: ResponseComposition,
    ctx: RestContext
  ): Promise<MockedResponse<Array<BurnTemperatureSeriesDto>>> => {
    const result = this.burnSeries;
    return res(ctx.json(result));
  };
}
