import { UserId } from "../user/User";

export interface AddressUserDto {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
}

export class AddressUser {
  readonly id: UserId;
  readonly userName: string;
  readonly firstName: string;
  readonly lastName: string;

  constructor(dto: AddressUserDto) {
    this.id = dto.id;
    this.userName = dto.userName;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
  }
}
