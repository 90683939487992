import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import { BurnAssessment } from "./BurnAssessment";

export const resolveBurnAssessmentIcon = (
  value: BurnAssessment
): OverridableComponent<SvgIconTypeMap> => {
  switch (value) {
    case BurnAssessment.Good:
      return StarRateRoundedIcon;
    case BurnAssessment.Normal:
      return ThumbUpRoundedIcon;
    case BurnAssessment.Bad:
      return ThumbDownRoundedIcon;
    case BurnAssessment.Unknown:
      return HelpRoundedIcon;
    default:
      return HelpRoundedIcon;
  }
};
