import { IdentityUser, IdentityUserDto } from "@airmont/shared/ts/ui/identity";
import { Address, AddressDto } from "firefly/my-chimney-users/domain";

export interface McUserDto extends IdentityUserDto {
  firstName?: string;
  lastName?: string;
  addresses: Array<AddressDto>;
}

export class McUser extends IdentityUser {
  firstName?: string;
  lastName?: string;
  addresses: Array<Address>;

  get fullName(): string | undefined {
    if (this.lastName === undefined && this.firstName === undefined) {
      return undefined;
    }
    if (this.lastName === undefined) {
      return this.firstName;
    }
    if (this.firstName === undefined) {
      return this.lastName;
    }
    return `${this.firstName} ${this.lastName}`;
  }
  constructor(args: McUserDto) {
    super(args);
    this.firstName = args.firstName;
    this.lastName = args.lastName;
    this.addresses = args.addresses.map((dto) => new Address(dto));
  }
}
