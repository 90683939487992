import { CustomerEnvironment } from "@airmont/firefly/shared/ts/customer-environment";
import { Address, AddressId } from "@airmont/firefly/shared/ts/domain";
import { Building, BuildingImpl } from "./Building";
import { Chimney } from "./chimney/Chimney";
import { ChimneyUpdateSpec } from "./chimney/ChimneyUpdate";
import { FlueUpdateSpec } from "./chimney/flue/FlueUpdate";
import { FireplaceUpdateSpec } from "./chimney/fireplace/FireplaceUpdate";

export class EnvironmentAwareBuilding extends BuildingImpl implements Building {
  readonly environment: CustomerEnvironment;

  get id(): AddressId {
    return this.environment.id + ":" + this.address.id;
  }

  get name(): string {
    return this.address.streetAddress.asString;
  }

  constructor(args: {
    environment: CustomerEnvironment;
    address: Address;
    chimneys: Array<Chimney>;
  }) {
    super(args);
    this.environment = args.environment;
  }

  copy(args: {
    address?: Address;
    chimneys?: Array<Chimney>;
  }): EnvironmentAwareBuilding {
    return new EnvironmentAwareBuilding({
      environment: this.environment,
      address: args.address ?? this.address,
      chimneys: args.chimneys ?? this.chimneys,
    });
  }

  updateChimney(update: ChimneyUpdateSpec): EnvironmentAwareBuilding {
    return this.copy({
      chimneys: this.chimneys.map((chimney) => {
        if (chimney.id === update.id) {
          return chimney.copy({
            mcFields: {
              name: update.name!.value,
            },
          });
        } else {
          return chimney;
        }
      }),
    });
  }

  updateFlue(update: FlueUpdateSpec): EnvironmentAwareBuilding {
    return this.copy({
      chimneys: this.chimneys.map((chimney) => {
        if (chimney.id === update.chimneyId) {
          return chimney.copy({
            flues: chimney.flues.map((flue) => {
              if (flue.id === update.id) {
                return flue.copy({
                  mcFields: {
                    name: update.name!.value,
                  },
                });
              } else {
                return flue;
              }
            }),
          });
        } else {
          return chimney;
        }
      }),
    });
  }

  updateFireplace(update: FireplaceUpdateSpec): EnvironmentAwareBuilding {
    return this.copy({
      chimneys: this.chimneys.map((chimney) => {
        if (chimney.id === update.chimneyId) {
          return chimney.copy({
            flues: chimney.flues.map((flue) => {
              if (flue.id === update.flueId) {
                return flue.copy({
                  fireplaces: flue.fireplaces.map((fireplace) => {
                    if (fireplace.id === update.id) {
                      return fireplace.copy({
                        mcFields: {
                          name: update.name!.value,
                        },
                      });
                    } else {
                      return fireplace;
                    }
                  }),
                });
              } else {
                return flue;
              }
            }),
          });
        } else {
          return chimney;
        }
      }),
    });
  }
}
