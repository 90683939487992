import React, { FC, ReactNode } from "react";
import { TreeItem } from "@mui/x-tree-view";
import RoofingRoundedIcon from "@mui/icons-material/RoofingRounded";
import { Chimney } from "@airmont/firefly/my-chimney/ts/building";
import { useTranslation } from "react-i18next";

export interface ChimneyTreeItemProps {
  chimney: Chimney;
  children?: ReactNode;
}

export const ChimneyTreeItem: FC<ChimneyTreeItemProps> = (props) => {
  const { chimney } = props;
  const { t } = useTranslation("firefly-my-chimney-ts-pages");

  let label = t("Chimney");
  if (chimney.mcFields?.name != null) {
    label += `: ${chimney.mcFields?.name}`;
  }

  return (
    <TreeItem
      itemId={`Chimney:${chimney.id}`}
      label={label}
      slots={{ expandIcon: RoofingRoundedIcon }}
      sx={{
        ".MuiTreeItem-content": {
          pt: 0.5,
          pb: 0.5,
          pr: 1,
          pl: 1.7,
          borderRadius: "7px",
        },
      }}
    >
      {props.children}
    </TreeItem>
  );
};
