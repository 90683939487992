import { Box, Collapse, useTheme } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import React from "react";
import {
  ActionGroup,
  ItemVariant,
  resolveActionGroupPreferences,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import {
  BooleanSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { GroupNode } from "./GroupNode";
import { List } from "./List";
import { Layout } from "../../../Layout";
import { LayoutDirection } from "../../../LayoutDirection";

export interface GroupListProps extends CommonProps {
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  item: ActionGroup;
  hideLabels?: boolean;
  labelOrientation?: "horizontal" | "vertical";
  onSelected?: (item: SelectableItem) => void;
}

export const GroupList = (props: GroupListProps) => {
  const { variant, layout, layoutDirection, item } = props;
  const theme = useTheme();
  const actionGroupPreferences = resolveActionGroupPreferences(
    props.item.preferences,
    layout
  );
  const hideLabels =
    props.hideLabels ?? actionGroupPreferences?.hideLabels ?? false;
  const labelOrientation = props.labelOrientation ?? "horizontal";
  const [isOpen, setIsOpen] = useUserSettingWithDefault(
    `GroupList[${item?.name}].open`,
    BooleanSetting,
    actionGroupPreferences?.displayGroupNode !== true,
    {
      storeLocally: true,
    }
  );

  const displayGroupNode = actionGroupPreferences?.displayGroupNode ?? false;

  const handleGroupNodeOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const handleSelectedItem = (item: SelectableItem) => {
    props.onSelected?.(item);
  };

  if (displayGroupNode) {
    return (
      <Box className={"GroupList layout-" + layout}>
        <GroupNode
          variant={variant}
          layout={layout}
          item={item}
          hideLabels={hideLabels}
          labelOrientation={labelOrientation}
          open={isOpen}
          onOpenChange={handleGroupNodeOpenChange}
        />
        <Collapse
          className={"GroupList-container"}
          in={isOpen}
          timeout="auto"
          unmountOnExit
          sx={{ mt: 1, pl: 4, pr: 2 }}
        >
          <List
            variant={variant}
            layout={layout}
            layoutDirection={layoutDirection}
            items={props.item.items}
            hideLabels={hideLabels}
            onSelected={handleSelectedItem}
          />
        </Collapse>
      </Box>
    );
  } else {
    return (
      <List
        variant={variant}
        layout={layout}
        layoutDirection={layoutDirection}
        items={props.item.items}
        hideLabels={hideLabels}
        onSelected={handleSelectedItem}
        sx={{
          backgroundColor: theme.palette.action.hover,
          borderRadius: 3,
          padding: 1,
        }}
      />
    );
  }
};
