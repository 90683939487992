import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { EnvironmentBuildingsDto } from "./EnvironmentBuildings";
import { AddressIdResolver } from "@airmont/firefly/shared/ts/domain";
import { BuildingDto } from "./Building";

export class BuildingDao {
  private readonly fetchHelper: FetchHelper;

  constructor() {
    this.fetchHelper = new FetchHelper({
      basePath: "/api/building/",
    });
  }
  getMyBuildings(): Promise<Array<EnvironmentBuildingsDto>> {
    return this.fetchHelper
      .get("get-my-buildings")
      .transformer<Array<EnvironmentBuildingsDto>>((response) => {
        return response.map((environmentBuildings) => {
          return {
            ...environmentBuildings,
            buildings: environmentBuildings.buildings.map((building) => {
              const addressId = AddressIdResolver.resolveAddressId({
                municipalityId: building.address.municipality.id,
                postalCode: building.address.postalAddress.code,
                streetAddress: building.address.streetAddress,
                cadastralAddress: building.address.cadastralAddress,
              });
              return {
                chimneys: building.chimneys,
                address: { ...building.address, id: addressId },
              } as BuildingDto;
            }),
          } as EnvironmentBuildingsDto;
        });
      })
      .fetchAsJson();
  }
}
