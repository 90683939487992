import {
  Collapse,
  Divider,
  ListItemButton,
  Menu,
  Stack,
  useTheme,
} from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import React, { useMemo } from "react";
import {
  ActionGroup,
  isSelectableAction,
  isSelectableItem,
  Item,
  ItemVariant,
  SelectableItem,
} from "@airmont/shared/ts/ui/action";
import { PopoverOrigin } from "@mui/material/Popover/Popover";
import { renderItem } from "./renderItem";
import { GroupNode } from "./GroupNode";
import { Layout } from "../../../Layout";
import { LayoutDirection } from "../../../LayoutDirection";

const resolveSelectedItem = (items: Item[]): SelectableItem | undefined => {
  return items.find((it) => isSelectableItem(it) && it.selected) as
    | SelectableItem
    | undefined;
};

export interface GroupMenuProps extends CommonProps {
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  item: ActionGroup;
  hideLabel?: boolean;
  onSelected?: (item: SelectableItem) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const GroupMenu = (props: GroupMenuProps) => {
  const { variant, layout, layoutDirection, item } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const selectedItem = useMemo(
    () => resolveSelectedItem(item.items),
    [item.items]
  );

  const handleItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelected = (item: SelectableItem) => {
    props.onSelected?.(item);
    setAnchorEl(null);
  };

  return (
    <Stack
      className={"Item GroupMenu layout-" + layout}
      direction={"column"}
      sx={{
        "&.layout-large": {
          borderRadius: selectedItem != null ? 3 : undefined,
          backgroundColor: theme.palette.action.hover,
        },
        "&.layout-medium": {
          borderRadius: 3,
          backgroundColor: theme.palette.action.hover,
        },
      }}
    >
      <GroupNode
        item={props.item}
        layout={layout}
        hideLabel={props.hideLabel}
        hasSiblings={selectedItem != null}
        onClick={handleItemClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorPosition={{
          top: 0,
          left: 4,
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={
          props.anchorOrigin ?? {
            vertical: "bottom",
            horizontal: "center",
          }
        }
        transformOrigin={
          props.transformOrigin ?? {
            vertical: "top",
            horizontal: "left",
          }
        }
      >
        {props.item?.items.map((childItem, index) => {
          const childItemLayoutDirection = isSelectableAction(childItem)
            ? "row"
            : layoutDirection;
          return renderItem({
            variant: variant,
            layout: layout,
            item: childItem,
            index: index,
            onSelected: handleItemSelected,
            layoutDirection: childItemLayoutDirection,
          });
        })}
      </Menu>
      <Collapse
        className={"GroupSelectedItemContainer layout-" + layout}
        in={selectedItem != null}
        timeout={{ enter: 500, exit: 500 }}
        sx={{
          ".Item": {
            "&.selected": {
              "&:hover": {
                backgroundColor: "unset",
                cursor: "auto",
              },
              ".CheckIcon": {
                display: "none",
              },
            },
          },
        }}
      >
        {selectedItem != null && (
          <>
            <Divider sx={{ ml: 1, mr: 1 }} />
            {renderItem({
              variant: variant,
              layout: layout,
              item: selectedItem,
              hideSelection: true,
            })}
          </>
        )}
        {selectedItem == null && (
          <>
            <Divider />
            <ListItemButton
              className={"Item NavigationItem layout-" + layout}
              sx={{ minHeight: "66px" }}
            ></ListItemButton>
          </>
        )}
      </Collapse>
    </Stack>
  );
};
