import { AxisValueFormatterContext } from "@mui/x-charts/models/axis";
import { TimeSeries } from "@airmont/firefly/shared/ts/timeseries";
import { XAxisDefaultConfig } from "@airmont/shared/ts/ui/mui";
import { DateTime } from "luxon";

const scaleType = "utc";

export const resolveXAxisConfig = (
  timeSeries: Array<TimeSeries<number | null>>,
  axisConfig?: XAxisDefaultConfig
): XAxisDefaultConfig[] => {
  if (timeSeries.length === 0) {
    return [
      {
        id: "time",
        scaleType: scaleType,
      },
    ];
  }

  const sortedTimeSeries = [...timeSeries];
  sortedTimeSeries.sort((a, b) => {
    if (a.firstPoint === undefined && b.firstPoint === undefined) {
      return 0;
    }
    if (a.firstPoint === undefined) {
      return -1;
    }
    if (b.firstPoint === undefined) {
      return 1;
    }
    if (a.firstPoint?.time < b.firstPoint?.time) {
      return -1;
    }
    if (a.firstPoint?.time > b.firstPoint?.time) {
      return 1;
    }
    return 0;
  });

  const axisData: Array<Date> = [];
  sortedTimeSeries.forEach((ts, tsIndex) => {
    if (tsIndex === 0) {
      timeSeries[tsIndex].points.forEach((point) => {
        axisData.push(point.time.toJSDate());
      });
    } else {
      timeSeries[tsIndex].points.forEach((point) => {
        const existingTimeIndex = axisData.findIndex(
          (it) => it.getTime() === point.time.toJSDate().getTime()
        );
        if (existingTimeIndex === -1) {
          axisData.push(point.time.toJSDate());
        }
      });
    }
  });

  const valueFormatter = (
    dateTimeJs: Date,
    context: AxisValueFormatterContext
  ): string => {
    const dateTime = DateTime.fromJSDate(dateTimeJs); // Luxon DateTime
    return dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
  };

  if (axisConfig != null) {
    return [
      {
        ...axisConfig,
        scaleType: scaleType,
        data: axisData,
        valueFormatter: valueFormatter,
        id: "time",
      } as XAxisDefaultConfig,
    ];
  } else {
    return [
      {
        scaleType: scaleType,
        data: axisData,
        valueFormatter: valueFormatter,
        id: "time",
      } as XAxisDefaultConfig,
    ];
  }
};
