import { Attributes, FC, useMemo } from "react";
import { Button, Stack, SxProps, Typography } from "@mui/material";
import { merge } from "lodash";
import {
  Burn,
  BurnAssessment,
  resolveBurnAssessmentIcon,
} from "@airmont/firefly/shared/ts/domain";
import { Button_color } from "@airmont/shared/ts/ui/mui";

export interface BurnItemProps extends Attributes {
  burn: Burn;
  anonymizedTime: boolean;
  selected?: boolean;
  color?: Button_color;
  sx?: SxProps;
  onSelect?: (burn: Burn) => void;
}

export const BurnItem: FC<BurnItemProps> = (props) => {
  const { burn, anonymizedTime } = props;
  const selected = props.selected ?? false;
  const BurnAssessmentIcon = resolveBurnAssessmentIcon(
    burn.analytics.startQuality
  );

  const color = useMemo(() => {
    if (burn.analytics.startQuality === BurnAssessment.Good) {
      return "success";
    } else if (burn.analytics.startQuality === BurnAssessment.Bad) {
      return "warning";
    }

    return "inherit";
  }, [burn.analytics.startQuality]);

  const sx: SxProps = useMemo(() => {
    return merge({}, props.sx, {
      minWidth: "fit-content",
      pl: 0,
      pr: 0,
      pt: 0,
      pb: 0,
    } as SxProps);
  }, [props.sx]);

  const handleClick = () => {
    props.onSelect?.(props.burn);
  };

  return (
    <Button
      color={selected ? "primary" : "inherit"}
      variant={selected ? "contained" : "text"}
      sx={sx}
      onClick={handleClick}
    >
      <Stack
        direction={"column"}
        useFlexGap
        gap={1}
        sx={{
          alignItems: "center",
          pt: 0.5,
          pl: 2,
          pr: 2,
          pb: 1,
        }}
      >
        <Typography variant={"button"}>
          {anonymizedTime && `#${burn.count}`}
          {!anonymizedTime && (
            <>
              {burn.startTime.day}.
              {!anonymizedTime && burn.countOfDay != null && (
                <span
                  style={{ fontSize: "smaller", verticalAlign: "sub" }}
                >{`${burn.countOfDay}`}</span>
              )}
            </>
          )}
        </Typography>
        <BurnAssessmentIcon fontSize={"small"} color={color} />
      </Stack>
    </Button>
  );
};
