import {
  ActionGroup,
  isAction,
  isActionGroup,
  isDivider,
  isRouteItem,
  isSelectableItem,
  isSpacer,
  resolveActionGroupPreferences,
  SelectableItem,
  useFindSelectedItem,
} from "@airmont/shared/ts/ui/action";
import {
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { NavigationBarMenu } from "./NavigationBarMenu";
import { NavigationComponentProps } from "../NavigationComponentProps";
import { NavigationItemObject } from "../shared/NavigationItemObject";
import { isComponent } from "../shared/Component";
import { useSxMerge } from "@airmont/shared/ts/ui/mui";
import { Layout } from "../Layout";

const layout: Layout = "compact";

const displayableItems = (
  items: Array<NavigationItemObject>
): Array<NavigationItemObject> => {
  return items.filter((item) => {
    if (item == null) {
      return false;
    } else if (isSpacer(item)) {
      return false;
    } else if (isDivider(item)) {
      return false;
    } else if (isComponent(item)) {
      return false;
    } else if (React.isValidElement(item)) {
      return false;
    }
    return true;
  });
};

export const NavigationBar = (props: NavigationComponentProps) => {
  const { items, onSelected } = props;
  const variant = props.variant ?? "elevation";
  const theme = useTheme();
  const { width, ref } = useResizeDetector<HTMLDivElement>({
    handleHeight: false,
    handleWidth: true,
  });
  const selectedItem = useFindSelectedItem(props.items);
  const value = selectedItem?.reference ?? undefined;

  const [visibleItemsCount, setVisibleItemsCount] = useState<number>(
    displayableItems(items).length
  );

  const visibleItems = useMemo(() => {
    return displayableItems(items).filter((item, index) => {
      return index < visibleItemsCount;
    });
  }, [visibleItemsCount, items]);
  const overflowedItems = useMemo(() => {
    return displayableItems(items).filter((item, index) => {
      return index >= visibleItemsCount;
    });
  }, [visibleItemsCount, items]);

  const overflowGroup = useMemo(() => {
    return {
      type: "ActionGroup",
      items: overflowedItems,
    } as ActionGroup;
  }, [overflowedItems]);

  useEffect(() => {
    const el = ref.current;
    if (el != null) {
      const overFlowInPixels = el.scrollWidth - el.clientWidth;

      let childrenWidth = 0;
      let childWidth = 0;
      for (const child of el.children) {
        childrenWidth += child.clientWidth;
        if (child.clientWidth > childWidth) {
          childWidth = child.clientWidth;
        }
      }

      if (overFlowInPixels > 0) {
        if (visibleItems.length > 1) {
          setVisibleItemsCount((prevState) => prevState - 1);
        }
      } else if (
        childrenWidth + childWidth <= el.clientWidth &&
        overflowedItems.length > 0
      ) {
        setVisibleItemsCount((prevState) => prevState + 1);
      }
    }
  }, [width, visibleItems, overflowedItems, ref]);

  const handleItemSelected = useCallback(
    (selectedItem: SelectableItem) => {
      onSelected?.(selectedItem);
    },
    [onSelected]
  );

  const sx = useSxMerge(props.sx, {
    borderTop: "1px solid " + theme.palette.divider,
    justifyContent: "space-around",
  });

  return (
    <BottomNavigation
      className={
        `NavigationBar ${variant}` +
        (props.className !== null ? ` ${props.className}` : "")
      }
      classes={props.classes}
      showLabels
      value={value}
      sx={sx}
      ref={ref}
    >
      {visibleItems.map((item, index) => {
        if (item === undefined) {
          return undefined;
        } else if (isAction(item) || isRouteItem(item)) {
          const selected = isSelectableItem(item) && item.selected;
          const handleItemClick = () => {
            if (isSelectableItem(item)) {
              onSelected?.(item);
            } else if (isAction(item)) {
              item.onExecute(item);
            }
          };

          return (
            <BottomNavigationAction
              key={index}
              label={item.name}
              value={item.reference}
              icon={item.icon}
              onClick={handleItemClick}
              sx={{
                color: selected ? theme.palette.secondary.main : undefined,
                flexGrow: 0,
              }}
            />
          );
        } else if (isActionGroup(item)) {
          const preferences = resolveActionGroupPreferences(
            item.preferences,
            layout
          );
          return (
            <NavigationBarMenu
              key={index}
              item={item}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              onSelected={handleItemSelected}
            />
          );
        } else {
          throw new Error("Unsupported item type: \n" + item.type);
        }
      })}
      {overflowedItems.length > 0 && (
        <NavigationBarMenu
          key={-1}
          item={overflowGroup}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onSelected={handleItemSelected}
        />
      )}
    </BottomNavigation>
  );
};
