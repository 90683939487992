import * as React from "react";
import { FC } from "react";
import { AddressId } from "@airmont/firefly/shared/ts/domain";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { EnvironmentAwareBuilding } from "./EnvironmentAwareBuilding";
import { useTranslation } from "react-i18next";

export interface BuildingSelectProps {
  buildings: Array<EnvironmentAwareBuilding>;
  selected: AddressId | undefined;
  onChange: (value: AddressId) => void;
}
export const BuildingSelect: FC<BuildingSelectProps> = (props) => {
  const { t } = useTranslation("firefly-my-chimney-ts-building");
  const handleChange = (event: SelectChangeEvent, child: React.ReactNode) => {
    props.onChange(event.target.value);
  };

  return (
    <FormControl size={"small"}>
      <InputLabel>{t("Building")}</InputLabel>
      <Select
        label={t("Building")}
        value={props.selected ?? ""}
        onChange={handleChange}
      >
        {props.buildings.map((it) => {
          return (
            <MenuItem
              key={it.id}
              selected={it.id === props.selected}
              value={it.id}
            >
              {it.name} ({it.environment.name})
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
